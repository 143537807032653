import React from "react";
import { useStyles } from "../style";
import { Button, Grid } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";

const AdminMcqQuestions = ({ options, setOptions, answerType }) => {
  const classes = useStyles();

  const handleOptionChange = (index, event) => {
    const newOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, text: event.target.value };
      }
      return option;
    });
    setOptions(newOptions);
  };

  const handleCorrectChange = (index) => {
    if (answerType === "multi-select") {
      const newOptions = options.map((option, i) => {
        const correct = i === index ? (option.correct ? 0 : 1) : option.correct;
        return { ...option, correct };
      });
      setOptions(newOptions);
    } else {
      let errorFlag = false;
      options.forEach((option) => {
        if (option.disabled && option.correct) {
          toast.error(
            "Delete disabled correct answer before assigning new correct answer!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          errorFlag = true;
        }
      });
      if (errorFlag) {
        return;
      }
      const newOptions = options.map((option, i) => {
        const correct = i === index ? 1 : 0;
        return { ...option, correct };
      });
      setOptions(newOptions);
    }
  };

  const addOption = () => {
    setOptions([
      ...options,
      {
        text: "",
        correct: 0,
        sequence: options.length + 1,
        disabled: false,
      },
    ]);
  };

  const handleRemoveOption = (index) => {
    let newOptions = options.filter((option, i) => i !== index);
    newOptions = newOptions.map((row, idx) => {
      return {
        ...row,
        sequence: idx + 1,
        answerSequence: idx + 1,
      };
    });
    setOptions(newOptions);
  };

  return (
    <React.Fragment>
      <div
        style={{
          maxHeight: "30vh",
          overflow: "auto",
        }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`${classes.flex_row} ${classes.alignItemsCenter} mt-2`}
          >
            <div className={classes.mcqOptionContainer}>
              <textarea
                rows={1}
                value={option.text}
                onChange={(event) => handleOptionChange(index, event)}
                placeholder={`Option ${index + 1}`}
                className={classes.mcqOption}
                disabled={option.disabled}
              />
              <label className={classes.labelInput}>
                <input
                  type={answerType === "multi-select" ? "checkbox" : "radio"}
                  checked={option.correct}
                  onChange={() => handleCorrectChange(index)}
                  style={{ marginRight: "10px" }}
                  disabled={option.disabled}
                  className={
                    answerType === "multi-select"
                      ? classes.customCheckbox
                      : classes.customRadioButton
                  }
                />
              </label>
            </div>
            <div>
              <DeleteOutlineOutlinedIcon
                onClick={() => handleRemoveOption(index)}
                style={{
                  color: "#E76B69",
                  cursor: "pointer",
                  margin: "auto 0 auto 10px",
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <Grid className={`${classes.flex_row} ${classes.justifyRight} mt-2 mr-4`}>
        <Button
          className={`${classes.addOptionBtn} ${classes.font500} ${classes.font14}`}
          onClick={addOption}
        >
          <strong>Add Option +</strong>
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default AdminMcqQuestions;
