import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { getTheme } from "./SharedComponents/ThemeComponent";
import { useSelector, useDispatch } from "react-redux";
import { selectDarkMode, toggleDarkMode } from "./Redux/Reducers/darkModeSlice";
// MUI Imports
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

//Components Import
import Authentication from "./Core/Authentication";
import Routes from "./Core/Routes/Routes";
import LoaderComponent from "./Core/Loader/LoaderComponent";
import SidebarComponent from "./Components/SidebarComponent/SidebarComponent";
import HeaderBar from "./Components/HeaderBar/HeaderBar";
import { getRole } from "./Core/utils/auth";
import ExerciseTracker from "./Components/Tracker/ExerciseTracker";
import ExerciseTrackerCatalog from "./Components/Tracker/ExerciseTrackerCatalog";
import MathWorkSheet from "./Components/Courses/Maths";
import Sections from "./Components/Courses/Sections/Index";

//Lazy Loading Imports
const Login = lazy(() => import("./Components/Login/Login"));
const LoginRouter = lazy(() => import("./Components/Login/LoginRouter"));
const Workbench = lazy(() =>
  import("./Components/EditorWithOutput/EditorWithOutput")
);
const Home = lazy(() => import("./Components/Home/HomeNew"));
const Collection = lazy(() => import("./Components/Courses/Collection"));
const FilesRouter = lazy(() => import("./Components/Files/FilesRouter"));
const LearningContent = lazy(() =>
  import("./Components/LearningContent/LearningContent")
);
const PreviewAlchemyFile = lazy(() =>
  import("./Components/LearningContent/PreviewAlchemyFile")
);
const Community = lazy(() => import("./Components/Community"));
const Error = React.lazy(() => import("./Components/Error"));

const Courses = React.lazy(() => import("./Components/Courses"));
const Exercises = React.lazy(() => import("./Components/Courses/exercises"));
const ExerciseWorkbench = React.lazy(() =>
  import("./Components/Courses/workbench")
);
const Whiteboard = React.lazy(() => import("./Components/Whiteboard"));

const ProtectedRoute = () => {
  const role = getRole();
  const theme = getTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isDarkMode = useSelector(selectDarkMode);

  const handleToggle = () => {
    dispatch(toggleDarkMode());
  };

  const routes = [
    {
      path: "/home",
      component: Home,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/personalCollection/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/classNote/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/assignment/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/exercise/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/files",
      component: FilesRouter,
      exact: false,
      allow: true,
    },
    {
      path: "/learningContent",
      component: LearningContent,
      exact: true,
      allow: role === "guest" ? false : true,
    },
    {
      path: "/exercises",
      component: Courses,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/assignedToMe",
      component: Courses,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/:catalogId",
      component: Exercises,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/assignedToMe/:catalogId",
      component: Exercises,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/maths/:catalogId/:exerciseId",
      component: MathWorkSheet,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/sections/:catalogId/:exerciseId",
      component: Sections,
      exact: true,
      allow: true,
    },
    {
      path: `/exercises/collection/:collectionId`,
      component: Collection,
      exact: true,
      allow: true,
    },
    {
      path: `/exercises/collection/assignedToMe/:collectionId`,
      component: Collection,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/:catalogId/:exerciseId",
      component: ExerciseWorkbench,
      exact: true,
      allow: true,
    },
    {
      path: "/whiteboard/:type/:fileId",
      component: Whiteboard,
      exact: true,
      allow: true,
    },
    {
      path: "/whiteboard",
      component: Whiteboard,
      exact: true,
      allow: true,
    },
    {
      path: "/tracker",
      component: ExerciseTracker,
      exact: true,
      allow: role === "mentor",
    },
    {
      path: "/tracker/:id",
      component: ExerciseTrackerCatalog,
      exact: true,
      allow: role === "mentor",
    },
  ];
  return (
    <>
      <Grid
        container
        xs={12}
        style={{
          backgroundColor: location.pathname.includes("/sections")
            ? "#FAF6F4"
            : theme.palette.background.background0,
        }}
      >
        {isDesktop && (
          <Grid item container xs={1}>
            <SidebarComponent handleToggle={handleToggle} />
          </Grid>
        )}
        <Grid
          item
          container
          xs={
            isDesktop &&
            (!location.pathname.includes("/home") || role == "guest")
              ? 11
              : 12
          }
          style={{
            backgroundColor: location.pathname.includes("/sections")
              ? "#FAF6F4"
              : theme.palette.background.background0,
          }}
        >
          <div
            style={{
              display: "block",
              width: "100%",
              // minHeight:
              //   location.pathname.includes("/workbench") && isDarkMode
              //     ? "105vh"
              //     : "100vh",
              minHeight: "100vh",
              padding: matchesSM ? "0px 10px" : "0px 32px",
            }}
          >
            <HeaderBar handleToggle={handleToggle} />
            <Suspense
              fallback={
                <div style={{ width: "100%", height: "100%" }}>
                  <LoaderComponent />
                </div>
              }
            >
              <Routes routes={routes} redirectTo="/home" />
            </Suspense>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

function App() {
  const darkModeTheme = useSelector(selectDarkMode);
  const [darkMode, setDarkMode] = React.useState(false);

  useEffect(() => {
    setDarkMode(darkModeTheme);
    const root = document.documentElement;
    root.style.setProperty(
      "--scrollcolour",
      theme.palette.background.background3
    );
    root.style.setProperty(
      "--scrolltrack",
      theme.palette.background.profileBackground
    );
  }, [darkModeTheme]);

  const theme = getTheme();
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fragment>
        <Router>
          <Suspense
            fallback={
              <div style={{ height: "100vh" }}>
                <LoaderComponent />
              </div>
            }
          >
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/login" component={LoginRouter} />
              <Route path="/previewFile/:id" component={PreviewAlchemyFile} />
              <Route path="/404" component={Error} exact />
              <Route
                path={[
                  "/student/:id/1",
                  "/student/:id/",
                  "/user/:id/1",
                  "/user/:id",
                ]}
                component={Community}
                exact
                allow
              />
              <Authentication>
                <ProtectedRoute />
              </Authentication>
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Fragment>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
