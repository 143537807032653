import { createTheme } from "@mui/material/styles";
import { store } from "../../Redux/store";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    spacing: 8,
    background: {
      background0: "#FFFFFF",
      background1: "#FCF9F8",
      background2: "#FFFFFF",
      background3: "#FFFFFF",
      background4: "#EDF6FC",
      background5: "#F6F2FC",
      background6: "#FFF7EB",
      background7: "#FAFBFF",
      background8: "#FFFFFF",
      background9: "#F5F8FF",
      background10: "linear-gradient(95.18deg, #49FDA9 0%, #38E689 100%)",
      background11: "linear-gradient(95.18deg, #F8D64F 0%, #DEBF68 100%)",
      assignedToYou: "#FFFAF5",
      loaderClsbg: "#F1F5F6",
      completed: "linear-gradient(104deg, #FBFFFA -0.88%, #FAFFFA 102.19%)",
      assessmentBg: "#E2EFFD",
      completedSpan: "#DBF0EF",
      locked: "linear-gradient(95.18deg, #C58A8A 0%, #634040 100%)",
      activeBtn: "#596DB1",
      tabBtnBg: "#FBFCFE",
      codingclscardbg: "#FFE7DB",
      worksheetclscardbg: "#FFF0C7",
      runBtnBg: "#25B143",
      startBtnBg: "#FF712D",
      InputOutputToggleEditorBtn: "#FF702D",
      highlightBackground: "#FFF1EB",
      roleBackground: "#FFF6EB",
      profileBackground: "#FAFCFF",
      toggleBg: "#FFEBC2",
      homeWorkbenchbackground: "#EDF6FC",
      homeWhiteboardbackground: "#F6F2FC",
      homeexercisebackground: "#FFF7EB",
      workbenchFileNameRoot: "#FBFCFE",
      workbenchFileNameContainer: "#FFFFFF",
      wrkbchname: "#FBFCFE",
      filenmrt: "#FBFCFE",
      filetreebg: "#FFFFFF",
      fileCollectionClsBg: "#FFFFFF",
      codngimgclsbg: "#FFD6C2",
      mathsimgclsbg: "#FFE6A3",
      codingskillclsbg: "#FFD6C2",
      mathsskillclsbg: "#FFE399",
      resultBoxBg: "#EBEFFF",
      published: "#3AE98D",
      fileBody: "#e0e0e0"
    },
    //border colours
    border2: "#FDF1ED",
    border3: "#FDF1ED",
    border4: "#E3F1FC",
    border5: "#F3EDFD",
    border6: "#FBEEDA",
    profileBorder: "#EAECF3",
    dateAndMonth: "#446C6C",
    workbenchFileNameContainer: "1px solid #EAECF3",
    InputOutputToggleEditorBtnBorder: "1px solid #FF702D",

    //border style
    border7: "1px solid #FBE2DA",
    border8: "1px solid #EAECF3",
    dividerbackground: "#0000001f",
    borderselectLang: "1px solid #7D7D7D",
    bordereditorborder: "1px solid #E0E0E0",
    filetreeborder: "1px solid #EAECF3",
    searchBorder: "#EEEEEE",
    searchBorderOuter: "#EBF1FF",
    completedBorder: "#9BD4CD",
    tabLayoutBorder: "#E6EBF8",
    btnborder: "1.6px solid #AFCFCF;",

    //shadows
    shadow1: "0px 0px 8px rgba(0, 83, 244, 0.16)",
    completedShadow: "0px 0px 8px 0px rgba(0, 83, 244, 0.16)",
    tabLayoutShadow: "-4px 0px 8px rgba(0, 0, 0, 0.04)",

    // Font colours
    heading: "#2F4F4F",
    body: "#569090",
    languageselectclr: "#959595",
    caption: "#95B2B2",
    caption1: "#FFC247",
    highlightColor: "#F16118",
    highlightColor1: "#FFA85D",
    roleColor: "#A48765",
    codeEditortxt: "#1E1E1E",
    menucolor: "#959595",
    exercise: "#8F8F8F",
    exerciseCount: "#292929",
    recentlyOpened: "#5C5C5C",
    searchText: "#000000",
    lastOpened: "#B3B3B3",
    assessment: "#508CCE",
    level: "#7D7D7D",
    completed: "#60BDB8",
    tickSymbol: "#7097FF",
    exercisePgTitle: "#292929",
    label: "#8F8F8F",
    filter: "#525252",
    filterIcon: "#525252",
    rowHeading: "#2761BA",
    trackerHeadings: "#7A7A7A",
    report: "#666",
    toggleEditorOutputcolor: "#fff",
    toggleEditornotOutputcolor: "#FF702D",
    textareaautosizeClsclr: "#1e1e1e",
    buttonclr: "2F4F4F",
    codingskillclsclr: "#CC4300",
    mathsskillclsclr: "#997000",
    resultBoxText: "#292929",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    spacing: 8,
    background: {
      //background colours
      highlightBackground: "#2E2724",
      roleBackground: "#333333",
      loaderClsbg: "#333333",
      profileBackground: "#1E1E1E",
      homeWorkbenchbackground: "#2E2824",
      homeWhiteboardbackground: "#2E2824",
      homeexercisebackground: "#2E2824",
      workbenchFileNameRoot: "#1E1E1E",
      wrkbchname: "#2E2E2E",
      filenmrt: "#292929",
      filetreebg: "#292929",
      InputOutputToggleEditorBtn: "#FF702D",
      fileCollectionClsBg: "#3b3939",
      codngimgclsbg: "#4C2E2E",
      mathsimgclsbg: "#4C442E",
      codingskillclsbg: "#4C2F2F",
      mathsskillclsbg: "#4C442F",
      //background colours
      background0: "#0B0A09",
      background1: "#0B0A09",
      background2: "#1E1E1E",
      background3: "#292929",
      background4: "#EDF6FC",
      background5: "#EDF6FC",
      background6: "#EDF6FC",
      background7: "#292929",
      background8: "#333333",
      background9: "#29323D",
      background10: "linear-gradient(95.18deg, #02B662 0%, #16B661 100%)",
      background11: "linear-gradient(95.18deg, #C49E08 0%, #C9952C 100%)",
      assignedToYou: "#2E2824",
      completed: "#1A2221",
      assessmentBg: "#292929",
      highlightBackground: "#2E2724",
      roleBackground: "#333333",
      codingclscardbg: "#221B1B",
      worksheetclscardbg: "#22201B",
      profileBackground: "#1E1E1E",
      completedSpan: "#364543",
      runBtnBg: "#5CB85C",
      startBtnBg: "#E98451",
      toggleBg: "#292929",
      resultBoxBg: "#3D3D3D",
      published: "#3AE98D",

      locked:
        "linear-gradient(95.18deg, #C58A8A 0%, #A25353 0.01%, #7E4E4E 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
      activeBtn: "#596DB1",
      tabBtnBg: "#292929",
      fileBody: "#2e2e2e"
    },
    //border colours
    border2: "#292929",
    border3: "#333333",
    border4: "#E3F1FC",
    border5: "#E3F1FC",
    border6: "#E3F1FC",
    profileBorder: "#292929",
    workbenchFileNameContainer: "ipx solid #2E2E2E",
    bordereditorborder: "1px solid #333333",
    searchBorder: "#333333",
    searchBorderOuter: "#333333",
    completedBorder: "#2B3B39",
    tabLayoutBorder: "#333333",
    InputOutputToggleEditorBtnBorder: "1px solid #FF702D",
    btnborder: "1.6px solid #E98451",
    //shadows
    shadow1: "0px 0px 8px 0px #F4000029",
    completedShadow: "0px 0px 8px 0px #17261A",
    tabLayoutShadow: "-4px 0px 8px 0px #0000000A",

    // Font colours
    exercisePgTitle: "#E3E0DE",
    heading: "#ECEAE9",
    body: "#D0CBC8",
    languageselectclr: "#D0CBC8",
    caption: "#847871",
    caption1: "#847871",
    highlightColor: "#E98451",
    highlightColor1: "#FFA85D",
    roleColor: "#847871",
    dateAndMonth: "#ABA29C",
    codeEditortxt: "#E0E0E0",
    borderselectLang: "1px solid #D0CBC8",
    menucolor: "D0CBC8",
    textareaautosizeClsclr: "#FFF",
    // Border style
    border7: "1px solid #333333",
    border8: "1px solid #292929",
    dividerbackground: "#333333",
    filetreeborder: "1px solid #1E1E1E",
    guesttrialborder: "1.6px solid #E98451",
    exercise: "#D0CBC8",
    exerciseCount: "#ECEAE9",
    recentlyOpened: "#ECEAE9",
    searchText: " #D0CBC8",
    lastOpened: "#847871",
    assessment: "#847871",
    level: "#796E67",
    completed: "#49AB9E",
    tickSymbol: "#4AB2AC",
    label: "#D0CBC8",
    filter: "#D0CBC8",
    filterIcon: "#58504B",
    rowHeading: "#ECEAE9",
    trackerHeadings: "#ECEAE9",
    report: "#D0CBC8",
    codingskillclsclr: "#D4B9B9",
    mathsskillclsclr: "#C8BFA7",
    buttonclr: "#E98451",
    resultBoxText: "#D0CBC8",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const getTheme = () => {
  const isDarkMode = store.getState().darkModeTheme.isDarkModeTheme;
  if (isDarkMode) {
    return darkTheme;
  } else {
    return lightTheme;
  }
};
