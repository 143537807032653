import { useState, useEffect } from 'react';

export const useDeviceInfo = () => {
    const [deviceInfo, setDeviceInfo] = useState({
      userAgent: '',
      platform: '',
      language: '',
      online: true,
      cookiesEnabled: false,
      screenWidth: 0,
      screenHeight: 0,
      browserName: '',
    });
  
    useEffect(() => {
      const getBrowserDetails = () => {
          let userAgent = navigator.userAgent;
          let browser = {};
      
          if (userAgent.match(/chrome|chromium|crios/i)) {
            browser.name = "chrome";
            browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
          } else if (userAgent.match(/firefox|fxios/i)) {
            browser.name = "firefox";
            browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
          } else if (userAgent.match(/safari/i)) {
            browser.name = "safari";
            browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
          } else if (userAgent.match(/edg/i)) {
            browser.name = "edge";
            browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
          } else {
            browser.name = "No browser detected";
            browser.version = "No Version detected";
          }
          return browser;
        };
  
      setDeviceInfo({
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        online: navigator.onLine,
        cookiesEnabled: navigator.cookieEnabled,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        browserName: getBrowserDetails(),
      });
    }, []);
  
    return deviceInfo;
  };
