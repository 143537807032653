import Cookies from "js-cookie";

const setAuthCookies = (auth, login = 0) => {
  const access = Cookies.get("Authorization");
  const refresh = Cookies.get("refresh-token");
  if (login || !access || !refresh) {
    const oneYearFromNow = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );

    Cookies.set("Authorization", auth.ACCESS_TOKEN, {
      expires: oneYearFromNow,
      path: "/",
      domain: ".codeyoung.com",
    });
    Cookies.set("refresh-token", auth.REFRESH_TOKEN, {
      expires: oneYearFromNow,
      path: "/",
      domain: ".codeyoung.com",
    });
  }
};

export default setAuthCookies;
