import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
};

export const darkMode = createReducer(initialState, {
  CHANGE_DARK_MODE: (state) => {
    state.darkMode = !state.darkMode;
  },
});
