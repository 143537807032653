import Axios from "axios";
import sessionExpired from "../Core/sessionExpired";
import { decrypt, encrypt } from "../Core/utils/others";
import setAuthCookies from "../SharedComponents/setAuthCookies";
// import { BASE_URL } from "./../Core/constants";

const accesToken = decrypt(localStorage.getItem("sandbox_user"));
const BASE_URL = process.env.REACT_APP_SANDBOX_HTTP_BASE_URL;
const api = function (baseUrl) {
  this.apiBase = baseUrl;
  this.accesToken = accesToken ? accesToken.ACCESS_TOKEN : "";
  this.refreshToken = accesToken ? accesToken.REFRESH_TOKEN : "";
  this.axios = Axios.create({
    baseURL: this.apiBase,
    timeout: 100000000,
  });
  this.axios.defaults.headers.post["Content-Type"] = "application/json";
  this.axios.interceptors.request.use(
    (req) => {
      req["headers"]["Authorization"] = `Bearer ${this.accesToken}`;
      req["headers"]["refresh-token"] = this.refreshToken
        ? this.refreshToken
        : "";
      return req;
    },
    (e) => {
      return Promise.reject(e);
    }
  );
  this.axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 //For Both Access Token Expiration
      ) {
        const originalRequest = error.config;
        originalRequest._retry = true;
        const refresh = decrypt(localStorage.getItem("sandbox_user"));
        let access_token;
        try {
          access_token = await refreshAccessToken(refresh.REFRESH_TOKEN);
          console.log("=========================", access_token);
        } catch (e) {
          sessionExpired();
          return Promise.reject();
        }
        const LsItem = decrypt(localStorage.getItem("sandbox_user"));
        LsItem["ACCESS_TOKEN"] = access_token.data.ACCESS_TOKEN;
        LsItem["REFRESH_TOKEN"] = access_token.data.REFRESH_TOKEN;
        localStorage.setItem("sandbox_user", encrypt(LsItem));
        // * Set Global access token
        Axios.defaults.headers.common["Authorization"] =
          "Bearer " + access_token.data.ACCESS_TOKEN;
        // * Set access token for current Instance ( Orginial Request )
        originalRequest.headers.Authorization =
          "Bearer " + access_token.data.ACCESS_TOKEN;
        let result;
        try {
          result = await Axios(originalRequest);
        } catch (e) {
          console.log("Error : ", e);
        }
        return result;
      }
      if (
        error.response &&
        error.response.status === 403 //For Referesh Token Expiration
      ) {
        sessionExpired();
      }
      return Promise.reject(error);
    }
  );
  const refreshAccessToken = async (refreshToken) => {
    const refreshToken1 = await this.axios.post("/getNewRefreshToken", {
      refreshToken,
    });
    setAuthCookies(refreshToken1.data);
    return refreshToken1;
  };
};

api.prototype.getApiBase = function () {
  return this.apiBase;
};

api.prototype.get = function (url, params = {}, data = {}) {
  return this.axios({
    method: "get",
    url,
    params,
    data,
  });
};

api.prototype.post = function (url, data = {}, params = {}, headers = {}) {
  const addHeaders = Object.keys(headers).length ? { ...headers } : {};
  return this.axios({
    method: "post",
    url,
    data,
    params,
    ...addHeaders,
  });
};

api.prototype.put = function (url, data, params = {}) {
  return this.axios({
    method: "put",
    url,
    data,
    params,
  });
};

api.prototype.delete = function (url, data) {
  return this.axios({
    method: "delete",
    url,
    data,
  });
};

api.prototype.updateTokens = function (data) {
  this.accesToken = data.ACCESS_TOKEN;
  this.refreshToken = data.REFRESH_TOKEN;
};

const API = new api(BASE_URL);
const API_CUSTOMERDEMO = new api(
  process.env.REACT_APP_CUSTOMERDEMO_HTTP_BASE_URL
);

export { API, API_CUSTOMERDEMO };
