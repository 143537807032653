import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Grid, Typography, Box, useMediaQuery } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../../Services/api-service";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Form, Spinner } from "react-bootstrap";
import { decrypt } from "../../../Core/utils/others";

toast.configure();

const useStyles = makeStyles((theme) => ({
  textArea: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  },
  label: {
    height: "19px",
    marginTop: "5%",
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#525252",
  },
  heading: {
    textAlign: "center",
    color: "#000000",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
  },
  addCollectionButtonBox: {
    display: "flex",
    justifyContent: "center",
    padding: "4%",
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "3%",
    color: "#FF702D",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #FF702D",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#FF712D",
    },
  },
  submitBtn: {
    padding: "8px 30px",
    color: "white",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  disableSubmitBtn: {
    padding: "8px 30px",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    borderRadius: "6px",
    textTransform: "none",
    background: "#D8C8C0",
    border: "1px solid #D8C8C0",
    color: "#FFFFFF",
    "&:hover": {
      background: "#D8C8C0",
      color: "#FFFFFF",
    },
  },
  textAreaInput: {
    width: 400,
    minHeight: "10vh",
    padding: "8px",
    color: "#939393",
    borderRadius: "4px",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "240px",
    },
    "@media (max-width: 320px)": {
      maxWidth: "200px",
    },
  },
  titleBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#666",
    borderRadius: "4px",
    background: "#FFF3EB",
    padding: "10px 20px 10px 10px",
    maxWidth: "480px",
    wordWrap: "break-word",
    wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      maxWidth: "410px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "290px",
    },
  },
  spinner: {
    width: "20px",
    height: "20px",
  },
}));

const ReportIssue = ({ open, closeModalHandler, title, exerciseId }) => {
  const classes = useStyles();
  const userInfo = decrypt(localStorage.sandbox_user);
  const userEmail = userInfo.email;
  const [issueDesc, setIssueDesc] = useState(null);
  const [loader, setLoader] = useState(false);
  const submitHandler = () => {
    setLoader(true);
    API.post(`/exercises/${exerciseId}/issue`, {
      email: userEmail,
      issue: issueDesc,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
            style: {
              borderRadius: "2px",
              background: "#3FC77F",
              boxShadow: "0px 4px 8px 0px rgba(78, 153, 72, 0.32)",
            },
          });
          setLoader(false);
          closeModalHandler();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoader(false);
      });
  };
  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <div className="ml-3 mr-3 add-course" style={{ marginTop: "3%" }}>
          <Typography className={classes.heading}>Report issue</Typography>
          <br />
          <div className={classes.titleBox}>
            <span className={classes.title}>{title}</span>
          </div>
          <div className={classes.textArea}>
            <TextareaAutosize
              required={true}
              value={issueDesc}
              onChange={(e) => setIssueDesc(e.target.value)}
              placeholder="Enter issue here..."
              className={classes.textAreaInput}
            />
          </div>
        </div>
      </DialogContent>
      <div className={classes.addCollectionButtonBox}>
        <DialogActions>
          <Button className={classes.cancelBtn} onClick={closeModalHandler}>
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            className={issueDesc ? classes.submitBtn : classes.disableSubmitBtn}
            disabled={loader || !issueDesc}
          >
            {loader ? (
              <Spinner
                animation="border"
                variant="white"
                className={classes.spinner}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ReportIssue;
