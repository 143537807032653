import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import TagManager from "react-gtm-module";

import Student from "../../assets/images/student_logo.png";
import Badge from "../../assets/images/badge.png";
import { decrypt } from "../../Core/utils/others";

const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));

const useStyles = makeStyles((theme) => ({
  modalContent: {
    margin: 0,
    padding: 0,
  },
  brokenBG: {
    backgroundColor: "#F8F4CC",
  },
  closeImg: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 5,
  },
  brokenImage: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    paddingBottom: 20,
  },
  modalActions: {
    height: 100,
    justifyContent: "center",
  },
}));

const UpgradeModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const navigateToBookDemo = () => {
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_sandbox",
        event_category: "codeyoung_sandbox",
        event_action: "book_demo_clicked",
        event_label1: sandboxUser.email,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
    window.open("https://book-a-demo.codeyoung.com/#/", "_blank");
  };
  const navigateToCourses = () =>
    window.open("https://www.codeyoung.com/courses", "_blank");

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogContent className={classes.modalContent} style={{ paddingTop: 0 }}>
        <div className={classes.brokenBG}>
          <div className={classes.closeImg}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className={classes.brokenImage}>
            <img src={Student} alt="Broken" />
          </div>
          <div className={classes.textContent}>
            <div style={{ display: "flex" }}>
              <img src={Badge} alt="Badge" />
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Only for Codeyoung Students
              </Typography>
            </div>
            <Typography color="textSecondary" style={{ textAlign: "center" }}>
              Here you can access learning content, Coding Exercises, Class
              Notes and Assignments
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: 10,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              // size="small"
              style={{
                backgroundColor: "#FF702D",
                borderColor: "#FF702D",
                border: "2px solid #FF702D",
              }}
              onClick={navigateToBookDemo}
            >
              Book Demo Class
            </Button>
            <Typography
              style={{
                fontWeight: "bold",
                textAlign: "center",
                textDecoration: "underline",
                marginTop: 10,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              Continue with free version
            </Typography>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions className={classes.modalActions}>
        <Button
          variant="contained"
          color="primary"
          // size="small"
          style={{
            backgroundColor: "#FF702D",
            borderColor: "#FF702D",
            border: "2px solid #FF702D",
          }}
          onClick={navigateToBookDemo}
        >
          Book Demo Class
        </Button>
        <Button
          variant="outline"
          // color="primary"
          // size="small"
          style={{
            borderColor: "#FF702D",
            border: "2px solid",
            color: "#FF702D",
          }}
          onClick={navigateToCourses}
        >
          Explore Courses
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default UpgradeModal;
