import React, { useState } from "react";
import { Input, InputLabel, makeStyles } from "@material-ui/core";
import StarOutline from "@material-ui/icons/StarOutline";
import Star from "@material-ui/icons/Star";
import { API } from "../../Services/api-service";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#e0e0e0",
    width: "220px",
    height: "70px",
    margin: "auto",
    position: "relative",
  },
  darkMode: {
    background: "#333333",
  },
  lightMode: {
    background: "#E9ECEC",
  },
  stars: {
    display: "flex",
    direction: "row",
  },
  star: {
    cursor: "pointer",
    transition: "color 200ms",
    color: "#5B8989",
    width: "28px",
    height: "28px",
  },
  darkStar: {
    color: "#9FA7A5",
  },
  text: {
    marginTop: "5px",
    fontSize: "12px",
    fontFamily: "Inter",
    lineHeight: "12px",
    color: "#607d8b",
  },
  orange: {
    color: "#E98451",
  },
  closeBtn: {
    position: "absolute",
    top: "2px",
    right: "5px",
    cursor: "pointer",
  },
  btnLight: {
    color: "#2F4F4F",
  },
  btnDark: {
    color: "#9FA7A5",
  },
  hide: {
    display: "none",
  },
  closeIcon: {
    width: "12px",
    height: "12px",
  },
  darkText: {
    color: "#ECEAE9",
  },
  workSheetLight: {
    border: "2px solid #517B7B",
    backgroundColor: "#FFFFFF",
    height: "100px",
  },
  workSheetDark: {
    border: "2px solid #2F4F4F",
    backgroundColor: "#1E1E1E",
    height: "100px",
  },
  worksheetClr: {
    color: "#5B8989",
  },
}));

const ExerciseRatings = ({ exerciseId, exerciseRating, afterSubmit }) => {
  const classes = useStyles();
  const [rating, setRating] = useState(exerciseRating || 0);
  const [hover, setHover] = useState(0);
  const [updateRating, setUpdateRating] = useState(
    exerciseRating ? true : false
  );
  const [showModel, setShowModel] = useState(true);

  const isDarkMode = useSelector(selectDarkMode);

  const handleRating = (ratingValue) => {
    if (exerciseRating != ratingValue) {
      setRating(ratingValue);
      API.post("/exercise/ratings", {
        exerciseId,
        rating: ratingValue,
      })
        .then((res) => {
          setUpdateRating(true);
        })
        .catch((err) => {
          console.log("Error while updationg rating: ", err);
        });
    }
  };

  const handleClose = () => {
    setShowModel(false);
  };

  return (
    <div className={!showModel ? classes.hide : ""}>
      <div
        className={`${classes.card} ${
          isDarkMode ? classes.darkMode : classes.lightMode
        } ${afterSubmit && isDarkMode && classes.workSheetDark} ${
          afterSubmit && !isDarkMode && classes.workSheetLight
        }`}
      >
        {afterSubmit && (
          <div className={`${classes.closeBtn} `} onClick={handleClose}>
            <CloseIcon
              className={`${classes.closeIcon} ${
                isDarkMode ? classes.btnDark : classes.btnLight
              }`}
            />
          </div>
        )}
        <div className={classes.stars}>
          {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;

            return (
              <InputLabel key={index}>
                <Input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  style={{ display: "none" }}
                  onClick={() => {
                    handleRating(ratingValue);
                  }}
                />
                {ratingValue <= (hover || rating) ? (
                  <Star
                    className={`${classes.star} ${classes.orange}`}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                  />
                ) : (
                  <StarOutline
                    className={`${classes.star} ${
                      isDarkMode && classes.darkStar
                    } ${afterSubmit && classes.worksheetClr}`}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                  />
                )}
              </InputLabel>
            );
          })}
        </div>
        {updateRating ? (
          <div className={`${classes.text} ${isDarkMode && classes.darkText}`}>
            Thanks for your rating!
          </div>
        ) : (
          <div className={`${classes.text} ${isDarkMode && classes.darkText}`}>
            Enjoyed this exercise? Rate it.
          </div>
        )}
      </div>
    </div>
  );
};

export default ExerciseRatings;
