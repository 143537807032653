import { decrypt } from "./others";

export const auth = () => !!decrypt(localStorage.sandbox_user);

export const authenticate = () => {
  if (localStorage.sandbox_user) {
    const ls = decrypt(localStorage.sandbox_user);
    if (!ls.Authorization) return false;
    if (!ls["refresh-token"]) return false;
  } else {
    return false;
  }
};

export const getRole = () => {
  const role = decrypt(localStorage.sandbox_user);
  return role.role;
};

export const getUserId = () => {
  const user = decrypt(localStorage.sandbox_user);
  return user.user_id;
};
