import React from "react";
import { Table } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import view from "../../assets/images/link--icon.png";
import setAuthCookies from "../../SharedComponents/setAuthCookies";
import { useCookies } from "react-cookie";
import { API } from "../../Services/api-service";
import { decrypt } from "../../Core/utils/others";
import "./TableData.css";
import AttemptSummary from "./AttemptSummary";
import CustomPagination from "./CustomPagination";
import {
  isQuestionnaire,
  isScratch,
  isCoding,
  isWorksheet,
} from "../Home/exerciseHelper";

const useStyles = makeStyles((theme) => ({
  rowStyle: {
    textTransform: "capitalize",
    // fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.palette.body,
    width: "150px",
    textAlign: "center",
    padding: "10px",
  },
  thead: {
    width: "150px",
    textAlign: "center",
    color: theme.palette.rowHeading,
    textTransform: "capitalize",
    background: theme.palette.background.background3,
  },
  note: {
    padding: "5px 15px 5px 15px",
    fontSize: "14px",
  },
  notFound: {
    borderRadius: "30px",
    background: "#EBEFFF",
    width: "160px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  historyView: {
    color: "#FF712D",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "none",
    cursor: "pointer",
  },
}));
function TableData({
  type,
  data,
  header,
  rowsPerPage,
  totalRows,
  setCurrentPage,
  currentPage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));
  const [cookies, setCookie] = useCookies([
    "catalogueId",
    "exerciseId",
    "refresh-token",
    "location",
    "fileId",
    "fileName",
    "type",
  ]);
  const [displayHistoryTable, setDisplayHistoryTable] = useState(false);
  const [exercisesId, setExercisesId] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Fetch data or update the table based on the new page
  };
  const getColor = (status) => {
    if (status == "completed") return "#28B844";
    else if (status == "attempted") return "#FFA749";
    return "#AB5C5C";
  };
  const getBrowserDetails = () => {
    let userAgent = navigator.userAgent;
    let browser = {};

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browser.name = "chrome";
      browser.version = `Chrome v${userAgent.split("Chrome/")[1]}`;
    } else if (userAgent.match(/firefox|fxios/i)) {
      browser.name = "firefox";
      browser.version = `Firefox v${userAgent.split("Firefox/")[1]}`;
    } else if (userAgent.match(/safari/i)) {
      browser.name = "safari";
      browser.version = `Safari v${userAgent.split("Safari/")[1]}`;
    } else if (userAgent.match(/edg/i)) {
      browser.name = "edge";
      browser.version = `Edg v${userAgent.split("Edg/")[1]}`;
    } else {
      browser.name = "No browser detected";
      browser.version = "No Version detected";
    }
    return browser;
  };
  const logWorkbench = (language, platform, exerciseId, submissionId) => {
    let browser = getBrowserDetails();
    API.post("/sandboxExecution", {
      language: language,
      status: "started",
      platform: platform,
      browser: browser.name,
      browserVersion: browser.version,
      vncId: null,
      executionId: null,
      frontEndSection: "tracker",
    })
      .then((res) => {
        const id = res.data.id;
        localStorage.setItem("exeId", res.data.id);
        window.open(
          `${process.env.REACT_APP_SCRATCH_PORTAL_URL}?executionId=${id}&exerciseId=${exerciseId}&submissionId=${submissionId}`
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const displayHistoryView = (id, disable) => {
    if (!disable) {
      setExercisesId(id);
      setDisplayHistoryTable(true);
    }
  };

  const closeHistoryTable = () => {
    setDisplayHistoryTable(false);
  };
  const handleView = (
    id,
    exerciseId,
    catalogueId,
    isDisabled = false,
    category
  ) => {
    const domain = `${process.env.REACT_APP_SANDBOX_PORTAL_URL}`;
    if (type !== "exercise") {
      const url = `${domain}#/tracker/${id}`;
      window.open(url, "_self");
    }

    // change exerciseName to language which has to be sent from backend
    if (type === "exercise" && isDisabled) return;

    if (isQuestionnaire(category)) {
      window.open(`/exercises/sections/${catalogueId}/${exerciseId}`);
    } else if (isWorksheet(category)) {
      const url = `${domain}#/exercises/maths/${catalogueId}/${exerciseId}?from=tracker&submissionId=${id}`;
      window.open(url);
    } else if (isCoding(category)) {
      const url = `${domain}#/exercises/${catalogueId}/${exerciseId}?from=tracker&submissionId=${id}`;
      window.open(url);
    } else if (isScratch(category)) {
      setAuthCookies(sandboxUser);
      setCookie("catalogueId", catalogueId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("exerciseId", exerciseId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("location", "exercise", {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("role", sandboxUser.role, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("fileId", undefined, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("fileName", exerciseId, {
        path: "/",
        domain: ".codeyoung.com",
      });
      setCookie("type", "exerciseTracker", {
        path: "/",
        domain: ".codeyoung.com",
      });
      logWorkbench("scratch", "sandbox_ui", exerciseId, id);
    }
  };
  return (
    <div
      className="table"
      style={{
        marginBottom: "20px",
      }}
    >
      {displayHistoryTable && (
        <AttemptSummary
          open={displayHistoryTable}
          close={closeHistoryTable}
          id={exercisesId}
        />
      )}
      {data?.length > 0 ? (
        <Table>
          <thead style={{ position: "sticky", top: 0 }}>
            <tr
              style={{
                backgroundColor: "#FAFBFF",
                border: "1px solid #EBF1FF",
              }}
            >
              {header.map((headerName) => (
                <th className={classes.thead}>{headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((d, idx) => {
              return (
                <tr key={idx}>
                  {type == "all" ? (
                    <>
                      <td className={classes.rowStyle}>{d.batch}</td>
                      <td className={classes.rowStyle}>{d.student}</td>
                      <td className={classes.rowStyle}>{d.catalogue}</td>
                      <td className={classes.rowStyle}>{d.totalDone}</td>
                      <td className={classes.rowStyle}>
                        {d.assignedExerciseCount}
                      </td>
                      <td className={classes.rowStyle}>
                        {d.assignedCompleted}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className={classes.rowStyle} style={{ width: "40%" }}>
                        {d.exercise}
                      </td>
                      <td className={classes.rowStyle} style={{ width: "40%" }}>
                        {d.typeId ? "Yes" : "No"}
                      </td>
                      <td className={classes.rowStyle} style={{ width: "40%" }}>
                        {d.isAssigned ? "Yes" : "No"}
                      </td>
                      {(d.isMath  || d.category === "Questionnaire") && (
                        <td className={classes.rowStyle}>
                          {d.disable ? "-" : d.score}
                        </td>
                      )}
                      {(d.isMath  || d.category === "Questionnaire") && (
                        <td className={classes.rowStyle}>{d.total}</td>
                      )}
                      <td className={classes.rowStyle}>
                        {d.completedIn ? d.completedIn : "-"}
                      </td>
                      <td className={classes.rowStyle}>
                        {d.attempts ? d.attempts : "-"}
                      </td>
                      <td
                        className={classes.rowStyle}
                        style={{ color: getColor(d.status.toLowerCase()) }}
                      >
                        {d.status}
                      </td>
                      {!d.disable ? (
                        <td className={classes.rowStyle}>
                          <span
                            className={classes.historyView}
                            style={{
                              cursor: d.disable ? "not-allowed" : "pointer",
                            }}
                            onClick={() => displayHistoryView(d.id, d.disable)}
                          >
                            View
                          </span>
                        </td>
                      ) : (
                        <td className={classes.rowStyle}>-</td>
                      )}
                    </>
                  )}
                  {!d.disable ? (
                    <td className={classes.rowStyle}>
                      <img
                        src={view}
                        alt="open exercise"
                        style={{
                          cursor: d.disable ? "not-allowed" : "pointer",
                        }}
                        onClick={() =>
                          handleView(
                            d.id,
                            d.exerciseId,
                            d.catalogueId,
                            d.disable,
                            d.category
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td className={classes.rowStyle}>-</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <center>
          <div className={classes.notFound}>No data Found</div>
        </center>
      )}
      {totalRows > 10 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          <CustomPagination
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableData;
