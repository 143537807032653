import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import dark_mode from "../../assets/images/dark_mode_icon.svg";
import light_mode from "../../assets/images/light_mode_icon.svg";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";

export const ToggleBar = () => {
  const isDarkMode = useSelector(selectDarkMode);

  const bgColor = isDarkMode ? "#292929" : "#FFEBC2";

  const StyledSwitch = styled(Switch)(({ isDarkMode }) => ({
    width: 62,
    height: 34,
    padding: 7,

    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",

      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",

        "& .MuiSwitch-thumb::before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${dark_mode})`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#292929",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: bgColor,

      width: 32,
      height: 32,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${light_mode})`,
      },
    },

    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#FFEBC2",
      borderRadius: 20 / 2,
    },
  }));

  return <StyledSwitch checked={isDarkMode} />;
};
