import { Button, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useStyles } from "./style";
import { getRole } from "../../../Core/utils/auth";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReactPlayer from "react-player";
import { API } from "../../../Services/api-service";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Video = ({
  questionData,
  handleAnswers,
  setHasAnswered,
  sectionId,
  setIconDisabled,
  clearAdminEditors,
  getSections,
  nextSection,
  handleOpenEnded,
}) => {
  const role = getRole();
  const classes = useStyles();
  const { exerciseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [questionId, setQuestionId] = useState(questionData?.questionId);
  const [score, setScore] = useState(questionData?.score);

  const [url, setUrl] = useState(questionData?.questionDesc);

  const isUrlValid = (userInput) => {
    if (typeof userInput === "string") {
      var res = userInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res == null) return false;
      else return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (role !== "admin") {
      if (questionData?.isQnResponded) {
        setIconDisabled(false);
      } else {
        setIconDisabled(true);
      }
      let ans = [{ viewed: 1 }];
      setHasAnswered(true);
      handleAnswers(questionData?.questionType, questionData?.questionId, ans);
      handleOpenEnded(false);
    }
  }, [questionData]);

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const saveHandler = () => {
    if (!sectionId) {
      return;
    }
    if (!isUrlValid(url)) {
      toast.error("Not a valid URL", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setLoading(true);
    const urlAddn = questionId ? `/${questionId}` : "";
    API.post(`${exerciseId}/questionnaire/${sectionId}/question${urlAddn}`, {
      type: "Instruction - Video",
      title: "Instruction video",
      description: url,
      score: score ? parseInt(score, 10) : 0,
      difficulty: "Easy",
    })
      .then((res) => {
        setLoading(false);
        if (res?.data?.questionId) {
          setQuestionId(res?.data?.questionId);
        }
        toast.success("Question saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.msg || "Something went wrong please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        console.log(err);
      });
  };

  const handleDeleteSection = () => {
    if (!sectionId) {
      return;
    }
    swal({
      title: `Delete Section?`,
      text: `Cannot be recovered once deleted.`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    })
      .then((isDeleted) => {
        if (isDeleted) {
          setLoading(true);
          API.delete(`${exerciseId}/questionnaire/section/${sectionId}`)
            .then((res) => {
              setLoading(false);
              toast.success(
                res?.data?.msg || "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              clearAdminEditors();
              getSections(nextSection);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(
                err?.response?.data?.msg ||
                  "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              console.log(err);
            });
        }
      })
      .catch((err) => {});
  };

  return (
    <Grid
      className={`${classes.flex_row} ${classes.justifyCenter} ${classes.alignItemsCenter}`}
      style={{ height: "100%" }}
    >
      {role === "admin" ? (
        <Grid className={`${classes.adminVideoBox}`}>
          <Grid
            className={`${classes.flex_row} ${classes.justifyRight} mt-1 mr-2`}
          >
            <Button
              onClick={handleDeleteSection}
              className={`${classes.deleteSectionBtn}`}
              disabled={loading}
            >
              Delete Section <DeleteOutlineOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid
            className={`${classes.flex_col} ${classes.justifyCenter} ${classes.alignItemsCenter} mt-5`}
          >
            <Grid className={`mt-4`}>
              <Grid>Video URL</Grid>
              <input
                type="text"
                className={classes.inputUrlText}
                name="input"
                value={url}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter video URL"
              />
            </Grid>
            <Grid className={`mt-4`}>
              <Grid>Score</Grid>
              <input
                type="number"
                className={`${classes.inputUrlText} pl-2`}
                name="input"
                value={score}
                onChange={(e) => setScore(e.target.value)}
                placeholder="Enter score"
              />
            </Grid>
          </Grid>
          <Grid className={`${classes.flex_row} ${classes.justifyCenter} mt-2`}>
            <Button
              className={`${classes.saveBtn}`}
              onClick={saveHandler}
              disabled={loading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      ) : (
        <ReactPlayer
          url={questionData?.questionDesc}
          className={classes.videoPlayer}
          controls
        />
      )}
    </Grid>
  );
};

export default Video;
