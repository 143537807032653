import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "end",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      justifyContent: "center",
    },
  },
  button: {
    color: "#FF712D",
    backgroundColor: "transparent",
    textTransform: "none",
    border: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      color: "#FFFFFF",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "21px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  pageNumber: {
    color: "#FFFFFF",
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    marginTop: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "20px",
      textAlign: "center",
      margin: "auto",
    },
  },
}));

const CustomPagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  workSheetData,
  setPageNumber,
  pageNumber,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  const handlePrevClick = () => {
    setCurrentPage(workSheetData.prevPage && workSheetData.prevPage);
    setPageNumber((pre) => pre - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(workSheetData.nextPage && workSheetData.nextPage);
    setPageNumber((pre) => pre + 1);
  };

  return (
    <div className={classes.pagination}>
      <Button
        variant="text"
        startIcon={<SkipPreviousOutlinedIcon />}
        style={{ cursor: "pointer" }}
        className={classes.button}
        onClick={handlePrevClick}
        disabled={pageNumber > 1 ? false : true}
      >
        {matches ? "" : "Prev"}
      </Button>
      {matchesMD ? (
        ""
      ) : (
        <span className={classes.pageNumber}>{pageNumber}</span>
      )}
      <Button
        className={classes.button}
        variant="text"
        endIcon={<SkipNextOutlinedIcon />}
        style={{ cursor: "pointer" }}
        onClick={handleNextClick}
        disabled={pageNumber < totalPages ? false : true}
      >
        {matches ? "" : "Next"}
      </Button>
    </div>
  );
};

export default CustomPagination;
