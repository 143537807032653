// import swal from "sweetalert";
// import { API } from "../Services/api-service";
// import { decrypt, encrypt } from "../Core/utils/others";
// import { getRole } from "../Core/utils/auth";

// const sessionExpired = () => {
//   const role = getRole()
//   swal({
//     title: "Session Expired",
//     text: "Continue working?",
//     icon: "warning",
//     buttons: ["No, go to home page.", "Yes, Login"],
//     closeOnClickOutside: false,
//     closeOnEsc: false,
//   }).then((login) => {
//     if (login) {
//       const userObj = localStorage.getItem("sandbox_user");
//       const user = decrypt(userObj);
//       const username = user.user_name;
//       swal({
//         title: "Login",
//         text: "Enter your password.",
//         content: {
//           element: "input",
//           attributes: {
//             placeholder: "Type your password",
//             type: "password",
//           },
//         },
//         closeOnClickOutside: false,
//         closeOnEsc: false,
//       })
//         .then((password) => {
//           return API.post("/logInByMail", {
//             email: username,
//             password: password,
//             userType: role
//           });
//         })
//         .then((res) => {
//           if (res) {
//             const encryptedUserData = encrypt(res.data);
//             localStorage.setItem("sandbox_user", encryptedUserData);
//             window.location.reload();
//           }
//         })
//         .catch((e) => {
//           if (e.response?.data?.msg === "Wrong Password") {
//             localStorage.removeItem("sandbox_user");
//             window.location.replace("/");
//           }
//         });
//     } else {
//       localStorage.removeItem("sandbox_user");
//       window.location.replace("/");
//     }
//   });
// };
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { decrypt } from "./utils/others";

const sessionExpired = () => {
  if (localStorage.getItem("sud")) {
    const userData = AES.decrypt(localStorage.getItem("sud"), "secret key 123");
    const decryptedData = JSON.parse(userData.toString(CryptoJS.enc.Utf8));
    localStorage.removeItem("sandbox_user");
    if (decryptedData.role === "student") {
      window.location.replace("/#/login/student");
    } else if (
      decryptedData.role === "mentor" ||
      decryptedData.role === "admin"
    ) {
      window.location.replace("/#/login/mentor");
    } else {
      window.location.replace("/#/login/guest");
    }
  } else {
    const decryptedData = decrypt(localStorage.getItem("sandbox_user"));
    localStorage.removeItem("sandbox_user");
    if (decryptedData.role === "student") {
      window.location.replace("/#/login/student");
    } else if (
      decryptedData.role === "mentor" ||
      decryptedData.role === "admin"
    ) {
      window.location.replace("/#/login/mentor");
    } else {
      window.location.replace("/#/login/guest");
    }
  }
};

export default sessionExpired;
