import React, { useEffect, useState } from "react";
import { useStyles } from "../style";
import { Button, Grid } from "@material-ui/core";
import AdminMcqQuestions from "./AdminMcqQuestions";
import { API } from "../../../../Services/api-service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const AdminQuestions = ({
  questionData,
  sectionId,
  clearAdminEditors,
  getSections,
  nextSection,
  parentQuestionId,
  getSectionWiseData,
  currentAdminSubQuestionNo,
  setCurrentAdminSubQuestionNo,
  totalNoOfSubQns,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const { exerciseId } = useParams();
  const [answerType, setAnswerType] = useState("single-select");
  const [score, setScore] = useState(null);
  const [difficulty, setDifficulty] = useState("Easy");
  const [questionId, setQuestionId] = useState();
  const [allowEdit, setAllowEdit] = useState(true);
  const [options, setOptions] = useState([
    {
      text: "",
      correct: 0,
      sequence: 1,
      disabled: false,
    },
  ]);

  useEffect(() => {
    setQuestionTitle(questionData?.questionText || "");
    setAnswerType(
      !questionData
        ? "single-select"
        : questionData?.questionType === "Single Select"
        ? "single-select"
        : questionData?.questionType === "Multi Select"
        ? "multi-select"
        : "free"
    );
    setScore(questionData?.score || "");
    setQuestionId(questionData?.questionId);
    setAllowEdit(
      questionData?.allowEdit !== undefined ? questionData.allowEdit : true
    );
    setOptions(
      questionData?.answers?.length
        ? questionData?.answers.map((row) => ({
            ...row,
            disabled:
              questionData?.allowEdit !== undefined && !questionData?.allowEdit,
          }))
        : [
            {
              text: "",
              correct: 0,
              sequence: 1,
              disabled: false,
            },
          ]
    );
    setDifficulty(questionData?.difficulty);
  }, [questionData]);

  const handleQuestionTitle = (e) => {
    setQuestionTitle(e.target.value);
  };
  const handleAddScore = (e) => {
    setScore(e.target.value);
  };

  const handleDeleteSection = () => {
    if (!sectionId) {
      return;
    }
    swal({
      title: `Delete Section?`,
      text: `Cannot be recovered once deleted.`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    })
      .then((isDeleted) => {
        if (isDeleted) {
          setLoading(true);
          API.delete(`${exerciseId}/questionnaire/section/${sectionId}`)
            .then((res) => {
              setLoading(false);
              toast.success(res?.data?.msg || "Section deleted successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              clearAdminEditors();
              getSections(nextSection);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(
                err?.response?.data?.msg ||
                  "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              console.log(err);
            });
        }
      })
      .catch((err) => {});
  };

  const handleSaveQuestion = async () => {
    try {
      const urlAddn = questionId ? `/${questionId}` : "";
      setLoading(true);
      const res = await API.post(
        `${exerciseId}/questionnaire/${sectionId}/question${urlAddn}`,
        {
          type:
            answerType === "single-select"
              ? "Single Select"
              : answerType === "multi-select"
              ? "Multi Select"
              : "Open-ended",
          title: questionTitle,
          description: "desc",
          score: score ? parseInt(score, 10) : 0,
          difficulty,
          answers:
            answerType === "single-select" || answerType === "multi-select"
              ? options
              : null,
          parentId: parentQuestionId,
        }
      );
      setLoading(false);
      if (res?.data?.questionId) {
        setQuestionId(res?.data?.questionId);
      }
      toast.success(res?.data?.msg || "Question Added Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getSectionWiseData(sectionId);
    } catch (e) {
      setLoading(false);
      toast.error(
        e?.response?.data?.msg ||
          e?.response?.data?.error ||
          "Error While Saving Question",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const handleDeleteQuestion = () => {
    if (!sectionId || !questionId) {
      return;
    }
    swal({
      title: `Delete Question?`,
      text: `Cannot be recovered once deleted.`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    })
      .then((isDeleted) => {
        if (isDeleted) {
          setLoading(true);
          API.delete(
            `${exerciseId}/questionnaire/${sectionId}/question/${questionId}`
          )
            .then((res) => {
              setLoading(false);
              toast.success(
                res?.data?.msg || "Question deleted successfully!",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              if (
                parentQuestionId &&
                currentAdminSubQuestionNo + 1 === totalNoOfSubQns
              ) {
                setCurrentAdminSubQuestionNo(currentAdminSubQuestionNo - 1);
              }
              getSectionWiseData(sectionId);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(
                err?.response?.data?.msg ||
                  "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            });
        }
      })
      .catch((err) => {});
  };

  const handleAnswerTypeChange = (answerType) => {
    if (answerType === "single-select") {
      const newOptions = options.map((option) => {
        return { ...option, correct: 0 };
      });
      setOptions(newOptions);
    }
    setAnswerType(answerType);
  };

  return (
    <React.Fragment>
      {!parentQuestionId && (
        <Grid
          className={`${classes.flex_row} ${classes.justifyCenter} ${classes.alignItemsCenter}`}
        >
          <Grid
            className={`${classes.flex_row} ${classes.justifyRight} mt-1 mr-2`}
            style={{ width: "61%" }}
          >
            <Button
              onClick={handleDeleteSection}
              className={`${classes.deleteSectionBtn}`}
              disabled={loading}
            >
              Delete Section <DeleteOutlineOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      )}
      <div
        className={`${classes.adminQuestionBox}`}
        style={{
          width: parentQuestionId ? "91%" : "",
          marginRight: parentQuestionId ? "20px" : "",
          marginLeft: parentQuestionId ? "50px" : "",
        }}
      >
        <Grid>
          <Grid
            className={`${classes.font14} ${classes.font400} ${classes.labelClr}`}
          >
            Question
          </Grid>
          <Grid className={`${classes.urlConatiner} mt-2`}>
            <textarea
              rows={1}
              className={classes.inputQuestionText}
              name="input"
              value={questionTitle}
              onChange={(e) => handleQuestionTitle(e)}
              placeholder="Enter Question"
            />
          </Grid>
          <Grid
            className={`${classes.font14} ${classes.font400} ${classes.labelClr} mt-4`}
          >
            Answer type
          </Grid>
          <Grid
            className={`${classes.flex_row} ${classes.alignItemsCenter} mt-2 mb-1`}
          >
            <div className={`${classes.flex_row} ${classes.alignItemsCenter} `}>
              <input
                className="form-check form-check-inline"
                type="radio"
                value="single-select"
                checked={answerType === "single-select"}
                name="single-select"
                onChange={() => handleAnswerTypeChange("single-select")}
                disabled={!allowEdit}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Single Select
              </label>
            </div>
            <div
              className={`${classes.flex_row} ${classes.alignItemsCenter} ml-4`}
            >
              <input
                className="form-check form-check-inline"
                type="radio"
                value="multi-select"
                checked={answerType === "multi-select"}
                name="multi-select"
                onChange={() => handleAnswerTypeChange("multi-select")}
                disabled={!allowEdit}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Multi Select
              </label>
            </div>
            <div
              className={`${classes.flex_row} ${classes.alignItemsCenter} ml-4`}
            >
              <input
                className="form-check form-check-inline"
                type="radio"
                value="free"
                checked={answerType === "free"}
                name="free"
                onChange={() => handleAnswerTypeChange("free")}
                disabled={!allowEdit}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Free text
              </label>
            </div>
          </Grid>
          {(answerType === "single-select" ||
            answerType === "multi-select") && (
            <AdminMcqQuestions
              options={options}
              setOptions={setOptions}
              answerType={answerType}
            />
          )}
          <Grid
            className={`${classes.font14} ${classes.font400} ${classes.labelClr}`}
          >
            Score
          </Grid>
          <Grid>
            <input
              type="number"
              value={score}
              onChange={(event) => handleAddScore(event)}
              placeholder={`Enter Score`}
              className={classes.scoreInput}
            />
          </Grid>
          <Grid
            className={`${classes.font14} ${classes.font400} ${
              classes.labelClr
            } ${parentQuestionId ? "mt-3" : "mt-4"}`}
          >
            Difficulty
          </Grid>
          <Grid
            className={`${classes.flex_row} ${classes.alignItemsCenter} mt-2`}
          >
            <div className={`${classes.flex_row} ${classes.alignItemsCenter} `}>
              <input
                className="form-check form-check-inline"
                type="radio"
                value="Easy"
                checked={difficulty === "Easy"}
                name="Easy"
                onChange={() => setDifficulty("Easy")}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Easy
              </label>
            </div>
            <div
              className={`${classes.flex_row} ${classes.alignItemsCenter} ml-4`}
            >
              <input
                className="form-check form-check-inline"
                type="radio"
                value="Medium"
                checked={difficulty === "Medium"}
                name="Medium"
                onChange={() => setDifficulty("Medium")}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Medium
              </label>
            </div>
            <div
              className={`${classes.flex_row} ${classes.alignItemsCenter} ml-4`}
            >
              <input
                className="form-check form-check-inline"
                type="radio"
                value="Hard"
                checked={difficulty === "Hard"}
                name="Hard"
                onChange={() => setDifficulty("Hard")}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Hard
              </label>
            </div>
          </Grid>
          <Grid className={`${classes.flex_row} ${classes.justifyLeft} mt-3`}>
            <Button
              disabled={loading}
              onClick={handleSaveQuestion}
              className={`${classes.saveInstructionBtn}`}
            >
              Save
            </Button>
            {parentQuestionId && (
              <Button
                disabled={loading}
                onClick={handleDeleteQuestion}
                className={`${classes.deleteQuestionBtn}`}
              >
                <span className="mt-1">Delete Question</span>{" "}
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AdminQuestions;
