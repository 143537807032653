import { API } from "../../Services/api-service";
import { getRole } from "../../Core/utils/auth";
import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import { decrypt } from "../../Core/utils/others";

const decode = (str) => {
  let txt = new DOMParser().parseFromString(str, "text/html");
  return txt.documentElement.textContent;
};

const startTimeFunction = ({
  startTime,
  exerciseId,
  mathWorksheet,
  reAttempt,
  exercise,
  catalogId,
  setAttemptId,
  pageId,
  mathsPageData,
  setPageAttemptId,
  language,
  sectionId
}) => {
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userID = userInfo.userId;
  const logSentry = (msg) => {
    const event = {
      message: msg,
      level: Sentry.Severity.Info,
      extra: {
        exerciseId: exerciseId,
        userID: userID,
      },
    };
    Sentry.captureEvent(event);
  };
  if (getRole() == "student" || getRole() == "guest") {
    const formData = new FormData();
    if (mathWorksheet) {
      let frame = document.getElementById("worksheet");
      let frameContent;
      if (mathsPageData?.properties && mathsPageData.properties?.imported) {
        frameContent =
          frame?.contentDocument?.querySelector("#root")?.outerHTML;
      } else {
        frameContent =
          frame?.contentDocument?.querySelector("#canvas")?.outerHTML;
      }
      // if (!frameContent) return;
      var blob = new Blob([frameContent]);
      let filedata = new File([blob], `${exerciseId}.html`);
      formData.append("language", language);
      formData.append("catalogId", catalogId);
      formData.append("mathWorksheet", 1);
      formData.append("exerciseId", exerciseId);
      formData.append("type", "worksheet-page");
      formData.append("pageId", pageId);
      formData.append("file", filedata);
    }
    if(exercise?.category === "Questionnaire"){
      formData.append("category", "Questionnaire");
      formData.append("sectionId", sectionId);
      formData.append("exerciseId", exerciseId);
      }
    if (!reAttempt) {
      formData.append("captureStart", 1);
    }
    formData.append("startTime", startTime);
    if (mathWorksheet == 0 && exercise?.category !== "Questionnaire") {
      formData.append("exerciseId", exerciseId);
      formData.append("category", "coding");
    }
    if (reAttempt) {
      formData.append("reAttempt", reAttempt);
    }
    API.post(`/exercises`, formData)
      .then((res) => {
        if (mathWorksheet) {
          if (mathsPageData?.pageNo === 1) {
            setAttemptId(res.data.recentAttempt?.attemptId);
          }
          setPageAttemptId(res.data.page?.recentAttempt?.attemptId);
        } else {
          setAttemptId(res.data.recentAttempt?.attemptId);
        }
      })
      .catch((e) => {
        if (mathWorksheet) {
          logSentry("maths capture start error");
        } else {
          logSentry("capture start error for exercises other than maths");
        }
      });
  }
};

export default startTimeFunction;
