import * as Sentry from "@sentry/react";

const logSentry = (msg, backgroundUrl, htmlResponseArr, exerciseId, userID) => {
  const event = {
    message: msg,
    level: Sentry.Severity.Info,
    extra: {
      exerciseId: exerciseId,
      userID: userID,
      iframeImages: htmlResponseArr,
      apiImages: backgroundUrl,
    },
  };
  Sentry.captureEvent(event);
};

export const CompareHtml = (type, data, backgroundUrl, exerciseId, userID, setMissingImage) => {
  let frame = `${data}`;
  let tempElement = document.createElement("div");
  tempElement.innerHTML = frame;
  let imgElements = tempElement.querySelectorAll("img");
  let htmlResponseArr = [];
  imgElements.forEach((img) => {
    let src = img.getAttribute("src");
    if (src) {
      htmlResponseArr.push(src);
    }
  });
  let message =
    type === "submission"
      ? "Math Missing image on First Submit"
      : "Math Missing image on First Load";
  if (backgroundUrl.length > 0) {
    for (let i = 0; i < backgroundUrl.length; i++) {
      if (
        backgroundUrl[i]?.slice(backgroundUrl[i]?.lastIndexOf("math")) !==
          htmlResponseArr[i]?.slice(htmlResponseArr[i]?.lastIndexOf("math")) ||
        htmlResponseArr[i]?.slice(htmlResponseArr[i]?.lastIndexOf("math")) ===
          undefined
      ) {
        if(type === "loading"){
          setMissingImage(true)
        }
        logSentry(message, backgroundUrl, htmlResponseArr, exerciseId, userID);
      }
    }
  }
};
