import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  exerciseV2Container: {
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 56px)",
    },
  },
  root: {
    fontFamily: "Inter",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      padding: "24px 0px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "16px 0px",
      height: "100%",
    },
  },

  sectionsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      padding: "0",
    },
  },
  sectionsContainer: {
    maxHeight: "100%",
    maxWidth: "720px",
    minWidth: "720px",
    border: "1px solid #EAECF3",
    padding: "32px 32px 0 32px",
    borderRadius: "8px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      marginTop: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#EBEBEB",
      borderRadius: "100px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D6D6D6",
      borderRadius: "100px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
      border: "none",
      padding: "12px 12px 0 12px",
    },
  },
  sectionsInstructionType: {
    width: "100%",
    borderRadius: "8px",
    [theme.breakpoints.between("sm", "md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px",
    },
  },
  questionContainer: {
    display: "flex",
    flexDirection: "column",
  },

  actionsContainer: {
    display: "flex",
    position: "sticky",
    bottom: 0,
    background: "#FFF",
    padding: "11px 0",
  },

  actionBackBtn: {
    color: "#FF712D",
    padding: "10px 24px",
    borderRadius: "4px",
    background: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "inherit",
    border: "1px solid #FF712D",

    "& .MuiButton-startIcon": {
      marginRight: "0px",
    },
  },

  actionNextBtn: {
    background: "#FF712D",
    padding: "10px 24px",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "inherit",

    "&:hover": {
      background: "#FF712D",
      color: "#FFFFFF",
    },
    "&:focus": {
      background: "#FF712D",
      // outline: 'none',
      color: "#FFFFFF",
    },

    "& .MuiButton-endIcon": {
      marginLeft: "0px",
    },

    [theme.breakpoints.down("md")]: {
      width: "172px",
    },
  },

  backIcon: {
    fontSize: "22px",
  },

  nextIcon: {
    fontSize: "22px",
  },

  sectionCountContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      position: "sticky",
      bottom: 10,
    },

    // position: "absolute",
    // left: "50%",
    // transform: "translateX(-50%)",
    // bottom: "16px",
  },

  sectionCountWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "14px",
    [theme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },
  innerRoot: {
    background: "#FFF",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    // [theme.breakpoints.up("md")]: {
    //   height: "85%",
    // },
    // [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    height: "calc(100% - 120px)",
    // },
  },
  font40: {
    fontSize: "40px",
  },
  font24: {
    fontSize: "24px",
  },
  font32: {
    fontSize: "32px",
  },
  font20: {
    fontSize: "20px",
  },
  font18: {
    fontSize: "18px",
  },
  font16: {
    fontSize: "16px",
  },
  font14: {
    fontSize: "14px",
  },
  font12: {
    fontSize: "12px",
  },
  font800: {
    fontWeight: 800,
  },
  font700: {
    fontWeight: 700,
  },
  font500: {
    fontWeight: 500,
  },
  font600: {
    fontWeight: 600,
  },
  font400: {
    fontWeight: 400,
  },
  headingClr: {
    color: "#292929",
  },
  subHeadingClr: {
    color: "#2F4F4F",
  },
  fadedClr: {
    color: "#95B2B2",
  },
  timerWrapper: {
    background: "#E7EEEE",
    border: "1px solid #C0D8D8",
  },
  flex_row: {
    display: "flex",
  },
  flex_col: {
    display: "flex",
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyAround: {
    justifyContent: "space-around",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyLeft: {
    justifyContent: "left",
  },
  alignContentCenter: {
    alignItems: "center",
    alignContent: "center",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignContentleft: {
    alignItems: "left",
    alignContent: "left",
  },
  alignContentTop: {
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  justifyRight: {
    justifyContent: "right",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textAlignleft: {
    textAlign: "left",
  },
  btnBackgroundClr: {
    color: "#FF712D",
  },
  width100: {
    width: "100%",
  },
  width90: {
    width: "90%",
  },
  divider: {
    margin: "10px 0px",
  },
  InstructionsAndQuestionBox: {
    width: "100%",
    height: "100%",
    padding: "15px 0px",
    color: "#292929",
  },
  questionSetBox: {
    marginLeft: "50px",
    paddingRight: "5px",
    width: "auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      paddingRight: "8px",
    },
  },
  qNoBox: {
    // height: "35px",
    width: "max-content",
    padding: "10px",
    borderRadius: "8px",
    background: "#E7EEEE",
    color: "#2F4F4F",
  },
  scoreInput: {
    marginTop: "10px",
    border: "1px solid #939393",
    width: "30%",
    height: "38px",
    borderRadius: "4px",
    outline: "none",
    padding: "10px",
  },
  sectionCountText: {
    color: "#2F4F4F",
    fontWeight: "600",
    fontSize: "16px",
  },
  questionSetPaginationContainer: {
    marginLeft: "50px",
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  questionCountContainer: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  questionCountText: {
    color: "#2F4F4F",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14.52px",
  },
  addQuestionContainer: {
    marginLeft: "auto",
  },
  optionsBox: {
    border: "1px solid #D9DDE8",
    borderRadius: "8px",
    width: "100%",
    // height: "44px",
    padding: "10px",
    textTransform: "none",
    textAlign: "left",
    marginTop: "16px !important",
  },
  optionNoBox: {
    background: "#E7EEEE",
    minHeight: "24px",
    minWidth: "24px",
    borderRadius: "50%",
  },

  nextQuestionBtn: {
    height: "38px",
    minWidth: "128px",
    width: "max-content",
    padding: "10px 24px",
    borderRadius: "4px",
    textTransform: "none",
    background: "#FF712D",
    color: "#FFF",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },
  prevQuestionBtn: {
    height: "38px",
    minWidth: "128px",
    width: "max-content",
    padding: "10px 24px",
    borderRadius: "4px",
    textTransform: "none",
    background: "#FFF",
    border: "1px solid #FF712D",
    color: "#FF712D",
    "&:hover": {
      background: "#FFF",
      color: "#FF712D",
    },
  },
  goBackBtn: {
    color: "#F2B202",
    textTransform: "none",
    "&:hover": {
      background: "#FFF",
      color: "#F2B202",
    },
  },
  timerText: {
    color: "#2F4F4F",
  },
  textAreaInput: {
    border: "1px solid #D9DDE8",
    color: "#939393",
    width: "100%",
    minHeight: "200px",
    maxHeight: "270px",
    padding: "15px",
    overflow: "auto",
  },
  videoPlayer: {
    width: "720px",
    height: "372px",
    // borderRadius: "8px",
    marginBottom: "18px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  frameScore: {
    position: "relative",
    height: "200px",
    objectFit: "conatin",
  },
  scoreTimeBox: {
    position: "absolute",
    bottom: theme.spacing(0.2),
    left: theme.spacing(4),
  },
  score: {
    position: "absolute",
    bottom: theme.spacing(5),
    left: theme.spacing(3),
    background: "linear-gradient(180deg, #934D10 -14.3%, #B85614 80.3%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  scorePercent: {
    position: "absolute",
    top: theme.spacing(6),
    left: theme.spacing(4),
    background: "linear-gradient(180deg, #934D10 -14.3%, #B85614 80.3%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  time: {
    background: "linear-gradient(180deg, #FDF4ED -14.3%, #FDF3ED 80.3%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  successViewBtn: {
    width: "172px",
    height: "43px",
    borderRadius: "12px",
    padding: "12px 20px",
    boxShadow: "0px -4px 0px 0px #CE7612 inset",
    background: "linear-gradient(123.51deg, #F27202 23.31%, #F5A300 77.69%)",
    color: "#FFF",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      maxWidth: "156px",
    },
  },
  viewResultsBtn: {
    marginLeft: "16px",
  },
  successBtnBox: {
    margin: " 20px auto",
  },
  tableHeadCell: {
    background: "#F96F60",
    color: "#FFF",
  },
  tableBodyCell: {
    color: "#B05413",
  },
  tableBox: {
    [theme.breakpoints.up("md")]: {
      minWidth: "534px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },
  tableContainer: {
    maxHeight: 250,
    border: "1px solid #AC5313",
    borderRadius: "8px",
    overflow: "auto",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
  },
  nextExercise: {
    border: "1px solid #FF712D",
    color: "#FF712D",
    height: "43px",
    width: "max-content",
    borderRadius: "12px",
    padding: "12px 20px",
    textTransform: "none",
    margin: "20px auto",
  },
  adminBox: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  addSectionBtn: {
    width: "max-content",
    padding: "10px 8px",
    height: "40px",
    border: "1px solid #FF712D",
    color: "#FF712D",
    textTransform: "none",
  },
  addSectionBtnContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputUrlText: {
    border: "1px solid #939393",
    borderRadius: "4px",
    width: "480px",
    height: "40px",
    padding: "5px",
    marginTop: "5px",
  },
  inputQuestionText: {
    border: "1px solid #939393",
    borderRadius: "4px",
    width: "100%",
    padding: "15px",
    height: "auto",
  },
  file: {
    display: "none",
  },
  uploadItemBox: {
    background: "#EBEBEB",
    height: "38px",
    width: "max-content",
    padding: "10px 8px",
    color: "#3F3F3F",
  },
  labelClr: {
    Color: "#525252",
  },
  cancelBtn: {
    border: "1px solid #939393",
    color: "#939393",
    padding: "6px 10px",
    borderRadius: "4px",
    textTransform: "none",
  },
  proceedBtn: {
    background: "#FF712D",
    color: "#FFF",
    padding: "6px 10px",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },
  saveBtn: {
    background: "#FF712D",
    color: "#FFF",
    padding: "6px",
    width: "120px",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "10px",
  },
  deleteSectionBtn: {
    color: "#E76B69",
    textTransform: "none",
  },
  adminInstructionBox: {
    border: "1px solid #EAECF3",
    width: "60%",
    height: "90%",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  adminVideoBox: {
    border: "1px solid #EAECF3",
    width: "60%",
    height: "85%",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  textEditorContainer: {
    margin: "5px 20px",
    display: "flex",
    flex: 1,
  },
  instructionScoreContainer: {
    margin: "0px 20px 20px 20px",
  },
  adminQuestionBox: {
    border: "1px solid #EAECF3",
    borderRadius: "8px",
    margin: "0 auto",
    width: "60%",
    height: "auto",
    maxHeight: "90%",
    overflow: "auto",
    padding: "15px",
  },
  mcqOptionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "auto",
    border: "1px solid #939393",
    borderRadius: "4px",
    width: "100%",
    height: "auto",
    padding: "1px",
  },
  mcqOption: {
    marginRight: "10px",
    border: "none",
    height: "100%",
    width: "80%",
    outline: "none",
    padding: "15px",
  },
  uploadBtnBox: {
    width: "480px",
    marginTop: "15px",
  },
  uploadBtnLabel: {
    color: "#FF712D",
    cursor: "pointer",
  },
  addOptionBtn: {
    textTransform: "none",
    color: "#FF712D",
    padding: "0 8px 0 0",
  },
  saveInstructionBtnContainer: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
    background: "#FFF",
    padding: "11px 0",
  },
  saveInstructionBtn: {
    width: "143px",
    textTransform: "none",
    background: "#FF712D",
    color: "#FFF",
    height: "40px",
    "&:hover": {
      background: "#FF712D",
      color: "#FFF",
    },
  },
  deleteQuestionBtn: {
    textTransform: "none",
    width: "143px",
    height: "40px",
    gap: "10px",
    borderRadius: "4px",
    border: "1px solid #E76B69",
    marginLeft: "10px",
    color: "#E76B69",
  },
  scoreContainer: {
    position: "relative",
    minWidth: "192px",
    minHeight: "229px",
    border: "6px solid #F98170",
    background: "linear-gradient(180deg, #F9ECC8 0%, #F9EDC8 100%)",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },

  scoreText: {
    background: "linear-gradient(180deg, #934D10 -14.3%, #B85614 80.3%)",
    backgroundClip: "text",
    color: "transparent",
  },

  completedContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#F9F0E7",
    border: "1px solid #EAECF3",
    borderRadius: "1px",
  },
  scoreLeaf: {
    position: "absolute",
    objectFit: "contain",
    top: "-40px",
    height: "60px",
  },
  percentageText: {
    margin: "0px 0px 20px",
    fontSize: "40px",
    fontWeight: "700",
    lineHeight: "48.41px",
    background: "linear-gradient(180deg, #934D10 -14.3%, #B85614 80.3%)",
    backgroundClip: "text",
    color: "transparent",
  },

  customScoreBox: {
    // background: "linear-gradient(180deg, #F6E2AC 1.28%, #F7DEAB 100%)",
    // minHeight: "78px",
    // minWidth: "144px",
    // border: "0.4px solid #ECDBBD",
    // boxShadow: "0px 1px 1px 0px #E6CFA8 inset",
    position: "relative",
    display: "inline-block",
  },
  timerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    minWidth: "128px",
    height: "40px",
    background: "#F17056",
    border: "4px solid #F0C99D",
    borderRadius: "16px",
    bottom: "-22px",
  },
  timerClock: {
    width: "24px",
    height: "27px",
    marginRight: "4px",
  },
  completedTime: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "19.36px",
    color: "#ffffff",
  },
  questionText: {
    fontSize: "14px !important",
    fontFamily: "Inter",
    lineHeight: "16.94px",
    color: "#292929",
    whiteSpace: "pre-wrap",
  },
  optionText: {
    fontSize: "14px !important",
    fontFamily: "Inter",
    lineHeight: "16.94px",
    color: "#292929",
    paddingLeft: "8px",
    whiteSpace: "pre-wrap",
  },
  quillContainer: {
    minHeight: 224,
    "& .ql-container": {
      minHeight: 224,
    },
    "& .ql-editor": {
      minHeight: 224,
    },
  },
  fullHeight: {
    height: "100%",
  },
  equalHeight: {
    [theme.breakpoints.down("md")]: {
      height: "calc(50% - 6px)",
    },
    height: "100%",
    overflowY: "auto",
    scrollbarGutter: "stable",
    "&::-webkit-scrollbar": {
      width: "6px",
      marginTop: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#EBEBEB",
      borderRadius: "100px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D6D6D6",
      borderRadius: "100px",
    },
  },
  userInstructionContainer: {
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
    marginRight: "24px",
    paddingRight: "8px",
  },
  verticalDivider: {
    display: "flex",
    justifyContent: "center",
    color: "#EAECF3",
    margin: "0 -32px",
  },
  horizontalDivider: {
    color: "#EAECF3",
    margin: "12px 0px",
  },

  questionSetContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
  cancelMoveSectionBtn: {
    marginLeft: "10px",
    marginRight: "20px",
    width: "128px",
    height: "40px",
    border: "1px solid #939393",
    borderRadius: "4px",
    textTransform: "none",
    color: "#939393",
  },

  headerActions: {
    position: "relative",
    marginBottom: "14px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "12px",
    },
  },
  mobileTimer: {
    // [theme.breakpoints.down("md")]: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    // },
  },
  labelInput: {
    position: "relative",
    margin: ".5rem",
    lineHeight: "135%",
    cursor: "pointer",
  },
  customCheckbox: {
    position: "relative",
    margin: "0 1rem 0 0",
    cursor: "pointer",

    // Pseudo-element styles
    "&::before": {
      content: '""',
      position: "absolute",
      left: "0.1rem",
      top: "0.225rem",
      zIndex: 1,
      width: "1rem",
      height: "0.375rem",
      border: "2px solid #fff",
      borderTopStyle: "none",
      borderRightStyle: "none",
      transform: "rotate(-45deg) scale(0, 0)",
      transition: "transform .4s cubic-bezier(.45, 1.8, .5, .75)",
    },

    "&:checked::before": {
      transform: "rotate(-45deg) scale(1, 1)",
    },

    "&::after": {
      content: '""',
      position: "absolute",
      top: "-0.05rem",
      left: "0",
      width: "1.2rem",
      height: "1.2rem",
      background: "#fff",
      border: "2px solid #aaa",
      borderRadius: "4px",
      cursor: "pointer",
    },

    "&:checked::after": {
      background: "#7EC8A6",
      border: "2px solid #2ecc71",
    },
  },
  customRadioButton: {
    position: "relative",
    margin: "0 1rem 0 0",
    cursor: "pointer",

    // Pseudo-element styles
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0.025rem",
      left: "0.025rem",
      zIndex: 1,
      width: "1rem",
      height: "1rem",
      background: "#7EC8A6",
      borderRadius: "50%",
      transform: "scale(0, 0)",
      transition: "transform .4s cubic-bezier(.45, 1.8, .5, .75)",
    },

    "&:checked::before": {
      transform: "scale(1, 1)",
    },

    "&::after": {
      content: '""',
      position: "absolute",
      top: "-0.1rem",
      left: "-0.1rem",
      width: "1.25rem",
      height: "1.25rem",
      background: "#fff",
      border: "2px solid #aaa",
      borderRadius: "50%",
    },

    "&:checked::after": {
      border: "3.5px solid #2ecc71",
    },
  },
  navButtonsContainer: {
    position: "sticky",
    bottom: 0,
    background: "#FFF",
    width: "100%",
    paddingTop: "1rem",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0.3rem",
      paddingBottom: "0.3rem",
    },
  },
  questionSummaryContainer: {
    display: "flex",

    justifyContent: "space-between",
    alignItems: "center",
  },
  insightsScores: {
    padding: "10px",
    borderRadius: "8px",
    background: "#E7EEEE",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14.52px",
    color: "#2F4F4F",
  },
  actualScore: {
    fontWeight: "700",
  },
  questionHighlightContainer: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0",
  },
  infoIcon: {
    color: "#FF961F",
  },
  infoText: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14.52px",
    color: "#FF961F",
    marginLeft: "4px",
  },
  colorTipContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "14px",
    paddingBottom: "10px",
    fontSize: "12px",
  },
  colorTile: {
    width: "10px",
    height: "10px",
    backgroundColor: "#FFB35C",
    marginRight: "5px",
  },

  saveWrapper: {
    display: "flex",
    alignItems: "center",
  },
  saveIcon: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16.41px",
    color: "#FF712D",
    marginRight: "4px",
    cursor: "pointer",
  },
}));
