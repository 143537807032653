import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import { toast } from "react-toastify";
import { useStyles } from "../style";
import { API } from "../../../../Services/api-service";

toast.configure();

const AddExercise = ({
  open,
  close,
  setStickyType,
  setTextEditor,
  setVideoEditor,
  setSingleQuestion,
  setParentQuestionId,
  exerciseId,
  sectionId,
  getSectionWiseData,
}) => {
  const classes = useStyles();
  const [editorType, setEditorType] = useState("instruction");
  const [instructionType, setInstructionType] = useState("video");
  const [questionType, setQuestionType] = useState("yes");
  const [loading, setLoading] = useState(false);

  const createQuestionSet = () => {
    setLoading(true);
    API.post(`/${exerciseId}/questionnaire/${sectionId}/question`, {
      type: "Question Set",
      title: "Question Set Title",
      description: "Please replace this message with instructions.",
      score: 0,
      difficulty: "Easy",
    })
      .then((res) => {
        setLoading(false);
        setStickyType(true);
        setParentQuestionId(res?.data?.questionId);
        getSectionWiseData(sectionId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.msg || "Failed to create Question Set",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const ProceedHandler = () => {
    setVideoEditor(false);
    setSingleQuestion(false);
    setStickyType(false);
    setTextEditor(false);
    if (instructionType === "video" && editorType === "instruction") {
      setVideoEditor(true);
    } else if (instructionType === "text" && editorType === "instruction") {
      setTextEditor(true);
    } else if (questionType === "yes" && editorType === "question") {
      createQuestionSet();
    } else if (questionType === "no" && editorType === "question") {
      setSingleQuestion(true);
    }
    close();
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <div className="ml-3 mr-3 add-course" style={{ marginTop: "3%" }}>
          <div
            className={`${classes.heading} ${classes.font800} ${classes.font20}`}
          >
            Add exercise details
          </div>
          <br />
          <div
            className={`${classes.flex_col} ${classes.alignContentleft} ${classes.textAlignleft} mb-3`}
          >
            <div
              className={`${classes.font400} ${classes.font14} ${classes.labelClr}`}
            >
              Select editor type
            </div>
            <div className="mt-2 ml-3">
              <input
                className="form-check-input"
                type="radio"
                value="instruction"
                checked={editorType === "instruction"}
                name="instruction"
                onChange={() => setEditorType("instruction")}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Instruction
              </label>
            </div>
            <div className="mt-2 ml-3">
              <input
                className="form-check-input"
                type="radio"
                value="question"
                checked={editorType === "question"}
                name="question"
                onChange={() => setEditorType("question")}
              />
              <label className={`${classes.formCheckLabel} form-check-label`}>
                Question
              </label>
            </div>
          </div>
          <Divider />
          {editorType === "instruction" ? (
            <>
              <div
                className={`${classes.flex_col}  ${classes.alignContentleft} ${classes.textAlignleft} mt-2 mb-3`}
              >
                <div
                  className={`${classes.font400} ${classes.font14} ${classes.labelClr}`}
                >
                  Select instruction type
                </div>
                <div className="mt-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="video"
                    checked={instructionType === "video"}
                    name="video"
                    onChange={() => setInstructionType("video")}
                  />
                  <label
                    className={`${classes.formCheckLabel} form-check-label`}
                  >
                    Video
                  </label>
                </div>
                <div className="mt-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="text"
                    checked={instructionType === "text"}
                    name="text"
                    onChange={() => setInstructionType("text")}
                  />
                  <label
                    className={`${classes.formCheckLabel} form-check-label`}
                  >
                    Text
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`${classes.flex_col} mt-2 mb-3`}>
                <div
                  className={`${classes.font400} ${classes.font14} ${classes.labelClr}`}
                >
                  Sticky question
                </div>
                <div className="mt-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="yes"
                    checked={questionType === "yes"}
                    name="yes"
                    onChange={() => setQuestionType("yes")}
                  />
                  <label
                    className={`${classes.formCheckLabel} form-check-label`}
                  >
                    Yes
                  </label>
                </div>
                <div className="mt-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="no"
                    checked={questionType === "no"}
                    name="no"
                    onChange={() => setQuestionType("no")}
                  />
                  <label
                    className={`${classes.formCheckLabel} form-check-label`}
                  >
                    No
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <div className={`${classes.flex_row} ${classes.justifyAround}`}>
        <DialogActions>
          <Button className={classes.cancelBtn} onClick={close}>
            Cancel
          </Button>
          <Button
            onClick={ProceedHandler}
            className={classes.proceedBtn}
            disabled={loading}
          >
            Proceed
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddExercise;
