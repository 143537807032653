import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  layout: {
    padding: "24px 44px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    marginBottom: "20px",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "row",
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "3%",
    color: "#939393",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #939393",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  submitBtn: {
    padding: "8px 30px",
    color: "white",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 44px 20px 44px",
  },
});

const Styles = ({
  open,
  handleFontSize,
  closeModalHandler,
  size,
  fontColor,
}) => {
  const [fontSize, setFontSize] = useState("");
  const [color, setColor] = useState("");
  const classes = useStyles();

  useEffect(() => {
    let trunctedStr = size?.replace("px", "");
    setFontSize(trunctedStr);
    setColor(fontColor);
  }, []);

  const onSubmit = () => {
    handleFontSize(`${fontSize}px`, color);
  };

  const handleChange = (e) => {
    setFontSize(e.target.value);
  };

  const handleChangeColor = (e) => {
    setColor(e.target.value);
  };
  return (
    <Dialog open={open} maxWidth="lg" className={classes.layout}>
      <DialogContent>
        <div>
          <Typography className={classes.title}>Format</Typography>
          <div className={classes.inputWrapper}>
            <Form.Group className="col-lg-6">
              <Form.Control
                type="text"
                placeholder="Enter Font Size"
                onChange={handleChange}
                value={fontSize}
              />
            </Form.Group>

            <Form.Group className="col-lg-6">
              <Form.Control
                type="text"
                placeholder="Enter Font Color"
                onChange={handleChangeColor}
                value={color}
              />
            </Form.Group>
          </div>
        </div>
      </DialogContent>
      <div>
        <DialogActions className={classes.actions}>
          <Button onClick={closeModalHandler} className={classes.cancelBtn}>
            Cancel
          </Button>
          <Button onClick={onSubmit} className={classes.submitBtn}>
            OK
          </Button>
        </DialogActions>
      </div>
      {/* {error && <ErrorPopUp getCourses={getCourses} />} */}
    </Dialog>
  );
};

export default Styles;
