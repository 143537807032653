import React from "react";

import Logo1 from "../../assets/images/Clockwise Spin.svg";
import Logo2 from "../../assets/images/Anticlockwise spin.svg";
import { makeStyles } from "@material-ui/core/styles";

import "./LoaderComponent.scss";

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.background0,
  },
}));

const LoaderComponent = ({ message }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.loader}>
        <img className="f1" src={Logo1} alt="Logo 1" />
        <img className="f2" src={Logo2} alt="Logo 2" />
      </div>
      <p>{message}</p>
    </div>
  );
};

export default LoaderComponent;
