import { Button, Grid, Divider } from "@material-ui/core";
import React, { useState } from "react";
import Questions from "./Questions";
import Instructions from "./Instructions";
import { getRole } from "../../../Core/utils/auth";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useStyles } from "./style";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import swal from "sweetalert";
import { API } from "../../../Services/api-service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import AdminSubQuestionsWrapper from "./Admin/AdminSubQuestionsWrapper";

const InstructionType = ({
  questionData,
  handleNextSection,
  handleAnswers,
  setHasAnswered,
  questionSetIndex,
  handlePreviousQuestionSet,
  nextSection,
  viewResults,
  setIconDisabled,
  clearAdminEditors,
  getSections,
  sectionId,
  parentQuestionId,
  getSectionWiseData,
  nextLoader,
  currentAdminSubQuestionNo,
  setCurrentAdminSubQuestionNo,
  isExerciseCompleted,
  handleOpenEnded,
}) => {
  const classes = useStyles();
  const role = getRole();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const { exerciseId } = useParams();
  const [questionsArr, setQuestionsArr] = useState(
    questionData?.subQuestions?.filter(
      (row) => row?.questionType !== "Instruction - Text"
    )
  );

  const handleDeleteSection = () => {
    if (!sectionId) {
      return;
    }
    swal({
      title: `Delete Section?`,
      text: `Cannot be recovered once deleted.`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    })
      .then((isDeleted) => {
        if (isDeleted) {
          setLoading(true);
          API.delete(`${exerciseId}/questionnaire/section/${sectionId}`)
            .then((res) => {
              setLoading(false);
              toast.success(
                res?.data?.msg || "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              clearAdminEditors();
              getSections(nextSection);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(
                err?.response?.data?.msg ||
                  "Something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              console.log(err);
            });
        }
      })
      .catch((err) => {});
  };

  return (
    <Grid
      container
      spacing={0}
      className={`${classes.fullHeight} ${classes.questionSetContainer}`}
    >
      <Grid
        item
        xs={12}
        md={6}
        className={`${classes.equalHeight} ${classes.userInstructionContainer}`}
      >
        <Instructions
          sectionId={sectionId}
          questionData={questionData}
          handleAnswers={handleAnswers}
          setHasAnswered={setHasAnswered}
          setIconDisabled={setIconDisabled}
          parentQuestionId={parentQuestionId}
          handleOpenEnded={handleOpenEnded}
        />
      </Grid>
      {matchesDesktop ? (
        <Grid item md={"auto"} className={classes.verticalDivider}>
          <Divider
            orientation="vertical"
            // flexItem
            sx={{ mx: 2, borderRightWidth: 2 }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.horizontalDivider}>
          <Divider />
        </Grid>
      )}

      <Grid item xs={12} md={6} className={classes.equalHeight}>
        {role === "admin" && (
          <Grid
            className={`${classes.flex_row} ${classes.justifyRight} mt-2 mr-2 mb-2`}
          >
            <Button
              disabled={loading}
              onClick={handleDeleteSection}
              className={`${classes.deleteSectionBtn}`}
            >
              Delete Section <DeleteOutlineOutlinedIcon fontSize="small" />
            </Button>
          </Grid>
        )}
        {role === "admin" ? (
          <AdminSubQuestionsWrapper
            questionsArr={questionsArr}
            setQuestionsArr={setQuestionsArr}
            sectionId={sectionId}
            parentQuestionId={parentQuestionId}
            getSectionWiseData={getSectionWiseData}
            currentAdminSubQuestionNo={currentAdminSubQuestionNo}
            setCurrentAdminSubQuestionNo={setCurrentAdminSubQuestionNo}
          />
        ) : (
          <Questions
            data={questionData}
            handleNextSection={handleNextSection}
            handleAnswers={handleAnswers}
            setHasAnswered={setHasAnswered}
            questionSetIndex={questionSetIndex}
            handlePreviousQuestionSet={handlePreviousQuestionSet}
            nextSection={nextSection}
            viewResults={viewResults}
            setIconDisabled={setIconDisabled}
            nextLoader={nextLoader}
            isExerciseCompleted={isExerciseCompleted}
            handleOpenEnded={handleOpenEnded}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InstructionType;
