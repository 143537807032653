import React from "react";
import { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TrackerHeaderData from "./TrackerHeaderData";
import student_icon from "../../assets/images/students--icon.png";
import pending_icon from "../../assets/images/pending--icon.png";
import completed_icon from "../../assets/images/completed--icon.png";
import search_key from "../../assets/images/search_exercise.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Loader from "../../Core/Loader/Loader";
import filter from "../../assets/images/filter.png";
import completedTracker from "../../assets/images/completedTracker.png";
import assignedTracker from "../../assets/images/assignedTracker.png";
import TableData from "./TableData";
import { API } from "../../Services/api-service";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import Filter from "./Filter";
import completed_dark from "../../assets/images/completed_dark.svg";
import active_dark from "../../assets/images/active_dark.svg";
import assigned_dark from "../../assets/images/assigned_dark.svg";
import exercises_dark from "../../assets/images/exercises_dark.svg";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  resultContainer: {
    display: "flex",
    background: theme.palette.background.background2,
    height: "70px",
    marginBottom: "20px",
    marginTop: "30px",
    padding: "10px",
    border: `1px solid ${theme.palette.searchBorderOuter}`,
    borderRadius: "8px",
    // justifyContent: "space-between",
  },
  searchFieldContainer: {
    background: theme.palette.background.background3,
    border: `1px solid ${theme.palette.searchBorder}`,
    borderRadius: "4px",
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    height: "50px",
    marginBottom: "20px",
    color: "#A0A0A0",
    padding: "0 5px 0 5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchField: {
    border: "none",
    width: "100%",
    outline: "none",
    background: theme.palette.background.background3,
    color: theme.palette.searchText,
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
  },
  checkBoxLast: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  label: {
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.palette.body,
    marginLeft: "5px",
  },
  searchBylabel: {
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.palette.label,
    marginTop: "0px",
    marginLeft: "20%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  filterBox: {
    position: "absolute",
    background: "#FFFFFF",
    boxShadow:
      "0px 2px 8px rgba(0, 0, 0, 0.16), 0px 2px 8px rgba(0, 0, 0, 0.16)",
    width: "350px",
    maxHeight: "250px",
    overflowY: "scroll",
    zIndex: 1,
    marginTop: "50px",
    marginLeft: "0px",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
  },
  filterDay: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "15px",
  },
  searchResults: {
    display: "flex",
    paddingBottom: "10px",
  },
  searchResultsText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "21px",
    color: "#7A7A7A",
  },
  clearResults: {
    marginLeft: "30px",
    color: "#FF712D",
    fontSize: "17px",
    lineHeight: "21px",
    cursor: "pointer",
  },
}));
function ExerciseTracker() {
  const classes = useStyles();
  const ref = useRef();
  const [batchFilter, setBatchFilter] = useState(false);
  const [studentFilter, setStudentFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [assignedFilter, setAssignedFilter] = useState(false);
  const [searchTxt, setSearchTxt] = useState();
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("md"));
  const [trackerData, setTrackerData] = useState();
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [range, setRange] = useState(30);
  const [activeSearchResults, setActiveSearchResults] = useState(false);
  const updateStatus = (filter) => {
    if (filter == "batch") setBatchFilter(!batchFilter);
    else if (filter == "student") setStudentFilter(!studentFilter);
    else if (filter == "email") setEmailFilter(!emailFilter);
    else setAssignedFilter(!assignedFilter);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  const getReport = () => {
    setLoading(true);
    API.get(
      `/report/exercise?page=${currentPage}&limit=${rowsPerPage}&range=${range}`
    )
      .then((res) => {
        setLoading(false);
        setTrackerData(res.data);
        setTotalRows(res.data?.total);
      })
      .catch((err) => {
        setLoading(false);
        console.error("error while fetching exersie data", err);
      });
  };
  useEffect(() => {
    if (!assignedFilter) {
      getReport();
    }
  }, [range, currentPage, assignedFilter]);
  useEffect(() => {
    if (assignedFilter) {
      handleSearch();
    }
  }, [assignedFilter, currentPage]);
  useEffect(() => {
    if (searchTxt?.length == 0) {
      getReport();
    }
  }, [searchTxt]);

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const isDarkMode = useSelector(selectDarkMode);
  const getOpenFilterData = () => {
    return (
      <div
        open={openFilter}
        setOpen={setOpenFilter}
        ref={ref}
        className={classes.filterBox}
      >
        {["batch", "student", "email", "assigned"].map((filter) => {
          return (
            <React.Fragment>
              <div className={classes.checkBox} style={{ marginBottom: "5px" }}>
                <input
                  type="checkbox"
                  value={filter}
                  checked={
                    filter == "batch"
                      ? batchFilter
                      : filter == "student"
                      ? studentFilter
                      : filter == "email"
                      ? emailFilter
                      : assignedFilter
                  }
                  onChange={() => updateStatus(filter)}
                />
                <span className={classes.label}>{filter}</span>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  const handleClearResults = () => {
    setSearchTxt("");
    setAssignedFilter(false);
    setEmailFilter(false);
    setStudentFilter(false);
    setBatchFilter(false);
    setActiveSearchResults(false);
    setCurrentPage(1);
  };
  const handleSearch = () => {
    const match = [];
    if (batchFilter) match.push("batch");
    if (emailFilter) match.push("email");
    if (studentFilter) match.push("student");
    if (assignedFilter) match.push("assigned");
    setLoading(true);
    API.get(
      `/report/exercise?page=${currentPage}&range=${range}&limit=${rowsPerPage}&text=${searchTxt}&match=${match.join(
        ","
      )}`
    )
      .then((res) => {
        setLoading(false);
        setTrackerData(res.data);
        setTotalRows(res.data?.total);
        setActiveSearchResults(true);
      })
      .catch((err) => {
        setLoading(false);
        console.error("error while fetching exersie data", err);
      });
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.filterDay}>
            <Filter range={range} setRange={setRange} />
          </div>
          <Grid container spacing={3} className={classes.headerContainer}>
            <TrackerHeaderData
              count={trackerData?.summary?.active}
              text="ACTIVE STUDENTS"
              icon={isDarkMode ? active_dark : student_icon}
            />
            <TrackerHeaderData
              count={trackerData?.summary?.overAllExerciseDone?.length}
              text="EXERCISES DONE"
              icon={isDarkMode ? exercises_dark : completed_icon}
            />
            <TrackerHeaderData
              count={trackerData?.summary?.assigned?.length}
              text="ASSIGNED"
              icon={isDarkMode ? assigned_dark : assignedTracker}
            />
            <TrackerHeaderData
              count={trackerData?.summary?.exerciseCompleted?.length}
              text="COMPLETED"
              icon={isDarkMode ? completed_dark : completedTracker}
            />
          </Grid>
          <div className={classes.resultContainer}>
            <div className={classes.searchFieldContainer}>
              <input
                placeholder="Search here..."
                className={classes.searchField}
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                onKeyDown={handleSearchEnter}
              />
              <img
                src={search_key}
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              />
            </div>
            {matchesSM && (
              <div className={classes.checkBoxContainer}>
                <div className={classes.searchBylabel}>Search by</div>
                {["batch", "student", "email"].map((filter) => {
                  return (
                    <React.Fragment>
                      <div className={classes.checkBox}>
                        <input
                          type="checkbox"
                          value={filter}
                          checked={
                            filter == "batch"
                              ? batchFilter
                              : filter == "student"
                              ? studentFilter
                              : emailFilter
                          }
                          onChange={() => updateStatus(filter)}
                        />
                        <span className={classes.label}>{filter}</span>
                      </div>
                    </React.Fragment>
                  );
                })}
                <div className={classes.checkBoxLast}>
                  <input
                    type="checkbox"
                    value={"assigned"}
                    checked={assignedFilter}
                    onChange={() => updateStatus("assigned")}
                  />
                  <span className={classes.label}>assigned</span>
                </div>
              </div>
            )}
            {!matchesSM && (
              <div className={classes.imgContainer}>
                <img
                  src={filter}
                  width="30"
                  height="30"
                  onClick={() => setOpenFilter(true)}
                />
              </div>
            )}
            {!matchesSM && openFilter && getOpenFilterData()}
          </div>
          {activeSearchResults && (
            <div className={classes.searchResults}>
              <span className={classes.searchResultsText}>
                {`Showing ${trackerData?.details?.length} ${
                  trackerData?.details?.length > 1 ? "results" : "result"
                }`}{" "}
                <span>
                  {assignedFilter && <span>(only Assigned exercises)</span>}
                </span>
              </span>
              <span
                className={classes.clearResults}
                onClick={handleClearResults}
              >
                Clear
                <ReplayOutlinedIcon
                  fontSize="small"
                  style={{ marginTop: "-4px" }}
                />
              </span>
            </div>
          )}
          <TableData
            type="all"
            data={trackerData?.details || []}
            header={[
              "batch",
              "student",
              "catalogue",
              "total done",
              "assigned",
              "completed",
              "view",
            ]}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </>
      )}
    </React.Fragment>
  );
}

export default ExerciseTracker;
