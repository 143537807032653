import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import timer from "../../assets/images/timer.png";
import completed_timer from "../../assets/images/completed_timer.png";
import questionnaire_timer from "../../assets/images/exercise_v2.png";

const useStyles = makeStyles((theme) => ({
  timerContainer: {
    background: "linear-gradient(99.26deg, #FF9C00 0%, #FF8101 100%), #FFFFFF",
    border: "4px solid #FED51B",
    borderRadius: "20px",
    color: "white",
    minWidth: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeTimeContainer: {
    background: "#DBF0EF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
  },
  questionnaireContainer: {
    background: "#E7EEEE",
    border: "1px solid #C0D8D8",
    borderRadius: "8px",
    color: "#2F4F4F",
    padding: "4px 8px",
  },
  timerImg: {
    marginRight: "5px",
    marginBottom: "5px",
  },
  questionnaireTimerImg: {
    height: "16px",
    width: "16px",
    marginRight: "5px",
  },
  timerTxt: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#FFFFFF",
  },
  questionnaireText: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#2F4F4F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  completedTimerText: {
    color: "#429F99",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "600",
    },
  },
  activeTimeContainerMaths: {
    background: "linear-gradient(99.26deg, #19B933 0%, #13B105 100%), #FFFFFF",
    border: "2px solid #1CD935",
    borderRadius: "20px",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  completedTimerTextMaths: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#FFFFFF",
  },
}));
const Timer = ({ startTime, endTime, type }) => {
  const [counter, setCounter] = useState(0);

  const [time, setTime] = useState(
    endTime
      ? endTime - startTime
      : startTime
      ? new Date().getTime() - startTime
      : 0
  );
  const classes = useStyles();
  useEffect(() => {
    let timer;
    if (endTime) {
      const diff = endTime - startTime;
      setTime(diff);
    } else {
      if (startTime) {
        timer = setInterval(() => {
          const now = new Date().getTime();
          const diff = now - startTime;
          setTime(diff);
          setCounter(counter + 1);
        }, 1000);
      }
    }
    return () => clearInterval(timer);
  }, [counter, startTime]);

  const hours = Math.floor(time / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((time % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, "0");
  return (
    <React.Fragment>
      <div
        className={
          endTime
            ? type == "worksheet"
              ? classes.activeTimeContainerMaths
              : classes.activeTimeContainer
            : type == "questionnaire"
            ? classes.questionnaireContainer
            : classes.timerContainer
        }
      >
        <div
          className={
            endTime
              ? type == "worksheet"
                ? classes.completedTimerTextMaths
                : classes.completedTimerText
              : type == "questionnaire"
              ? classes.questionnaireText
              : classes.timerTxt
          }
        >
          {type != "worksheet" && (
            <img
              src={
                endTime
                  ? completed_timer
                  : type === "questionnaire"
                  ? questionnaire_timer
                  : timer
              }
              className={
                type == "questionnaire"
                  ? classes.questionnaireTimerImg
                  : classes.timerImg
              }
            />
          )}
          {startTime ? (
            <React.Fragment>
              {endTime ? (
                <>
                  Completed in {parseInt(hours) ? <span>{hours}:</span> : null}
                  {minutes}:{seconds} mins
                </>
              ) : (
                <>
                  {parseInt(hours) ? <span>{hours}:</span> : null}
                  {minutes}:{seconds}
                </>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>00:00</React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Timer;
