import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TableData from "./TableData";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { API } from "../../Services/api-service";
import { useParams } from "react-router";
import Loader from "../../Core/Loader/Loader";
import back from "../../assets/images/back.svg";
import { isQuestionnaire, isWorksheet } from "../Home/exerciseHelper";

const useStyles = makeStyles((theme) => ({
  summary: {
    display: "flex",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  label: {
    textTransform: "capitalize",
    // fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: theme.palette.trackerHeadings,
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  labelData: {
    // fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.trackerHeadings,
    marginTop: "10px",
    marginLeft: "10px",
  },
}));
function ExerciseTrackerCatalog() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  const [trackerData, setTrackerData] = useState();
  const [loading, setLoading] = useState(false);
  const getReport = () => {
    setLoading(true);
    API.get(`/report/exercise/${id}`)
      .then((res) => {
        setLoading(false);
        setTrackerData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error("error while fetching exersie data", err);
      });
  };
  useEffect(() => {
    getReport();
  }, []);
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Button onClick={() => history.goBack()}>
              <img src={back} />
              Back
            </Button>
          </div>
          {matchesSM ? (
            <div className={classes.summary}>
              <div className={classes.label}>
                <div>Student Name :</div>
                <div style={{ marginTop: "5px" }}>Catalogue :</div>
              </div>
              <div className={classes.labelData}>
                <div>{trackerData?.student}</div>
                <div style={{ marginTop: "10px" }}>
                  {trackerData?.catalogue}
                </div>
              </div>
              <div className={classes.label} style={{ marginLeft: "50px" }}>
                <div>Total Exercises :</div>
                <div style={{ marginTop: "5px" }}>Completed Exercises :</div>
              </div>
              <div className={classes.labelData}>
                <div>{trackerData?.details?.length}</div>
                <div style={{ marginTop: "10px" }}>
                  {trackerData?.completed}
                </div>
              </div>
              <div className={classes.label} style={{ marginLeft: "50px" }}>
                <div>Total Assignments :</div>
                <div style={{ marginTop: "5px" }}>Completed Assignments :</div>
              </div>
              <div className={classes.labelData}>
                <div>{trackerData?.assigned}</div>
                <div style={{ marginTop: "10px" }}>
                  {trackerData?.assignedCompleted}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.summary}>
              <div className={classes.label}>
                <div>Student Name :</div>
                <div>Catalogue :</div>
                <div>Completed :</div>
                <div>Attempted :</div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div style={{ marginTop: "10px" }}>{trackerData?.student}</div>
                <div style={{ marginTop: "3px" }}>{trackerData?.catalogue}</div>
                <div style={{ marginTop: "3px" }}>{trackerData?.completed}</div>
                <div>{trackerData?.attempted}</div>
              </div>
            </div>
          )}
          <TableData
            type="exercise"
            data={trackerData?.details || []}
            header={
              isQuestionnaire(trackerData?.category) ||
              isWorksheet(trackerData?.category)
                ? [
                    "exercise",
                    "assessment",
                    "assigned",
                    "score",
                    "total",
                    "time",
                    "attempt",
                    "status",
                    "history",
                    "view",
                  ]
                : [
                    "exercise",
                    "assessment",
                    "assigned",
                    "time",
                    "attempt",
                    "status",
                    "history",
                    "view",
                  ]
            }
          />
        </>
      )}
    </React.Fragment>
  );
}

export default ExerciseTrackerCatalog;
