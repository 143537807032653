import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import mathScoreBg from "../../../assets/images/mathScoreBg.png";
import cup from "../../../assets/images/default-cup.png";
import back from "../../../assets/images/back.png";
import { getRole } from "../../../Core/utils/auth";
import Button from "@material-ui/core/Button";
import ReplaceIcon from "@mui/icons-material/LoopOutlined";
import ShowIcon from "@mui/icons-material/VisibilityOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import FormatIcon from "@mui/icons-material/Create";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import Timer from "../Timer";
import ClearIcon from "@mui/icons-material/Clear";
import { Spinner } from "react-bootstrap";
import TextBoxEditor from "./textboxEditor";
import AssessmentTimer from "../AssessmentTimer";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { API } from "../../../Services/api-service";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import { decrypt } from "../../../Core/utils/others";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  parentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundSize: "cover",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      minHeight: "600px",
    },
    background: `url(${mathScoreBg})`,
    border: "40px solid EDF8F3 ",
    borderRadius: "20px",
    textAlign: "center",
    paddingTop: "10px",
  },
  scoreBox: {
    width: "185px",
    position: "relative",
    height: "210px",
    background: "#FFAA00",
    border: "9px solid #FED51B",
    borderRadius: "35px 35px 55px 55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "5px",
    marginTop: "40px",
  },
  cupIcon: {
    position: "absolute",
    maxHeight: "80px",
    maxWidth: "80px",
    top: theme.spacing(-5.5),
    left: theme.spacing(5),
    borderRadius: "50%",
  },
  scoreText: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#FFFFFF",
    marginTop: "15px",
  },
  score: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  scoreSingle: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "54px",
    color: "#FFFFFF",
  },
  scoreInnerText: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "700",
    fontSize: "18px",
  },
  totalPages: {
    fontWeight: "600",
    fontSize: "14px !important",
    color: "#fff",
    marginTop: "10px",
  },
  totalQuestions: {
    fontWeight: "600",
    fontSize: "14px !important",
    color: "#fff",
    marginTop: "10px",
  },
  scoreTabMultiPage: {
    background: "linear-gradient(99.26deg, #FF9C00 0%, #FF8101 100%)",
    border: "4px solid #FFD210",
    borderRadius: "27px",
    width: "140px",
    height: "60px",
    paddingTop: "10px",
  },
  scoreTab: {
    background: "linear-gradient(99.26deg, #FF9C00 0%, #FF8101 100%)",
    border: "4px solid #FFD210",
    borderRadius: "27px",
    width: "140px",
    height: "60px",
  },
  resubmitBtn: {
    height: "50px",
    width: "108px",
    background: "linear-gradient(123.51deg, #F2C202 23.31%, #FFB41F 77.69%)",
    boxShadow:
      "0px 2px 8px rgba(251, 90, 21, 0.56), inset 0px -4px 0px #D79309",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    padding: "12px 18px",
  },
  btn: {
    height: "50px",
    width: "108px",
    background: "linear-gradient(123.51deg, #F27202 23.31%, #F5A300 77.69%)",
    boxShadow:
      "0px 2px 8px rgba(251, 90, 21, 0.56), inset 0px -4px 0px #CE7612",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 18px",
    marginBottom: "20px",
  },
  btnNxtPage: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
    },
  },
  btnText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.08em",

    color: "#FFFFFF",
  },
  backToexerciseBtnbox: {
    height: "25px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  backToExerciseText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    fontWeight: "700",
    letterSpacing: "0.08em",
    color: "#FFFFFF",
  },
  backIcon: {
    marginRight: "10px",
    height: "14px",
    width: "18px",
  },
  nextIcon: {
    height: "19px",
    width: "19px",
    marginLeft: "10px",
  },

  endIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
  showIcon: {
    "& > *:first-child": {
      fontSize: "20px",
    },
  },

  btnWrapper: {
    height: "60px",
    width: "100%",
  },

  actionsWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  header: {
    background: "#F9FBFB",
    padding: "10px",
  },
  headerBtn: {
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FF712D",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    marginRight: "35px",
  },
  divider: {
    backgroundColor: "#E7EFEF",
  },
  addPage: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "30px",
    marginTop: "150px",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FF712D",
    cursor: "pointer",
  },
  ButtonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  deletePage: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "30px",
    marginTop: "-90px",
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FF712D",
    cursor: "pointer",
  },
  totalScoreBox: {
    width: "185px",
    height: "46px",
    background: "linear-gradient(99.26deg, #FF9C00 0%, #FF8101 100%)",
    border: "4px solid #FFD210",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "0.04em",
    color: "#FFFFFF",
    paddingTop: "4px",
    marginTop: "10px",
  },
  totalScoreText: {
    marginTop: "7px",
  },
  marksScored: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "27px",
  },
  scoreCardDiv: {
    background: theme.palette.background.background3,
    borderRadius: "20px",
  },
}));

const YourScore = ({
  goBacktoExercise,
  exerciseId,
  submission,
  exercise,
  showAnswers,
  readOnly,
  handleSubmitAnswers,
  handleReattemptAnswers,
  handleSaveWorksheet,
  handleFormatWorksheet,
  handleDeleteWorksheet,
  handleCopyWorksheet,
  handleDragWorksheet,
  handleTypeWorksheet,
  showCancel,
  actionsDisabled,
  handleClearAnswers,
  handleReplaceAnswers,
  startTime,
  endTime,
  showTimer,
  loaderForSubmit,
  selectedBox,
  textEditor,
  showTextEditor,
  handleBoxSelection,
  previewStatus,
  view,
  previewMode,
  startAssessmentTime,
  endAssessmentTime,
  duration,
  showAssessmentTimer,
  restartTimer,
  totalPages,
  setShowAddPageBtn,
  showAddPageBtn,
  setPageData,
  setWorkSheet,
  setCurrentPage,
  fetchExercise,
  workSheetData,
  workSheetSubmission,
  currentPageId,
  handleVirtualKeyboard,
  keyboard,
  setPageNumber,
  pageNumber,
  setTab,
  fromAssignment,
  isExerciseCompleted,
  workSheetProperties,
  language,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const role = getRole();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [addPageLoader, setAddPageLoader] = useState(false);
  const [deletePageLoader, setDeletePageLoader] = useState(false);
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userID = userInfo.userId;
  const handleAns = (markAsCompleted, reAttempt) => {
    handleSubmitAnswers(markAsCompleted, reAttempt, previewMode);
  };

  const handleNextExercise = () => {
    if (role !== "admin") {
      window.open(exercise.nextExercise, "_self");
      window.location.reload();
    }
  };

  const handleShowAnswers = () => {
    showAnswers(true);
  };

  const handleReattemptAns = () => {
    handleReattemptAnswers("open");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleSave = () => {
    handleSaveWorksheet();
  };

  const handleFormat = () => {
    handleFormatWorksheet();
  };

  const handleDelete = () => {
    handleDeleteWorksheet();
  };

  const handleCopy = () => {
    handleCopyWorksheet();
  };

  const handleDrag = () => {
    handleDragWorksheet();
  };

  const handleType = () => {
    handleTypeWorksheet();
  };

  const handleClear = () => {
    handleClearAnswers();
  };

  const handleReplace = () => {
    handleReplaceAnswers();
  };

  const handlePreview = () => {
    previewStatus(true);
  };

  const isAllDescriptive = (multipage) => {
    const total = multipage ? submission?.total : workSheetSubmission?.total;
    return (
      workSheetData?.properties?.descQnsCount &&
      total - workSheetData?.properties?.descQnsCount === 0
    );
  };

  const addWorkSheetPage = () => {
    setAddPageLoader(true);
    API.post(`/exercises/${exerciseId}/page`)
      .then((res) => {
        setCurrentPage(totalPages + 1);
        setPageNumber(totalPages + 1);
        setWorkSheet("");
        setPageData(res?.data.pageId);
        fetchExercise();
        setAddPageLoader(false);
      })
      .catch((err) => {
        const event = {
          message: "Add Page error",
          level: Sentry.Severity.Info,
          extra: {
            exerciseId: exerciseId,
            userID: userID,
          },
        };
        Sentry.captureEvent(event);
        setAddPageLoader(false);
        console.log("error while fetching data", err);
      });
  };
  const deletePage = () => {
    if (currentPageId) {
      setDeletePageLoader(true);
      API.delete(`/exercises/${exerciseId}/page/${currentPageId}`)
        .then((res) => {
          toast.success(`Page Deleted Successfully`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
          setDeletePageLoader(false);
        })
        .catch((err) => {
          const event = {
            message: "Delete Page error",
            level: Sentry.Severity.Info,
            extra: {
              exerciseId: exerciseId,
              userID: userID,
            },
          };
          Sentry.captureEvent(event);
          setDeletePageLoader(false);
          console.log("error while fetching data", err);
        });
    }
  };

  return (
    <div className={classes.scoreCardDiv}>
      {role === "admin" && view !== "preview" && (
        <Grid container xs={12} className={classes.header}>
          <Grid item xs={6} className={classes.btnWrapper}>
            <Button
              endIcon={<ReplaceIcon />}
              className={classes.headerBtn}
              onClick={handleReplace}
              // disabled={imported}
              disabled={showCancel}
            >
              REPLACE
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.btnWrapper}>
            <Button
              endIcon={<SaveIcon />}
              className={classes.headerBtn}
              onClick={handleSave}
              disabled={showCancel}
            >
              SAVE
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.btnWrapper}>
            <Button
              endIcon={<FormatIcon />}
              className={classes.headerBtn}
              onClick={handleFormat}
              // disabled={imported}
              disabled={showCancel}
            >
              Format
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.btnWrapper}>
            <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                endIcon={<KeyboardArrowDownIcon />}
                className={classes.headerBtn}
                disabled={
                  actionsDisabled ||
                  (workSheetData?.properties &&
                    workSheetData?.properties?.imported)
                }
              >
                Actions
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{ zIndex: "10", minWidth: "105px" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              handleDelete();
                              setOpen(false);
                            }}
                            // disabled={imported}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCopy();
                              setOpen(false);
                            }}
                          >
                            Copy
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleDrag();
                              setOpen(false);
                            }}
                          >
                            Move
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.btnWrapper}>
            <Button
              endIcon={<VisibilityIcon />}
              className={classes.headerBtn}
              onClick={handlePreview}
              disabled={
                showCancel ||
                !workSheetData?.properties ||
                (workSheetData?.properties &&
                  !workSheetData?.properties.hasOwnProperty("answers"))
              }
            >
              Preview
            </Button>
          </Grid>
          {showCancel && (
            <Grid item xs={6} className={classes.btnWrapper}>
              <Button
                endIcon={<CancelIcon />}
                className={classes.headerBtn}
                onClick={() => handleType()}
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )}
      {role !== "admin" || view === "preview" ? (
        <div className={classes.parentBox}>
          {(role == "student" || role == "guest" || view === "preview") &&
            showTimer &&
            !duration && (
              <Timer startTime={startTime} endTime={endTime} type="worksheet" />
            )}
          {(getRole() == "student" ||
            getRole() == "guest" ||
            view === "preview") &&
            showAssessmentTimer &&
            duration && (
              <AssessmentTimer
                startTime={startAssessmentTime}
                endTime={endAssessmentTime}
                duration={duration}
                restartTimer={restartTimer}
              />
            )}
          <div className={classes.scoreBox}>
            <img src={cup} className={classes.cupIcon} alt="cup" />
            <Typography className={classes.scoreText}>YOUR SCORE</Typography>
            {totalPages > 1 ? (
              <>
                <div className={classes.scoreTabMultiPage}>
                  <span className={classes.score}>
                    <span className={classes.marksScored}>
                      {isAllDescriptive(true) ? (
                        !workSheetSubmission?.attempted ? (
                          0
                        ) : (
                          <span className={classes.scoreInnerText}>
                            In Review
                          </span>
                        )
                      ) : (
                        `${submission?.score}\\`
                      )}
                    </span>
                    {submission?.total -
                      (workSheetData?.properties?.descQnsCount || 0) || ""}
                  </span>
                </div>
                <Typography className={classes.totalPages}>
                  Page {pageNumber} of {totalPages}
                </Typography>
              </>
            ) : (
              <>
                <div className={classes.scoreTab}>
                  <Typography className={classes.scoreSingle}>
                    {isAllDescriptive(false) ? (
                      !workSheetSubmission?.attempted ? (
                        0
                      ) : (
                        <span className={classes.scoreInnerText}>
                          In Review
                        </span>
                      )
                    ) : (
                      workSheetSubmission?.score
                    )}
                  </Typography>
                </div>
                {workSheetSubmission?.total -
                  workSheetData?.properties?.descQnsCount !==
                  0 && (
                  <Typography className={classes.totalQuestions}>{`OUT OF ${
                    workSheetSubmission?.total -
                    (workSheetData?.properties?.descQnsCount || 0)
                  }`}</Typography>
                )}
              </>
            )}
          </div>
          {!matches && !submission.attempted && !readOnly && (
            <Button
              className={`${classes.btn} ${classes.btnText}`}
              onClick={() => handleAns(1, 0)}
              style={{
                cursor: "pointer",
              }}
              disabled={loaderForSubmit}
            >
              {loaderForSubmit ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "SUBMIT"
              )}
            </Button>
          )}
          {submission.attempted && !readOnly ? (
            <div className={classes.btnWrapper}>
              <div className={classes.actionsWrapper}>
                {!matches && (
                  <Button
                    className={`${classes.resubmitBtn} ${classes.btnText}`}
                    onClick={() => handleAns(1, 0)}
                    style={{
                      cursor: "pointer",
                    }}
                    classes={{ iconSizeMedium: classes.endIcon }}
                    disabled={loaderForSubmit}
                  >
                    {loaderForSubmit ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      "RESUBMIT"
                    )}
                  </Button>
                )}
                {exercise.nextExercise !== null &&
                  getRole() !== "admin" &&
                  pageNumber === totalPages &&
                  !fromAssignment && (
                    <Button
                      className={`${classes.btnNxtPage} ${classes.btn} ${classes.btnText}`}
                      onClick={handleNextExercise}
                      classes={{ iconSizeMedium: classes.endIcon }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      NEXT EXERCISE
                    </Button>
                  )}
                {submission.attemptedAll &&
                pageNumber < totalPages &&
                getRole() !== "admin" ? (
                  <Button
                    className={`${classes.btnNxtPage} ${classes.btn} ${classes.btnText}`}
                    onClick={() => {
                      setCurrentPage(workSheetData.nextPage);
                      setPageNumber((prev) => prev + 1);
                      if (matches) {
                        setTab("worksheet");
                      }
                    }}
                    classes={{ iconSizeMedium: classes.endIcon }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {"NEXT >>"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {view !== "preview" ? (
            (role === "mentor" ||
              (submission.attempted && submission.attemptedAll) ||
              isExerciseCompleted) &&
            !isAllDescriptive(totalPages > 1) ? (
              <Button
                variant="text"
                startIcon={<ShowIcon />}
                className={`${classes.btnText}`}
                onClick={handleShowAnswers}
                style={{ cursor: "pointer" }}
                classes={{ iconSizeMedium: classes.showIcon }}
              >
                SHOW ANSWERS
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          {totalPages > 1 && !isAllDescriptive(true) ? (
            <div className={classes.totalScoreBox}>
              <span className={classes.totalScoreText}>TOTAL</span>
              <span>
                <span className={classes.marksScored}>
                  {workSheetSubmission?.score}
                </span>
                /
                {workSheetSubmission?.total
                  ? workSheetSubmission?.total -
                    (workSheetProperties?.totalDescQns || 0)
                  : ""}
              </span>
            </div>
          ) : (
            ""
          )}
          <div className={classes.btnWrapper}>
            {!matches && (
              <div className={classes.actionsWrapper}>
                {(workSheetSubmission?.attemptedAll || isExerciseCompleted) &&
                getRole() !== "admin" &&
                !readOnly ? (
                  <Button
                    variant="text"
                    startIcon={<ReplayIcon />}
                    className={`${classes.btnText}`}
                    onClick={handleReattemptAns}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                    classes={{ iconSizeMedium: classes.showIcon }}
                  >
                    REATTEMPT
                  </Button>
                ) : (
                  ""
                )}
                {/* {!workSheetSubmission?.attemptedAll && !isExerciseCompleted ? (
                  <Button
                    variant="text"
                    startIcon={<ClearIcon />}
                    className={`${classes.btnText}`}
                    onClick={handleClear}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                    classes={{ iconSizeMedium: classes.showIcon }}
                  >
                    Clear
                  </Button>
                ) : (
                  ""
                )} */}
              </div>
            )}
          </div>
          <div
            onClick={goBacktoExercise}
            className={classes.backToexerciseBtnbox}
          >
            <img src={back} alt="prev" className={classes.backIcon} />
            <span className={classes.backToExerciseText}>
              BACK TO EXERCISES
            </span>
          </div>
        </div>
      ) : showAddPageBtn && totalPages > 0 ? (
        <Grid container xs={12} style={{ minHeight: "450px" }}>
          <Grid item xs={12} className={classes.ButtonWrapper}>
            <Button
              onClick={addWorkSheetPage}
              disabled={addPageLoader}
              className={classes.addPage}
            >
              {addPageLoader ? (
                <Spinner animation="border" variant="orange" />
              ) : (
                "Add Page +"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.ButtonWrapper}>
            <Button
              onClick={deletePage}
              diabled={deletePageLoader}
              className={classes.deletePage}
              style={{ minWidth: "150px" }}
            >
              {deletePageLoader ? (
                <Spinner animation="border" variant="orange" />
              ) : (
                <>
                  Delete Page{" "}
                  <DeleteOutlineOutlinedIcon style={{ marginTop: "-4px" }} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div style={{ minHeight: "450px" }}>
          <TextBoxEditor
            selectedBox={selectedBox}
            textEditor={textEditor}
            showTextEditor={showTextEditor}
            handleBoxSelection={handleBoxSelection}
            setShowAddPageBtn={setShowAddPageBtn}
            handleVirtualKeyboard={handleVirtualKeyboard}
            keyboard={keyboard}
            exercise={exercise}
            language={language}
          />
        </div>
      )}
    </div>
  );
};

export default YourScore;
