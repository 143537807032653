import React from "react";
import { useStyles } from "./style";
import { Button, Grid, Box } from "@material-ui/core";
import clock from "../../../assets/images/sectionClock.png";
import ScoreBox from "../../../assets/images/score_shape.png";
import scoreLeaf from "../../../assets/images/score_leaf.png";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Spinner } from "react-bootstrap";

const SectionSuccessPage = ({
  sectionLevelData,
  handleNextExercise,
  exercise,
  handleReattempt,
  handleViewResults,
  completedTime,
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={`${classes.flex_col} ${classes.alignItemsCenter} ${classes.fullHeight} ${classes.justifyCenter}`}
    >
      <Box className={classes.scoreContainer}>
        <img src={scoreLeaf} alt="score-leaf" className={classes.scoreLeaf} />
        {sectionLevelData?.percentage !== undefined &&
        sectionLevelData?.scored !== undefined &&
        sectionLevelData?.score !== undefined ? (
          <React.Fragment>
            <Box className={classes.percentageText}>
              {`${sectionLevelData?.percentage}%`}
            </Box>
            <Box className={classes.customScoreBox}>
              <img
                src={ScoreBox}
                alt="score box"
                style={{
                  width: "100%",
                  height: "78px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "24px",
                  fontWeight: "700",
                  textAlign: "center",
                  width: "100%",
                }}
                className={classes.scoreText}
              >
                {`${sectionLevelData?.scored} / ${sectionLevelData?.score}`}
              </div>
            </Box>
          </React.Fragment>
        ) : (
          <Spinner
            animation="border"
            variant="danger"
            style={{ fontSize: "16px" }}
          />
        )}
        <Box className={classes.timerContainer}>
          <img src={clock} alt="fire-clock" className={classes.timerClock} />
          <Box className={classes.completedTime}>
            {completedTime && completedTime}
          </Box>
        </Box>
      </Box>

      <Grid
        className={`${classes.flex_row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.successBtnBox}`}
      >
        <Button
          className={`${classes.flex_row} ${classes.font700} ${classes.alignItemsCenter} ${classes.successViewBtn}`}
          onClick={handleReattempt}
        >
          Reattempt <ReplayOutlinedIcon fontSize="small" className="ml-2" />
        </Button>
        <Button
          className={`${classes.flex_row} ${classes.font700} ${classes.alignItemsCenter} ${classes.successViewBtn} ${classes.viewResultsBtn}`}
          onClick={handleViewResults}
        >
          View Results{" "}
          <VisibilityOutlinedIcon fontSize="small" className="ml-2" />
        </Button>
      </Grid>
      {exercise?.nextExercise && (
        <Grid>
          <Button
            className={`${classes.flex_row} ${classes.alignItemsCenter} ${classes.font700} ${classes.font18} ${classes.nextExercise}`}
            onClick={handleNextExercise}
          >
            Next Exercise{" "}
            <ArrowForwardIosOutlinedIcon fontSize="small" className="ml-1" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SectionSuccessPage;
