import React from "react";
import { useHistory } from "react-router-dom";
import { auth, authenticate } from "./utils/auth";

const Authentication = (props) => {
  const history = useHistory();
  let component;
  const { children } = props;

  const checkAuth = authenticate();

  if (!auth()) {
    history.replace("/");
  } else {
    component = children;
  }

  return <>{component}</>;
};

export default Authentication;
