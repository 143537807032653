/**
 *
 * @param {object to be encrypted} o
 * @param {string} salt
 */
export function encrypt(o, salt = "Salt Strirng") {
  o = JSON.stringify(o).split("");
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === "{") o[i] = "}";
    else if (o[i] === "}") o[i] = "{";
  return encodeURI(salt + o.join(""));
}

export function decrypt(o, salt = "Salt Strirng") {
  o = decodeURI(o);
  if (salt && o.indexOf(salt) !== 0) return false;
  o = o.substring(salt.length).split("");
  for (var i = 0, l = o.length; i < l; i++)
    if (o[i] === "{") o[i] = "}";
    else if (o[i] === "}") o[i] = "{";
  return JSON.parse(o.join(""));
}

export function isEmailAddress(str) {
  if (str) {
    return true;
  } else {
    return false;
  }
}
