import React, { useState, useEffect } from "react";
import clsx from "clsx";
import swal from "sweetalert";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import CodeIcon from "@material-ui/icons/Code";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import TerminalIcon from "@mui/icons-material/Terminal";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import sandboxLogoNav from "../../assets/images/sandboxLogoNav.svg";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SandboxText from "../../assets/images/SandboxText.svg";
import SandboxTextWhite from "../../assets/images/SandboxTextWhite.svg";
import admin from "../../assets/images/admin.png";
import mentor from "../../assets/images/mentor.png";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UpgradeModal from "./../../SharedComponents/UpgradeModal/UpgradeModal";
import { getRole } from "../../Core/utils/auth";
import TagManager from "react-gtm-module";
import defaultUser from "../../assets/images/defaultUser.png";
import "./SidebarComponent.scss";
import { decrypt } from "../../Core/utils/others";
import { reducer_clearBreadCrumbs } from "../../Redux/Actions/folderPath.action";
import Cookies from "js-cookie";
import IconButton from "@material-ui/core/IconButton";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import dark_mode from "../../assets/images/dark_mode_icon.svg";
import light_mode from "../../assets/images/light_mode_icon.svg";
import light_mode_outline from "../../assets/images/light_mode_outlined.svg";
import NewUpdate from "../Home/NewUpdateModal";
import { API } from "../../Services/api-service";

import { ToggleBar } from "./ToggleBar";

const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: theme.palette.background.background2,
    border: `1px solid ${theme.palette.border2}`,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarwidth: "thin",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    width: "56px",
    [theme.breakpoints.up("sm")]: {
      width: "73px",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  userIconWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "70px",
  },
  profileContainer: {
    borderTop: `1px solid ${theme.palette.profileBorder}`,
    background: theme.palette.proofileBackground,
  },
  userContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "12px 20px",
  },
  userWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  userPic: {
    height: "36px",
    width: "36px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  userName: {
    color: theme.palette.body,
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: "10px",
  },
  userRole: {
    width: "100%",
    padding: "6px 0px",
    borderRadius: "4px",
    background: theme.palette.background.roleBackground,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.roleColor,
    marginBottom: "20px",
    fontSize: "14px",
  },
  logoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
  },
  logoutText: {
    fontSize: "14px",
    color: "#E35D5D",
    fontWeight: "700",
    marginRight: "5px",
  },
  logoutIcon: {
    padding: "0px",
    color: "#E35D5D",
  },
  listItemWrapper: {
    display: "flex",
    alignItems: "center",
  },
  sidebarImg: {
    width: "20px",
    height: "20px",
  },
}));

export default function SidebarComponent({ handleToggle }) {
  const theme = useTheme();
  const path = useSelector((state) => state.folderPathReducer.folderPath);
  const isBigScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const role = getRole();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [upgradeModal, setUpgradeModal] = React.useState(false);
  const [newUpdate, setNewUpdate] = React.useState(false);
  const isDarkMode = useSelector(selectDarkMode);
  const [userData, setUserData] = useState({
    userName: "",
    profileImage: "",
    initial: "",
  });
  const profilePicStorage = localStorage.getItem("profile_pic");

  const ASSETS_URL = `${process.env.REACT_APP_SANDBOX_ASSETS_URL}`;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (localStorage.getItem("sandbox_user")) {
      let local = decrypt(localStorage.getItem("sandbox_user"));
      const profilePicUrl = profilePicStorage
        ? decrypt(profilePicStorage)
        : null;

      const obj = {
        userName: local.userName ? local.userName : local.email,
        profileImage: profilePicUrl || local.profilePicUrl,
        initial: local.userName
          ? local.userName.charAt(0)
          : local.email.charAt(0).toUpperCase(),
      };
      setUserData(obj);
    }
    // getUpdates();
  }, [profilePicStorage]);

  const getUpdates = async () => {
    try {
      const response = await API.get("/newUpdates?updateName=darkMode");
      if (response.data && !response.data?.isViewed) setNewUpdate(true);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    swal({
      title: "Logout",
      text: "Do you really want to logout?",
      buttons: ["No", "Yes, Logout"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((logout) => {
      if (logout) {
        handleToggle();
        Cookies.remove("AuthorizationToken", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkCatalogId", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkViewAll", { domain: ".codeyoung.com" });
        Cookies.remove("homeWorkExerciseId", { domain: ".codeyoung.com" });
        Cookies.remove("userName", { domain: ".codeyoung.com" });
        Cookies.remove("userId", { domain: ".codeyoung.com" });
        Cookies.remove("grade", { domain: ".codeyoung.com" });
        Cookies.remove("tracker", { domain: ".codeyoung.com" });
        localStorage.removeItem("sandbox_user");
        if (localStorage.getItem("darkMode")) {
          localStorage.removeItem("darkMode");
        }
        localStorage.removeItem("sud");
        window.location.replace("/");
      }
    });
  };

  const navigateTo = (to) => {
    if (path.length > 0) {
      dispatch(reducer_clearBreadCrumbs());
    }
    if (to !== "/home") {
      const action =
        to === "/workbench"
          ? "workbench_clicked"
          : to === "/files"
          ? "files_clicked"
          : "learning_clicked";
      const tagManagerArgs = {
        gtmId: "GTM-TH4RWGG",
        dataLayer: {
          event: "codeyoung_sandbox",
          event_category: "codeyoung_sandbox",
          event_action: action,
          event_label: role,
          event_label1: sandboxUser.email,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    history.push(to);
  };

  const toggleUpgradeModal = () => setUpgradeModal((prev) => !prev);

  const handleTryNow = () => {
    handleDrawerOpen();
    API.post("/newUpdates", {
      updateName: "darkMode",
    })
      .then((res) => {
        setNewUpdate(false);
      })
      .catch((e) => {
        console.error(e);
        setNewUpdate(false);
      });
  };

  return (
    <>
      {upgradeModal && (
        <UpgradeModal open={upgradeModal} handleClose={toggleUpgradeModal} />
      )}
      {newUpdate && isBigScreen && (
        <NewUpdate open={newUpdate} handleClose={handleTryNow} />
      )}

      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <List>
            <ListItem style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <img
                  src={sandboxLogoNav}
                  alt="Sand Box"
                  height="60px"
                  width="60px"
                />
              </ListItemIcon>
              <ListItemIcon style={{ marginLeft: "9px" }}>
                <img
                  src={isDarkMode ? SandboxTextWhite : SandboxText}
                  alt="Sand Box"
                  width="70%"
                />
              </ListItemIcon>
            </ListItem>
          </List>
          <Divider />
          <button
            style={{
              color: isDarkMode ? "#333" : "red",
              background: theme.palette.background.background2,
              border: "none",
            }}
            onClick={handleToggle}
          ></button>

          {open ? (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {
                <span
                  style={{
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    color: isDarkMode ? "#847871" : "#569090",
                  }}
                >
                  {isDarkMode ? "Dark Mode" : "Light Mode"}
                </span>
              }

              <button
                onClick={handleToggle}
                style={{
                  border: "none",
                  background: "transparent",
                }}
              >
                {" "}
                <ToggleBar theme={theme} />
              </button>
            </div>
          ) : (
            <button
              style={{
                color: isDarkMode ? "#333" : "white",
                background: theme.palette.background.background2,
                border: "none",
                marginTop: "20px",
              }}
              onClick={handleToggle}
            >
              {isDarkMode ? (
                <>
                  <img src={dark_mode} />
                </>
              ) : (
                <>
                  <img src={light_mode} />
                </>
              )}
            </button>
          )}
          <Divider style={{ marginTop: "20px" }} />
          <div style={{ marginTop: "28px", flexGrow: 1 }}>
            <List>
              {[
                {
                  text: "Home",
                  to: "/home",
                  icon: location.pathname.includes("/home")
                    ? `${ASSETS_URL}home_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}home_passive_dark.svg`
                    : `${ASSETS_URL}home_passive.svg`,
                },
                {
                  text: "Workbench",
                  to: "/workbench",
                  icon: location.pathname.includes("/workbench")
                    ? `${ASSETS_URL}workbench_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}workbench_passive_dark.svg`
                    : `${ASSETS_URL}workbench_passive.svg`,
                },
                {
                  text: "Files",
                  to: "/files",
                  icon: location.pathname.includes("/files")
                    ? `${ASSETS_URL}files_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}files_passive_dark.svg`
                    : `${ASSETS_URL}files_passive.svg`,
                },
                {
                  text: "Resources",
                  to: "/learningContent",
                  icon: location.pathname.includes("/learningContent")
                    ? `${ASSETS_URL}resource_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}resource_passive_dark.svg`
                    : `${ASSETS_URL}resource_passive.svg`,
                },
                {
                  text: "Exercises",
                  to: "/exercises",
                  icon: location.pathname.includes("/exercises")
                    ? `${ASSETS_URL}exercise_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}exercise_passive_dark.svg`
                    : `${ASSETS_URL}exercise_passive.svg`,
                },
                {
                  text: "Tracker",
                  to: "/tracker",
                  icon: location.pathname.includes("/tracker")
                    ? `${ASSETS_URL}tracker_active.svg`
                    : isDarkMode
                    ? `${ASSETS_URL}tracker_passive_dark.svg`
                    : `${ASSETS_URL}tracker_passive.svg`,
                },
              ].map((route, index) => {
                if (role == "guest" && route.text == "Learning Content") {
                  return;
                }
                if (role != "mentor" && route.text == "Tracker") return;
                return (
                  <ListItem
                    button
                    key={route.text}
                    onClick={() => navigateTo(route.to)}
                    style={{
                      color: location.pathname.includes(route.to)
                        ? theme.palette.highlightColor1
                        : isDarkMode
                        ? theme.palette.caption1
                        : theme.palette.caption,
                      background: location.pathname.includes(route.to)
                        ? theme.palette.background.background2
                        : isDarkMode
                        ? theme.palette.background.background2
                        : theme.palette.background.background0,
                      marginBottom: !open && "16px",
                      marginLeft: "8px",
                    }}
                  >
                    <img
                      src={route.icon}
                      alt={route.icon}
                      className={classes.sidebarImg}
                    />
                    {open && (
                      <ListItemText
                        style={{ fontSize: "14px", marginLeft: "15px" }}
                        primary={route.text}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div>
            <div>
              {!open ? (
                <div className={classes.userIconWrapper}>
                  <img
                    src={
                      userData.profileImage
                        ? userData.profileImage
                        : role === "admin"
                        ? admin
                        : role === "student"
                        ? defaultUser
                        : role === "mentor"
                        ? mentor
                        : defaultUser
                    }
                    alt="user"
                    className={classes.userPic}
                  />
                </div>
              ) : (
                <div className={classes.profileContainer}>
                  <div className={classes.userContainer}>
                    <div className={classes.userWrapper}>
                      <img
                        src={
                          userData.profileImage
                            ? userData.profileImage
                            : role === "admin"
                            ? admin
                            : role === "student"
                            ? defaultUser
                            : role === "mentor"
                            ? mentor
                            : defaultUser
                        }
                        alt="user"
                        className={classes.userPic}
                      />
                      <div className={classes.userName}>
                        {" "}
                        {userData.userName?.trim(" ").split(" ")[0]}
                      </div>
                    </div>
                    <div className={classes.userRole}>
                      {role === "admin"
                        ? "Admin"
                        : role === "student"
                        ? "Student"
                        : role === "mentor"
                        ? "Mentor"
                        : "Guest user"}
                    </div>
                    <div className={classes.logoutContainer} onClick={logout}>
                      <span className={classes.logoutText}>Logout</span>
                      <IconButton className={classes.logoutIcon}>
                        <PowerSettingsNewIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}
