import React from "react";
import { Button, Divider, Grid, Dialog } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import beatClock from "../../assets/images/beat_the_clock.png";
import Star from "../../assets/images/concepts--icon..png";
import Close from "../../assets/images/close-btn.png";
import { API } from "../../Services/api-service";
import startTimeFunction from "./startTime";
import { getRole } from "../../Core/utils/auth";
import CompletedImg from "../../assets/images/completed_card.svg";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";
import concepts_dark from "../../assets/images/concepts_dark.svg";
import ExerciseRatings from "./ExerciseRatings";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.background.background2,
    [theme.breakpoints.up("md")]: {
      padding: "24px 80px",
      fontFamily: "'Lato', sans-serif",
      // width: "874px",
      height: "max-content",
    },
    [theme.breakpoints.down("md")]: {
      padding: "44px 12px",
      fontFamily: "'Lato', sans-serif",
      paddingBottom: "24px",
      // width: "343px",
      height: "max-content",
    },
    background: theme.palette.background.background3,
  },
  container: {
    background: theme.palette.background.background3,
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  close: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: "10px",
      right: "2px",
    },
    [theme.breakpoints.down("md")]: {
      top: "5px",
      right: "0px",
    },
  },
  content: {
    paddingRight: "12px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "12px",
    },
  },
  heading: {
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.up("md")]: {
      fontWeight: "800",
      fontSize: "24px",
      lineHeight: "29px",
    },
    marginBottom: "12px",
    color: theme.palette.heading,
  },
  description: {
    [theme.breakpoints.up("md")]: {
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      lineHeight: "14px",
      marginBottom: "64px",
    },
    color: theme.palette.body,
    fontWeight: "400",
  },
  concepts: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.heading,
    marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "35px",
    },
  },
  image: {
    width: "55px",
    height: "55px",
  },
  levelBox: {
    display: "flex",
    marginTop: "15px",
  },
  level: {
    background: "#CCDAFF",
    display: "block",
    marginLeft: "2px",
    [theme.breakpoints.up("md")]: {
      width: "25px",
      height: "8px",
    },
    [theme.breakpoints.down("md")]: {
      width: "20px",
      height: "8px",
    },
  },
  easy: {
    background: "#4AB3AC",
  },
  medium: {
    background: "#FAB44B",
  },
  hard: {
    background: "#FA6F4B",
  },
  contentInnerImgBox: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
  },
  contentBoxImg: {
    [theme.breakpoints.up("md")]: {
      width: "80px",
      height: "80px",
    },
    [theme.breakpoints.down("md")]: {
      width: "63px",
      height: "63px",
    },
  },
  levelText: {
    fontWeight: "400",
    fontSize: "9px",
    lineHeight: "12px",
    color: theme.palette.level,
    marginTop: "4px",
  },
  courseInclude: {
    margin: "6px 5px 0px 0px",
    borderRadius: "4px",
    background: "#E2EFFD",
    color: "#508CCE",
    fontWeight: "500",
    padding: "8px",
    maxHeight: "38px",
  },
  conceptText: {
    marginLeft: "6px",
  },
  start: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginBottom: "40px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
      marginTop: "10px",
    },
    background: theme.palette.background.background3,
  },
  startBtn: {
    width: "129px",
    height: "39px",
    background: theme.palette.background.startBtnBg,
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      marginTop: "1vh",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "3vh",
    },
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  courseIncludeBox: {
    display: "flex",
    flexWrap: "wrap",
    height: "max-content",
  },

  dialogWrapper: {
    minWidth: "300px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "800px",
    },
  },
  timeBox: {
    width: "max-content",
    height: "70px",
    padding: "10px 18px",
    background: "#F9F9FB",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  clockText: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#707070",
    marginLeft: "14px",
  },
  timer: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#707070",
    marginLeft: "10px",
    marginTop: "6px",
  },
  timerConatiner: {
    marginTop: "10px",
    width: "max-content",
    display: "flex",
    justifyContent: "center",
  },
  completedWrapper: {
    padding: "8px 12px",
    background: "#DBF0EF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
  },
  completedImg: {
    width: "15px",
    height: "15px",
    marginRight: "8px",
  },
  completedText: {
    color: "#49AB9E",
    fontSize: "16px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  darkText: {
    color: "#49AB9E",
  },
  darkWrapper: {
    backgroundColor: "#364543",
  },
}));

const ExerciseOverview = ({
  exersice,
  open,
  handleClose,
  setExerciseOverview,
  showBrief,
  exerciseId,
  catalogId,
  startTime,
  setStartTime,
  mathWorksheet,
  showTimer,
  setAttemptId,
  setStartAssessmentTime,
  startAssessmentTime,
  showAssessmentTimer,
  timerDuration,
  setShowAssessmentTimer,
  isAssessment,
  endAssessmentTime,
  pageId,
  mathsPageData,
  setPageAttemptId,
  isExerciseCompleted,
  sectionId,
  exerciseRating,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.up("md"));
  const easy = exersice.difficulty === "Easy" ? classes.easy : null;
  const medium = exersice.difficulty === "Medium" ? classes.medium : null;
  const hard = exersice.difficulty === "Hard" ? classes.hard : null;
  const timeDuration = timerDuration + ":00";
  const [hours, minutes, seconds] = timeDuration.split(":");

  const handleStart = () => {
    if (timerDuration) {
      setShowAssessmentTimer(getRole() == "student" || getRole() == "guest");
    }
    if (!startTime && showTimer && !timerDuration) {
      const now = new Date().getTime();
      setStartTime(now);
      startTimeFunction({
        startTime: now,
        exerciseId,
        mathWorksheet,
        reAttempt: 0,
        exercise: exersice,
        catalogId,
        setAttemptId,
        pageId,
        mathsPageData,
        setPageAttemptId,
        sectionId,
      });
    } else if (!startAssessmentTime && timerDuration) {
      const now = new Date().getTime();
      setStartAssessmentTime(now);
      startTimeFunction({
        startTime: now,
        exerciseId,
        mathWorksheet,
        reAttempt: 0,
        exercise: exersice,
        catalogId,
        setAttemptId,
        pageId,
        mathsPageData,
        setPageAttemptId,
        sectionId,
      });
    }
    setExerciseOverview((p) => !p);
  };
  const isDarkMode = useSelector(selectDarkMode);
  return (
    <>
      {
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={handleStart}
          classes={{ paper: classes.dialogWrapper }}
        >
          <Grid container>
            <Grid item style={{ flexGrow: "1" }}>
              <div className={classes.main}>
                <Button
                  variant="text"
                  className={classes.close}
                  onClick={handleStart}
                >
                  <img width="15px" src={Close} alt="close" />
                </Button>
                <div className={classes.container}>
                  <div className={classes.contentInnerImgBox}>
                    <div>
                      <img
                        src={exersice.exerciseImg}
                        alt="Rocket"
                        className={classes.contentBoxImg}
                      />
                      <div className={classes.levelBox}>
                        <span
                          className={`${classes.level} ${easy} ${medium} ${hard}`}
                        ></span>
                        <span
                          className={`${classes.level} ${medium} ${hard}`}
                        ></span>
                        <span className={`${classes.level} ${hard}`}></span>
                      </div>
                      <p className={classes.levelText}>
                        Level : {exersice.difficulty}
                      </p>
                    </div>
                  </div>
                  <div className={classes.content}>
                    <div className={classes.heading}>
                      {exersice.exerciseTitle}
                    </div>
                    <p className={classes.description}>
                      {exersice.description}
                    </p>
                    {matchesSM ? (
                      <>
                        <Divider />
                        <div className={classes.concepts}>Concepts Covered</div>
                        <div className={classes.courseIncludeBox}>
                          {exersice?.concepts &&
                            exersice?.concepts.split(";").map((c) => {
                              return (
                                <span className={classes.courseInclude}>
                                  <img
                                    src={isDarkMode ? concepts_dark : Star}
                                    alt="Star"
                                  />
                                  <span className={classes.conceptText}>
                                    {c}
                                  </span>
                                </span>
                              );
                            })}
                        </div>
                        {timerDuration &&
                          !endAssessmentTime &&
                          (getRole() == "student" || getRole() == "guest") && (
                            <div className={classes.timerConatiner}>
                              <div className={classes.timeBox}>
                                <Divider />
                                <div>
                                  <img src={beatClock} alt="clock" />
                                </div>
                                <div>
                                  <div className={classes.clockText}>
                                    Beat the clock
                                  </div>
                                  <div className={classes.timer}>
                                    {parseInt(hours) ? (
                                      <span>{hours}:</span>
                                    ) : null}
                                    {minutes}:{seconds}{" "}
                                    {parseInt(hours) ? "hr" : "mins"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    ) : null}
                  </div>
                </div>
                {!matchesSM ? (
                  <>
                    <Divider />
                    <div className={classes.concepts}>Concepts Covered</div>
                    <div className={classes.courseIncludeBox}>
                      {exersice?.concepts &&
                        exersice?.concepts.split(";").map((c) => {
                          return (
                            <span className={classes.courseInclude}>
                              <img
                                src={isDarkMode ? concepts_dark : Star}
                                alt="Star"
                              />
                              <span className={classes.conceptText}>{c}</span>
                            </span>
                          );
                        })}
                    </div>
                    {timerDuration &&
                      !endAssessmentTime &&
                      (getRole() == "student" || getRole() == "guest") && (
                        <div className={classes.timerConatiner}>
                          <div className={classes.timeBox}>
                            <Divider />
                            <div>
                              <img src={beatClock} alt="clock" />
                            </div>
                            <div>
                              <div className={classes.clockText}>
                                Beat the clock
                              </div>
                              <div className={classes.timer}>
                                {parseInt(hours) ? <span>{hours}:</span> : null}
                                {minutes}:{seconds}{" "}
                                {parseInt(hours) ? "hr" : "mins"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ) : null}
                <div>
                  {!showBrief && (
                    <div className={classes.start}>
                      {isExerciseCompleted ? (
                        <div
                          className={`${classes.completedWrapper} ${
                            isDarkMode && classes.darkWrapper
                          }`}
                        >
                          <img
                            src={CompletedImg}
                            alt="completed_img"
                            className={classes.completedImg}
                          />
                          <span
                            className={`${classes.completedText} ${
                              isDarkMode && classes.darkText
                            }`}
                          >{`Completed on ${exersice?.completedAtTime}`}</span>
                        </div>
                      ) : (
                        <Button
                          variant="outlined"
                          className={classes.startBtn}
                          onClick={handleStart}
                        >
                          Start
                        </Button>
                      )}
                    </div>
                  )}
                  {isExerciseCompleted ? (
                    <ExerciseRatings
                      exerciseId={exerciseId}
                      exerciseRating={exerciseRating}
                    />
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      }
    </>
  );
};

export default ExerciseOverview;
