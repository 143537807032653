import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IFrame from "./IFrame";
import YourScore from "./YourScore";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  layout: {
    padding: "24px 44px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    marginBottom: "20px",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "row",
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "3%",
    color: "#939393",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #939393",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  submitBtn: {
    padding: "8px 30px",
    color: "white",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 44px 20px 44px",
  },

  closeIconWrapper: {
    display: "flex",
    justifyContent: "end",
    marginRight: "20px",
  },

  closeIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
  scoreLayout: {
    [theme.breakpoints.up("md")]: {
      maxHeight: "600px",
      position: "sticky",
      top: "0",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      marginTop: "30px",
    },
  },
}));

const Preview = ({
  exercise,
  open,
  closeModalHandler,
  exerciseId,
  properties,
  tab,
  submission,
  goBacktoExercise,
  showAnswers,
  handleSubmitAnswers,
  handleReattemptAnswers,
  handleClearAnswers,
  startTime,
  endTime,
  showTimer,
  loaderForSubmit,
  selectedBox,
  textEditor,
  showTextEditor,
  handleBoxSelection,
  previewStatus,
  view,
  previewMode,
  workSheetSubmission,
  totalPages,
  setCurrentPage,
  pageNumber,
}) => {
  const classes = useStyles();
  const [html, setHtml] = useState("");
  useEffect(() => {
    if (exercise) {
      getHtml(exercise?.src);
    }
  }, [exercise]);
  useEffect(() => {}, [exercise?.properties?.answers]);

  const getHtml = (url) => {
    // let strippedUrl = url.split("?")[0];
    axios
      .get(url)
      .then((response) => {
        if (response.data !== "undefined") {
          setHtml(response.data);
          // props.setParentHtml(response.data);
          // setForceClearAns(false);
        }
        // else {
        //   logSentry(
        //     "Maths Exercise File Corruption Error",
        //     url,
        //     "file is corrupted"
        //   );
        //   if (strippedUrl !== props.exercise.originalState) {
        //     axios
        //       .get(props.exercise.originalState)
        //       .then((response) => {
        //         setHtml(response.data);
        //         props.setParentHtml(response.data);
        //         setForceClearAns(true);
        //       })
        //       .catch((e) => {
        //         logSentry(
        //           "Maths Exercise Loading Error",
        //           url,
        //           "s3 api success but content is undefined"
        //         );
        //       });
        //   }
        // }
      })
      .catch((e) => {
        // logSentry(
        //   "Maths Exercise File Corruption Error",
        //   url,
        //   "file is corrupted"
        // );
        // if (
        //   e.response.status !== 200 &&
        //   strippedUrl !== props.exercise.originalState
        // ) {
        //   axios
        //     .get(props.exercise.originalState)
        //     .then((response) => {
        //       setHtml(response.data);
        //       props.setParentHtml(response.data);
        //       setForceClearAns(true);
        //     })
        //     .catch((e) => {
        //       logSentry(
        //         e,
        //         "Maths Exercise Loading Error",
        //         url,
        //         "s3 api failure"
        //       );
        //     });
        // }
      });
  };
  return (
    <Dialog fullScreen open={open} className={classes.layout}>
      <DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModalHandler}
          aria-label="close"
          className={classes.closeIconWrapper}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={9}>
            {html && (html != undefined || html != null || html != "") && (
              <IFrame
                html={html}
                clearAnswers={true}
                answers={false}
                styles={exercise?.properties && exercise?.properties?.style}
                exerciseId={exerciseId}
                properties={properties}
                tab={tab}
                submission={submission}
                previewMode={previewMode}
                role={"user"}
                exercise={exercise}
              />
            )}
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.scoreLayout}
            style={{
              height: "calc(100vh - 300px)",
            }}
            id="scoreLayout"
          >
            <YourScore
              goBacktoExercise={goBacktoExercise}
              exerciseId={exerciseId}
              submission={submission}
              exercise={exercise}
              showAnswers={showAnswers}
              // readOnly={submissionId}
              handleClearAnswers={handleClearAnswers}
              handleSubmitAnswers={handleSubmitAnswers}
              handleReattemptAnswers={handleReattemptAnswers}
              startTime={startTime}
              endTime={endTime}
              showTimer={showTimer}
              loaderForSubmit={loaderForSubmit}
              selectedBox={selectedBox}
              textEditor={textEditor}
              showTextEditor={showTextEditor}
              handleBoxSelection={handleBoxSelection}
              previewStatus={previewStatus}
              view={view}
              previewMode={previewMode}
              workSheetSubmission={workSheetSubmission}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              pageNumber={pageNumber}
              workSheetProperties={properties}
              language={exercise?.language}
              workSheetData={exercise}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <div>
        <DialogActions className={classes.actions}>
          <Button onClick={closeModalHandler} className={classes.cancelBtn}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Preview;
