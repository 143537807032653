import React, { useState } from "react";
import { Pagination } from "react-bootstrap";

const CustomPagination = ({
  totalRows,
  rowsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const [activePage, setActivePage] = useState(currentPage);

  const handlePageChange = (page) => {
    setActivePage(page);
    onPageChange(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
  
    if (totalPages <= 5) {
      // Render all pages if total pages is less than or equal to 5
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === activePage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Calculate the range of pages to display
      let startPage, endPage;
      if (activePage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (activePage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = activePage - 2;
        endPage = activePage + 2;
      }
  
      // Render pages with previous and next buttons
      paginationItems.push(
        <Pagination.Prev
          key="prev"
          disabled={activePage === 1}
          onClick={() => handlePageChange(activePage - 1)}
        />
      );
  
      // Render the range of pages
      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === activePage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
  
      paginationItems.push(
        <Pagination.Next
          key="next"
          disabled={activePage === totalPages}
          onClick={() => handlePageChange(activePage + 1)}
        />
      );
    }
  
    return paginationItems;
  };
  
  

  return (
    <Pagination className="justify-content-center">
      {renderPaginationItems()}
    </Pagination>
  );
};

export default CustomPagination;
