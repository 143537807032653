import React, { useEffect, useState, useRef } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { toast } from "react-toastify";

import { API } from "../../../Services/api-service";
import { decrypt } from "../../../Core/utils/others";
import ExerciseOverview from "../ExerciseOverview";
import Congrats from "../Congrats";
import { useParams, useLocation, useHistory } from "react-router";
import BreadcrumbsSimple from "../BreadCrumbs";
import Loader from "../../../Core/Loader/Loader";
import WorkSheet from "./WorkSheet";
import YourScore from "./YourScore";
import ShowAnswers from "./showAnswers";
import { getRole } from "../../../Core/utils/auth";
import ReplayIcon from "@mui/icons-material/Replay";
import Timer from "../Timer";
import startTimeFunction from "../startTime";
import ClearIcon from "@mui/icons-material/Clear";
import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import Preview from "./preview";
import AssessmentTimer from "../AssessmentTimer";
import CustomPagination from "../../../SharedComponents/Pagination";
import ReattamptModal from "./ReattamptModal";
import { Buffer } from "buffer";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetAppOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportIssue from "./ReportPopup";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { CompareHtml } from "./MathsTrackingHelper";
import saveGTMEvents from "../../../SharedComponents/gtm";
import axios from "axios";
import ExerciseRatings from "../ExerciseRatings";
import { useDeviceInfo } from "./deviceInfo";

const useStyles = makeStyles((theme) => ({
  layout: {
    [theme.breakpoints.up("md")]: {
      // marginTop: 30,
      // height: "100%",
      // overflow: "auto",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
  },
  worksheetLayout: {
    textAlign: "center",
    // overflow: "auto",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      marginBottom: "80px",
    },
  },
  stickyPosition: {
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      top: 40,
      zIndex: 1000,
      background: "#fff",
    },
  },

  scoreLayout: {
    [theme.breakpoints.up("md")]: {
      maxHeight: "600px",
      position: "sticky",
      top: "50px",
      zIndex: 1000,
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      marginTop: "30px",
    },
  },

  executionArea: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      background: "#fff",
    },
  },
  inputButton: {
    [theme.breakpoints.down("lg")]: {
      width: "50%",
      height: "44px",
      borderRadius: "4px",
    },
  },

  footer: {
    width: "100%",
    height: "80px",
    position: "fixed",
    bottom: "0",
    left: "0",
    alignSelf: "end",
    background: "linear-gradient(90.58deg, #0AB6FF -1.8%, #008CF5 104.64%)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  btn: {
    background: "linear-gradient(123.51deg, #F27202 23.31%, #F5A300 77.69%)",
    boxShadow:
      "0px 2px 8px rgba(251, 90, 21, 0.56), inset 0px -4px 0px #CE7612",
    borderRadius: "12px",
    padding: "12px 21px",
  },
  btnText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.08em",

    color: "#FFFFFF",
  },
  actionsWrapper: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  breadCrumbsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    [theme.breakpoints.up("sm")]: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      background: theme.palette.background.background1,
    },
  },
  timerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  downloadIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "63px",
    fontSize: "14px",
    color: "#FF712D",
    lineHeight: "16.4px",
    textTransform: "capitalize",
    fontWeight: "600",
  },
  downloadIconMob: {
    fontSize: "14px",
    color: "#FF712D",
    fontWeight: "500",
  },
  endIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
  ReportBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  ReportBtn: {
    color: theme.palette.report,
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "none",
    width: "max-content",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#FEE4D7",
      color: "#FF702D",
    },
  },
  ratings: {
    position: "fixed",
    right: "10px",
    bottom: "10px",
    zIndex: 2000,
  },
}));

const MathWorkSheet = () => {
  const getDeviceInfo = useDeviceInfo();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));
  const [fromAssignment, setFromAssignment] = useState(
    query.get("assignedToMe")
  );
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const matchesSmd = useMediaQuery("(min-width: 960px)");

  const matchesXs = useMediaQuery("(max-width:320px)");

  // const matches = useMediaQuery("(min-width: 600px)");
  const matchesTab = useMediaQuery("(min-width:600px)");
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  toast.configure();
  const { catalogId, exerciseId } = useParams();
  const [submissionId, setSubmissionId] = useState(query.get("submissionId"));
  const [error, setError] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [properties, setProperties] = useState({});
  const [exerciseOverview, setExerciseOverview] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaderForSubmit, setLoaderForSubmit] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [reattempt, setReattempt] = useState(false);
  const [attemptId, setAttemptId] = useState(null);
  const [parentHtml, setParentHtml] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [textEditor, setTextEditor] = useState(false);
  const [showAddPageBtn, setShowAddPageBtn] = useState(true);
  const [view, setView] = useState("");
  const [tab, setTab] = useState("worksheet");
  const [submission, setSubmission] = useState(0);
  const [submittedAnswer, setSubmittedAnswer] = useState();
  const [isSubmitted, setIsSubmitted] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [showCancel, setShowCancel] = React.useState(false);
  const [actionsDisabled, setActionsDisabled] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [previewMode, setPreviewMode] = useState("0");
  const role = getRole();
  const [startTime, setStartTime] = useState("");
  const [showTimer, setShowTimer] = useState(
    getRole() == "student" || getRole() == "guest"
  );
  const [timerDuration, setTimerDuration] = useState();
  const [isAssessment, setIsAssessment] = useState(false);
  const [startAssessmentTime, setStartAssessmentTime] = useState("");
  const [endAssessmentTime, setEndAssessmentTime] = useState("");
  const [showAssessmentTimer, setShowAssessmentTimer] = useState(false);
  const [isExerciseCompleted, setIsExerciseCompleted] = useState(false);
  const [restartTimer, setRestartTimer] = useState(false);
  const [endTime, setEndTime] = useState("");
  const actionsRef = useRef(null);
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userID = userInfo.userId;
  const [totalPages, setTotalPages] = useState(0);
  const [pageData, setPageData] = useState("");
  const [workSheet, setWorkSheet] = useState("");
  const [workSheetData, setWorkSheetData] = useState([]);
  const [workSheetSubmission, setWorkSheetSubmission] = useState();
  const [currentPageId, setCurrentPageId] = useState("");
  const [pageAttemptId, setPageAttemptId] = useState();
  const [openReattemptModal, setOpenReattemptModal] = useState(false);
  const [workSheetLoader, setWorkSheetLoader] = useState(false);
  const [reAttemptPage, setReAttemptPage] = useState(false);
  const [keyboard, setKeyboard] = React.useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(null);
  const [dowloadLoading, setDownloadLoading] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [missingImage, setMissingImage] = useState(false);
  const [workSheetProperties, setWorkSheetProperties] = useState(null);
  const [loadOriginalState, setLoadOriginalState] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [clearedIds, setClearedIds] = useState([]);

  //New logic for storing answers
  const [changedAnswers, setChangedAnswers] = useState({});

  const sentryLog = (message, exerciseId, userID) => {
    const event = {
      message,
      level: Sentry.Severity.Info,
      extra: {
        exerciseId,
        userID,
      },
    };
    Sentry.captureEvent(event);
  };

  const startTimePastSevenDays = (submissionTime) => {
    if (submissionTime?.endTime) return false;
    const oneDay = 24 * 60 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((new Date().getTime() - submissionTime.startTime) / oneDay)
    );
    if (diffDays >= 7) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    API.get(`/exercises/${exerciseId}/page/first`)
      .then((res) => {
        if (totalPages > 0) {
          setCurrentPage(res.data?.page);
        } else {
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        const event = {
          message: "Maths First PageNo. API Failure",
          level: Sentry.Severity.Info,
          extra: {
            exerciseId: exerciseId,
            userID: userID,
          },
        };
        Sentry.captureEvent(event);
        console.log("error while fetching data", err);
      });
  }, []);

  useEffect(() => {
    if (currentPage) {
      fetchExercise();
    }
  }, [currentPage]);

  useEffect(() => {
    if (window.innerWidth < 600 && tab === "score") {
      document.getElementById("footer").style.display = "none";
      const offsetHeight =
        document.getElementById("scoreLayout").offsetHeight + 100;
      document.getElementById("scoreLayout").style.height = `${offsetHeight}px`;
    } else {
      let ele = document.getElementById("footer");
      if (ele) {
        ele.style.display = "flex";
      }
    }
  }, [tab]);

  const decode = (str) => {
    let txt = new DOMParser().parseFromString(str, "text/html");
    return txt.documentElement.textContent;
  };

  const fetchExercise = (view = "") => {
    API.get(`/exercises`, {
      catalogId: catalogId,
      exerciseId: exerciseId,
      submissionId,
    })
      .then((res) => {
        const recentSubmissionTime =
          res.data.responseData?.recentAttempt?.submissionTime;
        const normalSubmission =
          res.data.responseData?.submission?.submissionTime;
        setTotalPages(res.data.responseData.pageCount);
        setExercise(res.data.responseData);
        setWorkSheetSubmission(res.data.responseData.submission);

        if (role !== "admin" && !res.data.responseData?.isPublished) {
          window.location.href = `${process.env.REACT_APP_SANDBOX_PORTAL_URL}#/exercises`;
        }

        if (res.data.responseData.recentAttempt?.attemptId) {
          setAttemptId(res.data.responseData?.recentAttempt?.attemptId);
        }
        if (res.data?.responseData?.properties) {
          setTimerDuration(res?.data?.responseData.properties.timer);
        }
        if (res.data?.responseData.typeId) {
          setIsAssessment(res?.data?.responseData.typeId);
        }
        setIsExerciseCompleted(res?.data?.responseData.isExerciseCompleted);
        setWorkSheetProperties(res?.data?.responseData?.properties);
        const submissionTime = normalSubmission?.startTime
          ? normalSubmission
          : recentSubmissionTime;
        if (
          res.data.responseData.isExerciseCompleted === 1 &&
          !submissionTime
        ) {
          setShowTimer(false);
          setShowAssessmentTimer(false);
        } else if (
          submissionTime &&
          !res?.data?.responseData?.properties?.timer
        ) {
          if (startTimePastSevenDays(submissionTime)) {
            const now = new Date().getTime();
            setStartTime(now);
            startTimeFunction({
              startTime: now,
              exerciseId,
              mathWorksheet: 1,
              reAttempt: 1,
              exercise,
              catalogId,
              setAttemptId,
              pageId: currentPageId,
              mathsPageData: workSheetData[0],
              setPageAttemptId,
              language: res.data.responseData?.language || "maths",
            });
          } else {
            setStartTime(submissionTime.startTime);
            if (
              showTimer &&
              (res.data.responseData.submission?.attemptedAll ||
                res.data?.responseData?.isExerciseCompleted === 1)
            ) {
              setEndTime(submissionTime.endTime);
            }
          }
        } else if (
          submissionTime &&
          res?.data?.responseData?.properties?.timer
        ) {
          setStartAssessmentTime(submissionTime.startTime);
          if (res.data.responseData.submission?.attemptedAll) {
            setEndAssessmentTime(submissionTime.endTime);
          }
        }
        view !== "preview" && setExerciseOverview(true);
        setLoading(false);
      })
      .catch((err) => {
        const event = {
          message: "Maths Exercise load error",
          level: Sentry.Severity.Info,
          extra: {
            exerciseId: exerciseId,
            userID: userID,
          },
        };
        Sentry.captureEvent(event);
        setError(!error);
        console.error("error while fetching exersie data", err);
      });
  };
  const handleClose = () => {
    setExerciseOverview(false);
  };
  const handleCongrats = () => {
    setShowCongrats(false);
  };

  const handleNextExercise = () => {
    setShowCongrats(false);
    window.open(exercise.nextExercise, "_self");
    window.location.reload();
  };
  const goBacktoExercise = () => {
    history.push(`/exercises/${catalogId}`);
  };
  const handleSubmit = (formData) => {
    setLoaderForSubmit(true);
    API.post(`/exercises`, formData)
      .then((res) => {
        toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT });
        setChangedAnswers({});
        const pageSubmission = res.data.page.submission;
        setLoaderForSubmit(false);
        setClearedIds([]);
        if (
          res.data.submission?.attempted &&
          res.data.submission.attemptedAll &&
          showTimer &&
          !timerDuration
        ) {
          setEndTime(formData.get("endTime"));
        }
        if (
          res.data.submission?.attempted &&
          res.data.submission.attemptedAll &&
          showAssessmentTimer &&
          timerDuration
        ) {
          setEndAssessmentTime(formData.get("endTime"));
        }
        setIsExerciseCompleted(res.data?.isExerciseCompleted);
        if (res.data?.isExerciseCompleted == 1) {
          setShowRating(true);
        }
        setSubmission(pageSubmission);
        setWorkSheetSubmission(res.data.submission);
        setSubmittedAnswer(res.data.page?.answers);
        setIsSubmitted(pageSubmission.attempted);
        setProperties(res.data.page?.properties);
        if (res.data.page?.recentAttempt?.attemptId) {
          setPageAttemptId(res.data.page?.recentAttempt.attemptId);
        }
        if (res.data.recentAttempt?.attemptId) {
          setAttemptId(res.data.recentAttempt?.attemptId);
        }
        if (res.data.page?.submission?.correctAnswers) {
          let frame;
          if (
            previewMode === "1" &&
            workSheetData[0]?.properties &&
            workSheetData[0]?.properties?.hasOwnProperty("imported") &&
            workSheetData[0]?.properties?.imported
          ) {
            frame = document.querySelectorAll("#worksheet")[1];
          } else {
            frame = document.getElementById("worksheet");
          }
          frame.contentDocument
            .querySelectorAll("[data-ans]")
            .forEach((node) => {
              if (res.data.page?.submission?.correctAnswers[node.id] === true) {
                node.style.background = "rgb(202 246 202 / 50%)";
                node.style.border = "4px solid #14B814";
              } else if (
                res.data.page?.submission?.correctAnswers[node.id] === false
              ) {
                node.style.background = "rgb(246 214 202 / 50%)";
                node.style.border = "4px solid #ED6D5A";
              } else {
                node.style.background = "rgb(256 256 256 0.4)";
                node.style.border = "1px solid black";
              }
            });
        }
      })
      .catch((e) => {
        setLoaderForSubmit(false);
        sentryLog("Maths Exercise Submit error", exerciseId, userID);
        toast.error("Sorry, unable to save exercise!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleAnswers = (val) => {
    setShowAnswers(val);
  };

  const setHtml = (html) => {
    setParentHtml(html);
  };
  const closeShowAnswers = () => {
    setShowAnswers(false);
  };

  const handleTotalScore = (score) => {
    setTotalScore(score);
  };

  const handleReattempt = (val) => {
    setReattempt(val);
  };

  const handleDownloadWorksheet = async () => {
    setDownloadLoading(true);
    try {
      const response = await API.get(`/exercises/${exerciseId}/pdfDownload`, {
        responseType: "arraybuffer",
      });
      const buffer = Buffer.from(response.data.pdf);
      const pdfBlob = new Blob([buffer], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${response.data.name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDownloadLoading(false);
    } catch (error) {
      const event = {
        message: "Maths Download API failure",
        level: Sentry.Severity.Info,
        extra: {
          exerciseId: exerciseId,
          userID: userID,
        },
      };
      Sentry.captureEvent(event);
      setDownloadLoading(false);
      console.error("Error downloading exercise:", error);
    }
  };

  const toggleTab = (value) => {
    setTab(value);
  };
  function compareAnswers(attemptedAnswers, newAnswers) {
    for (const key in attemptedAnswers) {
      if (attemptedAnswers[key] !== newAnswers[key]) {
        return true;
      }
    }
    return false;
  }

  const handleChangeAnswers = (id, value) => {
    setChangedAnswers((prevAns) => ({
      ...prevAns,
      [id]: value,
    }));
  };

  const handleSubmitAnswers = (
    markAsCompleted,
    reAttempt,
    previewMode = "0"
  ) => {
    if (role !== "admin" || view === "preview") {
      let frame;
      if (
        previewMode === "1" &&
        workSheetData[0]?.properties &&
        workSheetData[0]?.properties?.hasOwnProperty("imported") &&
        workSheetData[0]?.properties?.imported
      ) {
        frame = document.querySelectorAll("#worksheet")[1];
      } else {
        frame = document.getElementById("worksheet");
      }
      // const answersJson = {};
      // frame?.contentDocument.querySelectorAll("[data-ans]").forEach((node) => {
      //   if (
      //     node.getAttribute("type") === "multi-select" ||
      //     node.getAttribute("type") === "single-select"
      //   ) {
      //     answersJson[node.id] = node.getAttribute("user-submission");
      //   } else if (node.getAttribute("type") === "latex") {
      //     answersJson[node.id] = node.getAttribute("user-submission");
      //   } else if (node.getAttribute("type") === "descriptive") {
      //     answersJson[node.id] = node.innerHTML;
      //   } else {
      //     let decodedVal = decode(node.innerHTML);
      //     answersJson[node.id] = decodedVal;
      //   }
      // });
      let frameContent;
      if (
        workSheetData[0]?.properties &&
        workSheetData[0]?.properties?.hasOwnProperty("imported") &&
        workSheetData[0]?.properties?.imported
      ) {
        frameContent = frame.contentDocument.querySelector("#root").outerHTML;
      } else {
        frameContent = frame.contentDocument.querySelector("#canvas").outerHTML;
      }
      const formData = new FormData();
      if (workSheetData[0]?.originalState == workSheetData[0]?.src) {
        if (!missingImage) {
          CompareHtml(
            "submission",
            frameContent,
            workSheetData[0]?.properties?.backgroundUrl,
            exerciseId,
            userID,
            setMissingImage
          );
        }
      }
      var blob = new Blob([frameContent]);
      let filedata = new File([blob], `${exerciseId}.html`);
      if (filedata.size < 10) {
        toast.error("Sometihing went wrong, Please try again!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const event = {
          message: "File size is less than 10 bytes",
          level: Sentry.Severity.Info,
          extra: {
            exerciseId: exerciseId,
            userID: userID,
          },
        };
        Sentry.captureEvent(event);
        setLoadOriginalState(true);
        return;
      }
      if (reAttempt && !timerDuration) {
        setShowTimer(getRole() == "student" || getRole() == "guest");
        const now = new Date().getTime();
        setStartTime(now);
        setEndTime("");
        formData.append("startTime", now);
      }
      if (reAttempt && timerDuration) {
        setRestartTimer(true);
        setTimeout(() => {
          setRestartTimer(false);
        }, 0);
        setShowAssessmentTimer(getRole() == "student" || getRole() == "guest");
        const now = new Date().getTime();
        setStartAssessmentTime(now);
        setEndAssessmentTime("");
        formData.append("startTime", now);
      }
      if (showTimer && markAsCompleted && !timerDuration) {
        const now = new Date().getTime();
        formData.append("startTime", startTime);
        formData.append("endTime", now);
      } else if (showAssessmentTimer && markAsCompleted && timerDuration) {
        const now = new Date().getTime();
        formData.append("startTime", startAssessmentTime);
        formData.append("endTime", now);
      }
      formData.append("language", exercise.language);
      formData.append("exerciseId", exerciseId);
      formData.append("catalogId", catalogId);
      formData.append("reAttempt", reAttempt);
      formData.append("attemptId", attemptId ? attemptId : "");
      formData.append("pageAttemptId", pageAttemptId);
      formData.append("mathWorksheet", 1);
      formData.append("answers", JSON.stringify(changedAnswers));
      if (previewMode !== "0") {
        formData.append("previewMode", previewMode);
      }
      formData.append("pageId", workSheetData[0]?.pageId);
      formData.append("type", "worksheet-page");
      formData.append("file", filedata);
      formData.append("device", JSON.stringify(getDeviceInfo));
      formData.append("cleared", clearedIds);
      formData.append("dbAnswer", submittedAnswer);
      if (isSubmitted) {
        if (Object.keys(changedAnswers).length !== 0) {
          handleSubmit(formData);
        } else {
          toast.error("Please attempt some questions before submitting", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      } else {
        handleSubmit(formData);
      }
    }

    if (!reAttempt) {
      toggleTab("score");
    }
  };

  const handleReattemptAnswers = (type) => {
    if (type === "open") {
      setOpenReattemptModal(true);
    } else {
      setOpenReattemptModal(false);
    }
  };

  const handleSaveWorksheet = () => {
    actionsRef.current.save();
  };

  const handleFormatWorksheet = () => {
    actionsRef.current.format();
  };

  const handleDeleteWorksheet = () => {
    actionsRef.current.delete();
  };

  const handleCopyWorksheet = () => {
    actionsRef.current.copy();
  };

  const handleDragWorksheet = () => {
    actionsRef.current.drag();
  };

  const handleTypeWorksheet = () => {
    actionsRef.current.type();
  };
  const displayCancel = (value) => {
    setShowCancel(value);

    value ? setActionsDisabled(true) : setActionsDisabled(false);
  };

  const getAnsweredDivIds = (answers) => {
    const answeredDivIds = [];

    for (let divId in answers) {
      if (answers[divId] !== "") {
        answeredDivIds.push(divId);
      }
    }
    return answeredDivIds;
  };

  const saveClearAnswerIds = () => {
    let frame;
    if (
      previewMode === "1" &&
      workSheetData[0]?.properties &&
      workSheetData[0]?.properties?.hasOwnProperty("imported") &&
      workSheetData[0]?.properties?.imported
    ) {
      frame = document.querySelectorAll("#worksheet")[1];
    } else {
      frame = document.getElementById("worksheet");
    }
    const answersJson = {};
    frame?.contentDocument.querySelectorAll("[data-ans]").forEach((node) => {
      if (
        node.getAttribute("type") === "multi-select" ||
        node.getAttribute("type") === "single-select"
      ) {
        answersJson[node.id] = node.getAttribute("user-submission");
      } else if (node.getAttribute("type") === "latex") {
        answersJson[node.id] = node.getAttribute("user-submission");
      } else if (node.getAttribute("type") === "descriptive") {
        answersJson[node.id] = node.innerHTML;
      } else {
        let decodedVal = decode(node.innerHTML);
        answersJson[node.id] = decodedVal;
      }
    });
    const divIdsWithAnswers = getAnsweredDivIds(answersJson);
    setClearedIds(divIdsWithAnswers);
  };

  const handleClearAnswers = () => {
    saveClearAnswerIds();
    let frame;
    if (
      previewMode === "1" &&
      workSheetData[0]?.properties &&
      workSheetData[0]?.properties?.hasOwnProperty("imported") &&
      workSheetData[0]?.properties?.imported
    ) {
      frame = document.querySelectorAll("#worksheet")[1];
    } else {
      frame = document.getElementById("worksheet");
    }
    if (!frame) {
      setTimeout(handleClearAnswers, 300);
      return;
    }
    frame.contentDocument.querySelectorAll("[data-ans]").forEach((node) => {
      node.style.background = "rgba(255, 255, 255, 0.4)";
      node.style.border = "1px solid black";
      if (node.getAttribute("type") === "latex") {
        node.setValue("");
      } else {
        node.innerHTML = "";
      }

      if (
        node.getAttribute("type") === "multi-select" ||
        node.getAttribute("type") === "single-select"
      ) {
        let userSubmission = node.getAttribute("user-submission");

        let ansArray = userSubmission.split(":");
        if (ansArray[0] === "select" || ansArray[0] === "select-one") {
          let questionId = ansArray.slice(-1);
          node.setAttribute("question-id", questionId);
          if (ansArray[0] === "select-one") {
            node.setAttribute("user-submission", `select-one:no:${questionId}`);
            node.setAttribute("selected", "no");
          } else {
            node.setAttribute("user-submission", `select:no:${questionId}`);
            node.setAttribute("selected", "no");
          }
        }
      } else if (node.getAttribute("type") === "latex") {
        node.setAttribute("user-submission", "");
      }
    });
  };

  const handleReplaceAnswers = () => {
    actionsRef.current.handleReplace();
  };

  const handleVirtualKeyboard = (boxId, checked) => {
    setKeyboard((prevValues) => ({
      ...prevValues,
      [boxId]: checked,
    }));
    actionsRef.current.createBox();
    if (checked) {
      toast.success("Virtual Keyboard added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleBoxSelection = (item, val = true) => {
    if (item) {
      setShowAddPageBtn(false);
    }
    setSelectedBox(item);
    setTextEditor(val);
  };

  const showTextEditor = (val) => {
    setTextEditor(val);
  };

  const previewStatus = (status) => {
    setPreview(status);
    setView("preview");
    setPreviewMode("1");
  };
  useEffect(() => {
    if (!matchesMD) {
      getPageData();
    }
  }, [tab]);

  useEffect(() => {
    getPageData();
  }, [currentPage]);
  const getPageData = () => {
    if (currentPage) {
      setWorkSheetLoader(true);
      let url = submissionId
        ? `/exercises/${exerciseId}/page/${currentPage}?submissionId=${submissionId}`
        : `/exercises/${exerciseId}/page/${currentPage}`;
      API.get(url)
        .then((res) => {
          if (res.data.success) {
            setProperties(res.data.page[0]?.properties);
            setSubmission(res.data.page[0]?.submission);
            setSubmittedAnswer(res.data.page[0]?.answers);
            setIsSubmitted(res.data.page[0]?.submission?.attempted);
            setCurrentPageId(res.data.page[0]?.pageId);
            setWorkSheetData(res?.data?.page);
            if (res.data.page[0]?.reAttempted) {
              setReAttemptPage(res.data.page[0]?.reAttempted);
            }
            if (res.data.page[0]?.recentAttempt?.attemptId) {
              setPageAttemptId(res.data.page[0]?.recentAttempt.attemptId);
            }
            if (res.data.page[0] && res.data.page[0]?.properties) {
              if (res.data.page[0].properties?.isLatex) {
                setKeyboard(res.data.page[0].properties.isLatex);
              }
            }
            if (
              !res.data?.page[0].recentAttempt?.submissionTime?.startTime &&
              currentPage > 1
            ) {
              const now = new Date().getTime();
              setStartTime(now);
              startTimeFunction({
                startTime: now,
                exerciseId,
                mathWorksheet: 1,
                reAttempt: 0,
                exercise,
                catalogId,
                pageId: res.data?.page[0].pageId,
                mathsPageData: res.data?.page[0],
                setPageAttemptId,
              });
            }
            setWorkSheetLoader(false);
          }
        })
        .catch((err) => {
          const event = {
            message: "Maths Page load error",
            level: Sentry.Severity.Info,
            extra: {
              exerciseId: exerciseId,
              userID: userID,
            },
          };
          Sentry.captureEvent(event);
          setWorkSheetLoader(false);
          console.log("unable to fecth data", err);
        });
    }
  };

  const closePreview = () => {
    setPreview(false);
    setView("");
    fetchExercise("preview");
    setPreviewMode("0");
  };
  const openReport = (type) => {
    if (type === "open") {
      setOpenReportModal(true);
    } else {
      setOpenReportModal(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={classes.parentContainer}>
      {matchesMD && (
        <div
          className={`${classes.breadCrumbsContainer} ${classes.stickySection}`}
        >
          <Grid container className={classes.layout} spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <BreadcrumbsSimple
                catalogId={catalogId}
                exerciseId={exerciseId}
                isExerciseCompleted={exercise.isExerciseCompleted}
                exerciseTitle={exercise.exerciseTitle}
                fromAssignment={fromAssignment}
                view={view}
                handleDownloadWorksheet={handleDownloadWorksheet}
                maths={true}
                showBreadcrumb={matchesMD}
                dowloadLoading={dowloadLoading}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {matchesMD && totalPages > 1 && (
                <CustomPagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  workSheetData={workSheetData[0]}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              )}
            </Grid>
            {matchesMD && (
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {(role !== "admin" || view === "preview") && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      varient="text"
                      endIcon={
                        dowloadLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <DownloadIcon />
                        )
                      }
                      className={`${classes.downloadIcon}`}
                      onClick={handleDownloadWorksheet}
                      classes={{ iconSizeMedium: classes.endIcon }}
                      style={{
                        cursor: "pointer",
                      }}
                      disabled={dowloadLoading}
                    >
                      {dowloadLoading ? "Downloading" : "Download"}
                    </Button>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {role !== "admin" && currentPage === 1 && (
        <ExerciseOverview
          open={exerciseOverview}
          handleClose={handleClose}
          setExerciseOverview={setExerciseOverview}
          exersice={exercise}
          showBrief={false}
          startTime={startTime}
          setStartTime={setStartTime}
          exerciseId={exerciseId}
          catalogId={catalogId}
          mathWorksheet={1}
          showTimer={showTimer}
          setAttemptId={setAttemptId}
          setStartAssessmentTime={setStartAssessmentTime}
          startAssessmentTime={startAssessmentTime}
          showAssessmentTimer={showAssessmentTimer}
          timerDuration={timerDuration}
          setShowAssessmentTimer={setShowAssessmentTimer}
          isAssessment={timerDuration}
          endAssessmentTime={endAssessmentTime}
          pageId={currentPageId}
          mathsPageData={workSheetData[0]}
          setPageAttemptId={setPageAttemptId}
          isExerciseCompleted={exercise?.isExerciseCompleted}
          exerciseRating={exercise?.exerciseRating}
        />
      )}
      {openReportModal && (
        <ReportIssue
          open={openReportModal}
          closeModalHandler={openReport}
          title={exercise.exerciseTitle}
          exerciseId={exerciseId}
        />
      )}
      {openReattemptModal && (
        <ReattamptModal
          open={openReattemptModal}
          closeModalHandler={handleReattemptAnswers}
          previewMode={previewMode}
          handleReattempt={handleReattempt}
          handleSubmitAnswers={handleSubmitAnswers}
          exerciseId={exerciseId}
        />
      )}
      <Congrats
        setShowCongrats={setShowCongrats}
        handleClose={handleCongrats}
        open={showCongrats}
        nextExercise={exercise.nextExercise}
        handleNextExercise={handleNextExercise}
        startAssessmentTime={startAssessmentTime}
        endAssessmentTime={endAssessmentTime}
        isAssessment={timerDuration}
        duration={timerDuration}
        fromAssignment={fromAssignment}
        exerciseId={exerciseId}
      />
      <ShowAnswers
        exercise={workSheetData[0]}
        open={showAnswers}
        closeModalHandler={closeShowAnswers}
        role={role}
        html={parentHtml}
        exerciseId={exerciseId}
        properties={properties}
        tab={tab}
        submission={submission}
      />
      {role === "admin" && preview && (
        <Preview
          exercise={workSheetData[0]}
          open={preview}
          closeModalHandler={closePreview}
          exerciseId={exerciseId}
          properties={properties}
          tab={tab}
          submission={submission}
          goBacktoExercise={goBacktoExercise}
          showAnswers={handleAnswers}
          handleSubmitAnswers={handleSubmitAnswers}
          handleReattemptAnswers={handleReattemptAnswers}
          handleClearAnswers={handleClearAnswers}
          startTime={startTime}
          endTime={endTime}
          showTimer={showTimer}
          loaderForSubmit={loaderForSubmit}
          selectedBox={selectedBox}
          textEditor={textEditor}
          totalScore={totalScore}
          showTextEditor={showTextEditor}
          handleBoxSelection={handleBoxSelection}
          previewStatus={previewStatus}
          view={view}
          previewMode={previewMode}
          workSheetSubmission={workSheetSubmission}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          pageNumber={pageNumber}
        />
      )}
      {!matchesMD ? (
        <>
          <Grid container className={classes.layout}>
            <>
              <div className={classes.executionArea}>
                <div className={classes.inputButton}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => toggleTab("worksheet")}
                    style={{
                      textTransform: "none",
                      backgroundColor:
                        tab === "worksheet" ? "#FF702D" : "#FFFFFF",
                      color: tab === "worksheet" ? "#fff" : "#FF702D",
                      border: "1px solid #FF702D",
                      height: "40px",
                    }}
                    fullWidth
                  >
                    WORKSHEET
                  </Button>
                </div>
                <div
                  className={classes.inputButton}
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => toggleTab("score")}
                    style={{
                      textTransform: "none",
                      backgroundColor: tab === "score" ? "#FF702D" : "#FFFFFF",

                      color: tab === "score" ? "#fff" : "#FF702D",
                      border: "1px solid #FF702D",
                      height: "40px",
                    }}
                    fullWidth
                  >
                    SCORE
                  </Button>
                </div>
              </div>
            </>
            {tab === "worksheet" ? (
              <Grid item xs={12} className={classes.worksheetLayout}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className={classes.stickyPosition}
                >
                  <Button
                    onClick={goBacktoExercise}
                    style={{
                      marginLeft: !matchesXs ? "5px" : "-10px",
                      textTransform: "none",
                      fontSize: "14px",
                      color: "#959595",
                      fontWeight: "400",
                      marginTop: "10px",
                    }}
                  >
                    <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />{" "}
                    {!matchesXs ? "Back" : ""}
                  </Button>
                  {(role == "student" || role == "guest") &&
                    showTimer &&
                    !timerDuration && (
                      <div className={classes.timerContainer}>
                        <Timer
                          startTime={startTime}
                          endTime={endTime}
                          type="worksheet"
                        />
                      </div>
                    )}
                  {(role !== "admin" || view === "preview") && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        className={classes.ReportBtn}
                        onClick={() => openReport("open")}
                      >
                        {matchesTab
                          ? "Report issue"
                          : matchesXs
                          ? ""
                          : "Report"}
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ marginLeft: "4px" }}
                        />
                      </Button>
                      {matchesTab ? (
                        <Button
                          varient="text"
                          endIcon={
                            dowloadLoading ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <DownloadIcon />
                            )
                          }
                          className={`${classes.downloadIcon}`}
                          onClick={handleDownloadWorksheet}
                          classes={{ iconSizeMedium: classes.endIcon }}
                          style={{
                            cursor: "pointer",
                          }}
                          disabled={dowloadLoading}
                        >
                          {dowloadLoading ? "Downloading" : "Download"}
                        </Button>
                      ) : (
                        <IconButton
                          onClick={handleDownloadWorksheet}
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classes.downloadIconMob}`}
                          classes={{ iconSizeMedium: classes.endIcon }}
                        >
                          {dowloadLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <DownloadIcon />
                          )}
                        </IconButton>
                      )}
                    </div>
                  )}
                </div>
                {(getRole() == "student" || getRole() == "guest") &&
                  showAssessmentTimer &&
                  timerDuration && (
                    <AssessmentTimer
                      startTime={startAssessmentTime}
                      endTime={endAssessmentTime}
                      duration={timerDuration}
                      restartTimer={restartTimer}
                    />
                  )}
                <Grid>
                  {workSheetLoader ? (
                    <Loader />
                  ) : (
                    <WorkSheet
                      exercise={workSheetData[0]}
                      setParentHtml={setHtml}
                      fetchExercise={fetchExercise}
                      handleTotalScore={handleTotalScore}
                      reattempt={reattempt}
                      handleReattempt={handleReattempt}
                      properties={properties}
                      tab={tab}
                      submission={submission}
                      ref={actionsRef}
                      displayCancel={displayCancel}
                      handleBoxSelection={handleBoxSelection}
                      showTextEditor={showTextEditor}
                      setShowAddPageBtn={setShowAddPageBtn}
                      setWorkSheet={setWorkSheet}
                      workSheet={workSheet}
                      pageId={currentPageId}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      setPageData={setCurrentPageId}
                      pageData={pageData}
                      setWorkSheetData={setWorkSheetData}
                      keyboard={keyboard}
                      setMissingImage={setMissingImage}
                      loadOriginalState={loadOriginalState}
                      setLoadOriginalState={setLoadOriginalState}
                      handleChangeAnswers={handleChangeAnswers}
                      changedAnswers={changedAnswers}
                    />
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                className={classes.scoreLayout}
                style={{
                  height: !matchesMD
                    ? "calc(100vh - 300px)"
                    : "calc(100vh - 400px)",
                }}
                id="scoreLayout"
              >
                <YourScore
                  goBacktoExercise={goBacktoExercise}
                  exerciseId={exerciseId}
                  submission={submission}
                  exercise={exercise}
                  showAnswers={handleAnswers}
                  readOnly={submissionId}
                  handleSubmitAnswers={handleSubmitAnswers}
                  handleReattemptAnswers={handleReattemptAnswers}
                  startTime={startTime}
                  endTime={endTime}
                  showTimer={showTimer}
                  loaderForSubmit={loaderForSubmit}
                  selectedBox={selectedBox}
                  textEditor={textEditor}
                  showTextEditor={showTextEditor}
                  handleBoxSelection={handleBoxSelection}
                  previewStatus={previewStatus}
                  view={view}
                  previewMode={previewMode}
                  startAssessmentTime={startAssessmentTime}
                  endAssessmentTime={endAssessmentTime}
                  duration={timerDuration}
                  showAssessmentTimer={showAssessmentTimer}
                  restartTimer={restartTimer}
                  totalPages={totalPages}
                  setShowAddPageBtn={setShowAddPageBtn}
                  showAddPageBtn={showAddPageBtn}
                  setPageData={setPageData}
                  setWorkSheet={setWorkSheet}
                  setCurrentPage={setCurrentPage}
                  fetchExercise={fetchExercise}
                  workSheetData={workSheetData[0]}
                  workSheetSubmission={workSheetSubmission}
                  currentPageId={currentPageId}
                  handleVirtualKeyboard={handleVirtualKeyboard}
                  keyboard={keyboard}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  setTab={setTab}
                  fromAssignment={fromAssignment}
                  isExerciseCompleted={isExerciseCompleted}
                  workSheetProperties={workSheetProperties}
                  language={exercise?.language}
                />
                <Grid className={classes.ReportBtnBox}>
                  <Button
                    className={classes.ReportBtn}
                    style={{ marginTop: "-10px" }}
                    onClick={() => {
                      openReport("open");

                      saveGTMEvents(
                        "report_issue",
                        sandboxUser.role,
                        sandboxUser.email,
                        exerciseId,
                        exercise.exerciseTitle,
                        exercise.language
                      );
                    }}
                  >
                    Report issue{" "}
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ marginLeft: "4px" }}
                    />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <footer className={classes.footer} id="footer">
            {tab === "worksheet" ? (
              <>
                <div className={classes.actionsWrapper}>
                  {(workSheetSubmission?.attemptedAll || isExerciseCompleted) &&
                  getRole() !== "admin" ? (
                    <Button
                      variant="text"
                      startIcon={<ReplayIcon />}
                      className={`${classes.btnText}`}
                      onClick={() => handleReattemptAnswers("open")}
                      style={{ cursor: "pointer" }}
                      classes={{ iconSizeMedium: classes.showIcon }}
                    >
                      REATTEMPT
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* {!submission?.attemptedAll && !isExerciseCompleted ? (
                    <Button
                      variant="text"
                      startIcon={<ClearIcon />}
                      className={`${classes.btnText}`}
                      onClick={handleClearAnswers}
                      style={{ cursor: "pointer" }}
                      classes={{ iconSizeMedium: classes.showIcon }}
                    >
                      Clear
                    </Button>
                  ) : (
                    ""
                  )} */}
                </div>
                {(!submission.attempted || submission.attempted === 0) &&
                  !submissionId && (
                    <Button
                      className={`${classes.btn} ${classes.btnText}`}
                      onClick={() => handleSubmitAnswers(1, 0)}
                      style={{
                        cursor: role === "admin" ? "not-allowed" : "pointer",
                      }}
                    >
                      SUBMIT
                    </Button>
                  )}

                {submission.attempted &&
                submission.attempted !== 0 &&
                !submissionId ? (
                  <div className={classes.actionsWrapper}>
                    <Button
                      className={`${classes.btn} ${classes.btnText}`}
                      onClick={() => handleSubmitAnswers(1, 0)}
                      style={{
                        cursor: role === "admin" ? "not-allowed" : "pointer",
                      }}
                      classes={{ iconSizeMedium: classes.endIcon }}
                    >
                      RESUBMIT
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {totalPages > 1 && (
                  <CustomPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    workSheetData={workSheetData[0]}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                  />
                )}
              </>
            ) : (
              <div
                className={classes.btnText}
                onClick={() => toggleTab("worksheet")}
              >
                SWITCH To WORKSHEET
              </div>
            )}
          </footer>
        </>
      ) : (
        <Grid container className={classes.layout} spacing={2}>
          {showRating && (
            <div className={classes.ratings}>
              <ExerciseRatings exerciseId={exerciseId} afterSubmit={true} />
            </div>
          )}
          <Grid item xs={12} md={9} className={classes.worksheetLayout}>
            <Grid>
              {workSheetLoader ? (
                <Loader />
              ) : (
                <WorkSheet
                  exercise={workSheetData[0]}
                  setParentHtml={setHtml}
                  fetchExercise={fetchExercise}
                  handleTotalScore={handleTotalScore}
                  reattempt={reattempt}
                  handleReattempt={handleReattempt}
                  properties={properties}
                  tab={tab}
                  submission={submission}
                  ref={actionsRef}
                  displayCancel={displayCancel}
                  handleBoxSelection={handleBoxSelection}
                  showTextEditor={showTextEditor}
                  setShowAddPageBtn={setShowAddPageBtn}
                  setWorkSheet={setWorkSheet}
                  workSheet={workSheet}
                  pageId={currentPageId}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPageData={setCurrentPageId}
                  pageData={pageData}
                  setWorkSheetData={setWorkSheetData}
                  keyboard={keyboard}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  setMissingImage={setMissingImage}
                  loadOriginalState={loadOriginalState}
                  setLoadOriginalState={setLoadOriginalState}
                  handleChangeAnswers={handleChangeAnswers}
                  changedAnswers={changedAnswers}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} className={classes.scoreLayout}>
            <YourScore
              goBacktoExercise={goBacktoExercise}
              exerciseId={exerciseId}
              submission={submission}
              exercise={exercise}
              showAnswers={handleAnswers}
              readOnly={submissionId}
              handleSubmitAnswers={handleSubmitAnswers}
              handleReattemptAnswers={handleReattemptAnswers}
              handleSaveWorksheet={handleSaveWorksheet}
              handleFormatWorksheet={handleFormatWorksheet}
              handleDeleteWorksheet={handleDeleteWorksheet}
              handleCopyWorksheet={handleCopyWorksheet}
              handleDragWorksheet={handleDragWorksheet}
              handleTypeWorksheet={handleTypeWorksheet}
              showCancel={showCancel}
              actionsDisabled={actionsDisabled}
              handleClearAnswers={handleClearAnswers}
              handleReplaceAnswers={handleReplaceAnswers}
              startTime={startTime}
              endTime={endTime}
              showTimer={showTimer}
              loaderForSubmit={loaderForSubmit}
              selectedBox={selectedBox}
              textEditor={textEditor}
              showTextEditor={showTextEditor}
              handleBoxSelection={handleBoxSelection}
              previewStatus={previewStatus}
              view={view}
              previewMode={previewMode}
              startAssessmentTime={startAssessmentTime}
              endAssessmentTime={endAssessmentTime}
              duration={timerDuration}
              showAssessmentTimer={showAssessmentTimer}
              restartTimer={restartTimer}
              totalPages={totalPages}
              setShowAddPageBtn={setShowAddPageBtn}
              showAddPageBtn={showAddPageBtn}
              setPageData={setPageData}
              setWorkSheet={setWorkSheet}
              setCurrentPage={setCurrentPage}
              fetchExercise={fetchExercise}
              workSheetData={workSheetData[0]}
              workSheetSubmission={workSheetSubmission}
              currentPageId={currentPageId}
              handleVirtualKeyboard={handleVirtualKeyboard}
              keyboard={keyboard}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              fromAssignment={fromAssignment}
              isExerciseCompleted={isExerciseCompleted}
              workSheetProperties={workSheetProperties}
              language={exercise?.language}
            />
            <Grid className={classes.ReportBtnBox}>
              <Button
                className={classes.ReportBtn}
                onClick={() => {
                  openReport("open");

                  saveGTMEvents(
                    "report_issue",
                    sandboxUser.role,
                    sandboxUser.email,
                    exerciseId,
                    exercise.exerciseTitle,
                    exercise.language
                  );
                }}
              >
                Report issue{" "}
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ marginLeft: "4px" }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MathWorkSheet;
