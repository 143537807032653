import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    background: theme.palette.background.background3,
  },
}));
const Filter = ({ range, setRange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const days = [
    { text: "7 days", value: 7 },
    { text: "15 days", value: 15 },
    { text: "30 days", value: 30 },
    { text: "90 days", value: 90 },
  ];

  const handleSelectDay = (day) => {
    setRange(day);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  return (
    <div>
      <Button
        style={{
          height: 40,
          textTransform: "none",
          color: theme.palette.body,
          fontWeight: "bold",
          border: `0.4px solid ${theme.palette.border2}`,
          background: theme.palette.background.background3,
        }}
        onClick={handleClick}
        disableRipple
        // disabled={searchedFiles.length === 0}
      >
        <FilterListOutlinedIcon style={{ color: theme.palette.filterIcon }} />{" "}
        Show for
        <span style={{ color: "#FF712D", marginLeft: "5px" }}>
          {" "}
          {range} days
        </span>
      </Button>
      <Menu
        classes={{ paper: classes.menuPaper }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          marginTop: 50,
        }}
      >
        {days.map((data, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleSelectDay(data.value);
                handleClose();
              }}
              style={{
                width: "100%",
                background: theme.palette.background.background3,
                color: theme.palette.body,
              }}
            >
              {data.text}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default Filter;
