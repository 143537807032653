import React from "react";
import { Button, Grid, Dialog } from "@material-ui/core";
import Completed from "../../assets/images/illustration_view.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SkipNext } from "@material-ui/icons";
import Close from "../../assets/images/close-btn.png";
import completed_timer from "../../assets/images/completed_timer.png";

import { useState } from "react";
import ExerciseRatings from "./ExerciseRatings";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    height: "auto",
    [theme.breakpoints.up("md")]: {
      width: "874px",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "466px",
    },
  },
  heading: {
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "29px",
    color: theme.palette.heading,
    marginBottom: "10px",
  },
  description: {
    fontWeight: "500",
    color: theme.palette.heading,
    marginBottom: "38px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14x",
      lineHeight: "17px",
    },
  },
  next: {
    display: "flex",
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
    justifyContent: "center",
  },
  nextBtn: {
    background: "#FF712D",
    color: "#FFFFFF",
    // [theme.breakpoints.up("md")]: {
    //   position: "absolute",
    //   bottom: "28px",
    //   right: "28px",
    // },
  },
  close: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: "10px",
      right: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "8px",
      right: "0px",
    },
  },
  activeTimeContainer: {
    background: "#DBF0EF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginTop: "10px",
  },
  completedTimerText: {
    color: "#429F99",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      fontWeight: "600",
    },
  },
  ratings: {
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
  },
}));

const Congrats = ({
  setShowCongrats,
  open,
  handleCLose,
  nextExercise,
  handleNextExercise,
  startTime,
  endTime,
  startAssessmentTime,
  endAssessmentTime,
  isAssessment,
  duration,
  fromAssignment,
  exerciseId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  let hours, minutes, seconds;
  if (!isAssessment) {
    hours = Math.floor((endTime - startTime) / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0");
    minutes = Math.floor(
      ((endTime - startTime) % (1000 * 60 * 60)) / (1000 * 60)
    )
      .toString()
      .padStart(2, "0");
    seconds = Math.floor(((endTime - startTime) % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");
  } else {
    hours = Math.floor(
      (endAssessmentTime - startAssessmentTime) / (1000 * 60 * 60)
    )
      .toString()
      .padStart(2, "0");
    minutes = Math.floor(
      ((endAssessmentTime - startAssessmentTime) % (1000 * 60 * 60)) /
        (1000 * 60)
    )
      .toString()
      .padStart(2, "0");
    seconds = Math.floor(
      ((endAssessmentTime - startAssessmentTime) % (1000 * 60)) / 1000
    )
      .toString()
      .padStart(2, "0");
  }
  const timeDiff = `${hours}:${minutes}:${seconds}`;
  const assessmentDuration = duration + ":00";

  function compareTimes(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

    const totalSeconds1 = hours1 * 3600 + minutes1 * 60 + seconds1;
    const totalSeconds2 = hours2 * 3600 + minutes2 * 60 + seconds2;

    const difference = totalSeconds1 - totalSeconds2;

    if (difference >= 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={open}
        onCLose={handleCLose}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.background3,
          },
        }}
      >
        <Grid container>
          <Grid item>
            <div className={classes.container}>
              <Button
                variant="text"
                className={classes.close}
                onClick={() => {
                  setShowCongrats((p) => !p);
                }}
              >
                <img width="15px" src={Close} alt="close" />
              </Button>
              {compareTimes(assessmentDuration, timeDiff) ? (
                <>
                  <div className={classes.heading}>Timely Victory!</div>
                  <p className={classes.description}>
                    You have successfully completed the exercise with in Time.
                  </p>
                </>
              ) : (
                <>
                  <div className={classes.heading}>Congrats!</div>
                  <p className={classes.description}>
                    You have successfully completed the exercise.
                  </p>
                </>
              )}
              <img width="200px" src={Completed} alt="img" />
              <div className={classes.activeTimeContainer}>
                <div className={classes.completedTimerText}>
                  <img
                    src={completed_timer}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                  <>Completed in {timeDiff} mins</>
                </div>
              </div>
              <div className={classes.ratings}>
                <ExerciseRatings exerciseId={exerciseId} />
              </div>
              {nextExercise && !fromAssignment && (
                <div className={classes.next}>
                  <Button
                    variant="outlined"
                    className={classes.nextBtn}
                    onClick={handleNextExercise}
                  >
                    Next Exercise {matchesSM ? <SkipNext /> : null}
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Congrats;
