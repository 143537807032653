import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import completed_timer from "../../assets/images/completed_timer.png";
import greenTimer from "../../assets/images/greenTimer.png";
import redTimer from "../../assets/images/redTimer.png";
import moment from "moment-timezone";
import { useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timerRoot: {
    //height: "26px",
    width: "max-content",
    borderRadius: "20px",
    padding: "5px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "500",
  },
  timer: {
    background: "linear-gradient(99.26deg, #F36B4E 0%, #F75624 100%), #FFFFFF",
  },
  timerGreen: {
    background: "linear-gradient(99.26deg, #4BCC0F 0%, #1AC740 100%), #FFFFFF",
  },
  activeTimeContainer: {
    background: "#DBF0EF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginTop: "10px",
  },
  completedTimerText: {
    color: "#429F99",
    fontSize: "16px",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "12px",
    //   fontWeight: "600",
    // },
  },
  completedTimerTextMaths: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#FFFFFF",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "12px",
    //   fontWeight: 400,
    // },
  },
  completedTimerTextMobile: {
    fontSize: "12px",
    fontWeight: 400,
  },
  activeTimeContainerMaths: {
    background: "linear-gradient(99.26deg, #19B933 0%, #13B105 100%), #FFFFFF",
    border: "2px solid #1CD935",
    borderRadius: "20px",
    color: "white",
    padding: "10px",
  },
}));

const AssessmentTimer = ({ startTime, endTime, duration, restartTimer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const calculateSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
  };

  const [seconds, setSeconds] = useState(calculateSeconds(duration + ":00"));

  const timeDiff = endTime - startTime;
  const hour = Math.floor(timeDiff / (1000 * 60 * 60));
  const minute = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const second = Math.floor((timeDiff % (1000 * 60)) / 1000);
  const formattedTimeDiff = `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}:${second.toString().padStart(2, "0")}`;

  useEffect(() => {
    let intervalId;
    if (!endTime && !restartTimer) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          const updatedSeconds = prevSeconds - 1;
          return updatedSeconds;
        });
      }, 1000);
    } else if (restartTimer) {
      setSeconds(calculateSeconds(duration + ":00"));
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [duration, endTime, restartTimer]);

  const formatTime = (totalSeconds) => {
    const sign = totalSeconds < 0 ? "-" : "";
    const absSeconds = Math.abs(totalSeconds);

    const hours = Math.floor(absSeconds / 3600);
    const minutes = Math.floor((absSeconds % 3600) / 60);
    const seconds = absSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${sign}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const timeOutput = formatTime(seconds);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!endTime ? (
        <div
          className={
            timeOutput.includes("-")
              ? `${classes.timer} ${classes.timerRoot}`
              : `${classes.timerGreen} ${classes.timerRoot}`
          }
        >
          <img
            src={timeOutput.includes("-") ? redTimer : greenTimer}
            style={{ marginRight: "5px", height: "16px", width: "16px" }}
          />
          {timeOutput}
        </div>
      ) : (
        <div
          className={classes.activeTimeContainerMaths}
          style={{
            background:
              "linear-gradient(99.26deg, #19B933 0%, #13B105 100%), #FFFFFF",
          }}
        >
          <div
            className={
              matchesSM
                ? classes.completedTimerTextMobile
                : classes.completedTimerTextMaths
            }
          >
            Completed in {formattedTimeDiff}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentTimer;
