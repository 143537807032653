import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IFrame from "./IFrame";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  layout: {
    padding: "24px 44px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    marginBottom: "20px",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "row",
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "3%",
    color: "#939393",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #939393",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  submitBtn: {
    padding: "8px 30px",
    color: "white",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 44px 20px 44px",
  },

  closeIconWrapper: {
    display: "flex",
    justifyContent: "end",
    marginRight: "20px",
  },

  closeIcon: {
    "& > *:first-child": {
      fontSize: "25px",
    },
  },
});

const ShowAnswers = ({
  exercise,
  open,
  closeModalHandler,
  role,
  html,
  exerciseId,
  properties,
  tab,
  submission,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} className={classes.layout}>
      <DialogTitle>
        {" "}
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModalHandler}
          aria-label="close"
          className={classes.closeIconWrapper}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <IFrame
          html={html}
          clearAnswers={false}
          answers={true}
          styles={exercise?.properties && exercise?.properties?.style}
          exerciseId={exerciseId}
          properties={properties}
          tab={tab}
          submission={submission}
          exercise={exercise}
        />
      </DialogContent>
      <div>
        <DialogActions className={classes.actions}>
          <Button onClick={closeModalHandler} className={classes.cancelBtn}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ShowAnswers;
