import React from "react";
import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: "flex",
    justifyContent: "center",
    padding: "3%",
    marginTop:"2%"
  },
  cancelButton: {
    padding: "8px 30px",
    marginRight: "5%",
    color: "#939393",
    width: "120px",
    height: "45px",
    background: "#FFFFFF",
    borderRadius: "4px",
    border: "1px solid #939393",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  modelHeading: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontstyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#444444",
    paddingTop: "5%",
  },
  errorTextBox: {
    display: "block",
    textAlign: "center",
    marginTop: "3.5%",
    marginTop:"8%"
  },
  errorText: {
    fontFamily: "lato",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#525252",
    marginTop: "3%",
  },
  popupBackground: {
    width: "460px",
    height: "200px",
  },
}));

const ErrorPopUp = ({ open, closeErrorPopup }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth="lg">
      <div className={classes.popupBackground}>
        <Typography className={classes.modelHeading}>
          Error!
        </Typography>
        <div className={classes.errorTextBox}>
          <Typography className={classes.errorText}>
          Your exercise couldn’t be saved.
          </Typography>
        </div>
        <div className={classes.btnBox}>
          <DialogActions>
            <Button className={classes.cancelButton} onClick={closeErrorPopup}>
              Close
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorPopUp;