import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined";
import TextEditor from "../../QuillTextEditor/TextEditor";
import { Spinner } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";

const Questions = ({
  data,
  handleNextSection,
  handleAnswers,
  setHasAnswered,
  questionSetIndex,
  handlePreviousQuestionSet,
  nextSection,
  viewResults,
  setIconDisabled,
  nextLoader,
  isExerciseCompleted,
  handleOpenEnded,
}) => {
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (data?.isQnResponded) {
      setIconDisabled(false);
    } else {
      setIconDisabled(true);
    }

    if (data?.questionType && data?.questionType === "Open-ended") {
      if (Object.keys(data?.response).length > 0) {
        let value = [data?.response["answer"]];
        setHasAnswered(true);
        setAnswers(value);
        handleAnswers(data?.questionType, data.questionId, value);
      }
    } else {
      const respondedOptions = data?.answers
        .filter((option) => option.responded)
        .map((option) => option.answerId);
      if (respondedOptions && respondedOptions.length > 0) {
        setHasAnswered(true);
        setAnswers(respondedOptions);
        handleAnswers(data?.questionType, data.questionId, respondedOptions);
      }
    }
  }, []);

  useEffect(() => {
    if (
      data?.questionType === "Question Set" &&
      questionSetIndex !== null &&
      questionSetIndex !== undefined
    ) {
      if (data?.subQuestions[questionSetIndex]?.questionType === "Open-ended") {
        if (
          Object.keys(data?.subQuestions[questionSetIndex]?.response).length > 0
        ) {
          let value = [
            data?.subQuestions[questionSetIndex]?.response["answer"],
          ];
          setHasAnswered(true);
          setAnswers(value);
          handleAnswers(
            data?.subQuestions[questionSetIndex].questionType,
            data?.subQuestions[questionSetIndex]?.questionId,
            value
          );
        }
      } else {
        const respondedOptions = data?.subQuestions[questionSetIndex]?.answers
          .filter((option) => option.responded)
          .map((option) => option.answerId);
        if (respondedOptions && respondedOptions.length > 0) {
          setHasAnswered(true);
          setAnswers(respondedOptions);
          handleAnswers(
            data?.subQuestions[questionSetIndex]?.questionType,
            data?.subQuestions[questionSetIndex]?.questionId,
            respondedOptions
          );
        }
      }
    }
  }, [questionSetIndex]);

  const handleNext = (action) => {
    handleNextSection(action);
  };

  const handlePrevious = () => {
    handlePreviousQuestionSet();
  };

  const handleAnswer = (e, questionType, questionId, answerId) => {
    setAnswers((prevSelected) => {
      let ans;
      if (questionType === "Multi Select") {
        ans = prevSelected.includes(answerId)
          ? prevSelected.filter((id) => id !== answerId)
          : [...prevSelected, answerId];
      } else if (questionType === "Single Select") {
        ans = [answerId];
      } else if (questionType === "Open-ended") {
        ans = [e];
      }

      handleAnswers(questionType, questionId, ans);
      setHasAnswered(true);
      return ans;
    });
  };

  const getBorderColor = (item, qType) => {
    if (viewResults) {
      if (item.responded) {
        return item?.correct ? "#70C29C" : "#EB9284";
      } else {
        if (qType === "Single Select") {
          return item?.answerCorrect ? "#70C29C" : "#D9DDE8";
        } else {
          return item?.answerCorrect ? "#FFAE52" : "#D9DDE8";
        }
      }
    } else {
      return answers.includes(item?.answerId) ? " #B6C2CD" : "#D9DDE8";
    }
  };

  const getColor = (item, qType) => {
    if (viewResults) {
      if (item.responded) {
        return item?.correct ? "#7EC8A6" : "#EEA195";
      } else {
        if (qType === "Single Select") {
          return item?.correct ? "#7EC8A6" : "#ffffff";
        } else {
          return item?.correct ? "#FFBC70" : "#ffffff";
        }
      }
    } else {
      return answers.includes(item?.answerId) ? "#C9D2DA" : "#ffffff";
    }
  };

  const getSingleSelectMcq = (questionData) => {
    handleOpenEnded(false);
    return (
      <Grid
        className={
          questionData?.questionType !== "Question Set" &&
          `${classes.flex_row} ${classes.justifyCenter}`
        }
      >
        <Grid className={classes.width100}>
          <Typography
            className={`${classes.questionText} ${classes.font14} ${classes.font400} ${classes.headingClr} mt-3`}
          >
            {questionData?.questionText}
          </Typography>
          <Grid className={`${classes.width100} mt-3`}>
            {questionData?.answers?.map((options, index) => {
              return (
                <Button
                  key={options?.answerId}
                  className={`${classes.optionsBox} ${classes.flex_row} ${classes.justifyLeft} ${classes.alignItemsCenter} ${classes.headingClr} mt-2`}
                  style={{
                    background: getColor(options, questionData?.questionType),
                    border: `1px solid ${getBorderColor(
                      options,
                      questionData?.questionType
                    )}`,
                  }}
                  onClick={(e) =>
                    handleAnswer(
                      e,
                      questionData.questionType,
                      questionData.questionId,
                      options.answerId,
                      options?.responded
                    )
                  }
                >
                  <Grid
                    className={`${classes.optionNoBox} ${classes.flex_col} ${classes.alignItemsCenter} ${classes.headingClr}`}
                  >
                    {options.index}
                  </Grid>
                  <Grid className={`${classes.optionText}`}>
                    {options.text}
                  </Grid>
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getQuetionSet = (questionData) => {
    return (
      <Grid>
        <Grid className={classes.questionSummaryContainer}>
          <Grid
            className={`${classes.qNoBox} ${classes.flex_row} ${classes.alignItemsCenter}`}
          >
            Question {questionSetIndex + 1} of {data?.subQuestions?.length}{" "}
          </Grid>
          {viewResults ? (
            <Grid className={classes.insightsScores}>
              Score :{" "}
              <span
                className={classes.actualScore}
              >{`${questionData?.subQuestions[questionSetIndex].scored} / ${questionData?.subQuestions[questionSetIndex].score}`}</span>
            </Grid>
          ) : null}
        </Grid>
        {questionData?.subQuestions[questionSetIndex]?.questionType ===
        "Single Select" ? (
          <Grid>
            {getSingleSelectMcq(questionData?.subQuestions[questionSetIndex])}
          </Grid>
        ) : questionData?.subQuestions[questionSetIndex]?.questionType ===
          "Open-ended" ? (
          <Grid>
            {getOpenEndedQues(questionData?.subQuestions[questionSetIndex])}
          </Grid>
        ) : (
          <Grid>
            {getMultiSelectQuetions(
              questionData?.subQuestions[questionSetIndex]
            )}
          </Grid>
        )}
      </Grid>
    );
  };
  const getOpenEndedQues = (questionData) => {
    handleOpenEnded(true);
    return (
      <Grid
        className={
          data?.questionType !== "Question Set" &&
          `${classes.flex_row} ${classes.justifyCenter}`
        }
      >
        <Grid>
          <Typography
            className={`${classes.questionText} ${classes.font14} ${classes.font400} ${classes.headingClr} mt-3`}
          >
            {questionData?.questionText}
          </Typography>
          <Grid className={`${classes.quillContainer} mt-3`}>
            <TextEditor
              handleChange={handleAnswer}
              answers={answers[0]}
              questionType={questionData.questionType}
              questionId={questionData.questionId}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const getMultiSelectQuetions = (questionData) => {
    handleOpenEnded(false);
    return (
      <Grid
        className={
          questionData?.questionType !== "Question Set" &&
          `${classes.flex_row} ${classes.justifyCenter} mt-3`
        }
      >
        <Grid className={classes.width100}>
          <Typography
            className={`${classes.questionText} ${classes.font400} ${classes.headingClr}`}
          >
            {questionData?.questionText}
          </Typography>
          {!isExerciseCompleted && (
            <Grid className={classes.questionHighlightContainer}>
              <InfoIcon className={classes.infoIcon} />
              <div className={classes.infoText}>
                You can choose more than one option
              </div>
            </Grid>
          )}
          <Grid className={`${classes.width100} mt-3`}>
            {questionData?.answers?.map((options, index) => {
              return (
                <Button
                  className={`${classes.optionsBox} ${classes.flex_row} ${classes.justifyLeft} ${classes.alignItemsCenter} ${classes.headingClr}`}
                  style={{
                    background: getColor(options, questionData?.questionType),
                    border: `1px solid ${getBorderColor(
                      options,
                      questionData?.questionType
                    )}`,
                  }}
                  key={index}
                  onClick={(e) =>
                    handleAnswer(
                      e,
                      questionData.questionType,
                      questionData.questionId,
                      options.answerId,
                      options?.responded
                    )
                  }
                >
                  <Grid
                    className={`${classes.optionNoBox} ${classes.flex_col} ${classes.alignItemsCenter} ${classes.headingClr}`}
                  >
                    {options.index}
                  </Grid>
                  <Grid className={`${classes.optionText}`}>
                    {options.text}
                  </Grid>
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const showButton = (key) => {
    if (key === "next") {
      if (
        nextSection ||
        (data?.subQuestions?.length > 0 &&
          questionSetIndex < data?.subQuestions?.length - 1)
      ) {
        return true;
      }
      return false;
    } else if (key === "finish") {
      if (
        !nextSection &&
        data?.subQuestions.length > 0 &&
        questionSetIndex === data?.subQuestions.length - 1
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <Grid
      className={`${classes.InstructionsAndQuestionBox} ${
        data.questionType === "Question Set" ? classes.questionSetBox : ""
      }`}
    >
      <Grid>
        {data.questionType === "Single Select" ? (
          <Grid>{getSingleSelectMcq(data)}</Grid>
        ) : data.questionType === "Question Set" ? (
          <Grid>{getQuetionSet(data)}</Grid>
        ) : data.questionType === "Open-ended" ? (
          <Grid>{getOpenEndedQues(data)}</Grid>
        ) : (
          <Grid>{getMultiSelectQuetions(data)}</Grid>
        )}
      </Grid>
      <Divider className="mt-4 mb-1" />
      {(data?.questionType === "Multi Select" ||
        (data?.questionType === "Question Set" &&
          data?.subQuestions[questionSetIndex]?.questionType ===
            "Multi Select")) &&
        viewResults && (
          <Grid className={classes.colorTipContainer}>
            <Grid className={classes.colorTile} />
            <Grid>Correct answer (not selected).</Grid>
          </Grid>
        )}
      {data?.questionType === "Question Set" && (
        <Grid
          className={`${classes.navButtonsContainer} ${
            questionSetIndex > 0
              ? classes.justifyBetween
              : classes.justifyCenter
          }`}
        >
          {questionSetIndex > 0 && (
            <Button
              onClick={handlePrevious}
              className={`${classes.prevQuestionBtn} ${classes.font14} ${classes.font500}`}
              disabled={nextLoader}
            >
              <KeyboardArrowLeftOutlinedIcon
                fontSize="small"
                className="mr-1"
              />{" "}
              Back
            </Button>
          )}

          {showButton("next") && (
            <Button
              onClick={() => handleNext("next")}
              className={`${classes.nextQuestionBtn} ${classes.font14} ${classes.font500}`}
              disabled={nextLoader}
            >
              {nextLoader ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                <span>
                  Next{" "}
                  <KeyboardArrowRightOutlinedIcon
                    fontSize="small"
                    className="ml-1"
                  />
                </span>
              )}
            </Button>
          )}

          {showButton("finish") && (
            <Button
              onClick={() => handleNext("finish")}
              className={classes.actionNextBtn}
              disabled={nextLoader}
            >
              Finish{" "}
              <KeyboardArrowRightOutlinedIcon
                fontSize="small"
                className="ml-1"
              />
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Questions;
