// darkModeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const loadDarkModeFromStorage = () => {
  const storedDarkMode = localStorage.getItem("darkMode");
  return storedDarkMode ? JSON.parse(storedDarkMode) : false;
};

const saveDarkModeToStorage = (isDarkMode) => {
  localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
};

const darkModeSlice = createSlice({
  name: "darkModeTheme",
  initialState: {
    isDarkModeTheme: loadDarkModeFromStorage(),
  },
  reducers: {
    toggleDarkMode: (state) => {
      state.isDarkModeTheme = !state.isDarkModeTheme;
      saveDarkModeToStorage(state.isDarkModeTheme);
    },
  },
});

export const { toggleDarkMode } = darkModeSlice.actions;
export const selectDarkMode = (state) => state.darkModeTheme.isDarkModeTheme;
export default darkModeSlice.reducer;
