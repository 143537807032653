import React from "react";
import { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Table } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "../../Services/api-service";
import Error from "../../assets/images/err.png";
import { getTheme } from "../../SharedComponents/ThemeComponent";

const theme = getTheme();
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  rowStyle: {
    width: "150px",
    textAlign: "center",
    padding: "10px",
    color: theme.palette.body,
  },
  thead: {
    width: "150px",
    textAlign: "center",
    color: theme.palette.heading,
    background: theme.palette.background.background3,
  },
  note: {
    padding: "5px 15px 5px 15px",
    fontSize: "14px",
  },
  headingContainer: {
    display: "flex",
    marginBottom: "10px",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: theme.palette.heading,
    [theme.breakpoints.down("xs")]: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  value: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.body,
    margin: "1px 0 0 5px",
    [theme.breakpoints.down("sm")]: {
      margin: "2px 0 0 5px",
    },
    [theme.breakpoints.down("xs")]: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      margin: "0 0 0 5px",
    },
  },
  notFound: {
    borderRadius: "30px",
    background: theme.palette.background.background3,
    width: "160px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#2761BA",
    [theme.breakpoints.down("xs")]: {
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ textAlign: "center" }} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AttemptSummary(props) {
  const [open, setOpen] = useState(props.open);
  const classes = useStyles();
  const [data, setData] = useState();
  const [err, setErr] = useState(false);
  useEffect(() => {
    API.get(`/report/exercise/section/${props.id}?type=attempts`)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        setErr(true);
        console.log("err", e);
      });
  }, []);

  const handleClose = () => {
    props.close(false);
  };
  return (
    <div style={{ maxHeight: "762px" }}>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.background3,
            color: theme.palette.heading,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          ATTEMPT SUMMARY
        </DialogTitle>
        <DialogContent>
          {err ? (
            <img src={Error} alt="error" />
          ) : (
            <>
              <div className={classes.headingContainer}>
                <span className={classes.heading}>Exercise: </span>
                <span className={classes.value}>{data?.exercise}</span>
              </div>
              <div className={classes.headingContainer}>
                <span className={classes.heading}>Attempts: </span>
                <span className={classes.value}>{data?.attempts}</span>
              </div>
              {data?.details.length ? (
                <Table>
                  <thead>
                    <tr>
                      <th className={classes.thead}>Date</th>
                      {data?.isMath && <th className={classes.thead}>Score</th>}
                      {data?.isMath && <th className={classes.thead}>Total</th>}
                      <th className={classes.thead}>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.details.map((d, idx) => {
                      return (
                        <tr key={idx}>
                          <td className={classes.rowStyle}>{d.attemptedAt}</td>
                          {data?.isMath && (
                            <td className={classes.rowStyle}>{d.score}</td>
                          )}
                          {data?.isMath && (
                            <td className={classes.rowStyle}>{d.total}</td>
                          )}
                          <td className={classes.rowStyle}>{d.completedIn}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <center>
                  <div className={classes.notFound}>No data Found</div>
                </center>
              )}
            </>
          )}
        </DialogContent>
        {data?.attempts > 25 && (
          <DialogContent className={classes.footerText}>
            Summary of recent 25 attempts is shown
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

export default AttemptSummary;
