import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useStyles } from "../style";
import { useState } from "react";
import { API } from "../../../../Services/api-service";
import { toast } from "react-toastify";

const MoveSectionDialog = ({
  openMoveSectionDialog,
  setOpenMoveSectionDialog,
  sectionNo,
  totalSections,
  exerciseId,
  sectionId,
  getSections,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sectionSequence, setSectionSequence] = useState(null);

  const onChangeHandler = (e) => {
    setSectionSequence(e.target.value);
  };

  const getMenuItems = () => {
    const obj = {};
    for (let i = 0; i < totalSections; i++) {
      if (i === 0) {
        obj[i + 1] = "Beginning";
      } else {
        obj[i + 1] = `After Section ${i}`;
      }
    }
    return Object.keys(obj).map((item, idx) => (
      <MenuItem value={item} disabled={idx + 1 === sectionNo}>
        {obj[item]}
      </MenuItem>
    ));
  };

  const saveHandler = () => {
    if (sectionSequence === null || sectionSequence === undefined) {
      return;
    }
    setLoading(true);
    API.post(`/${exerciseId}/questionnaire/section/${sectionId}`, {
      reOrderSection: parseInt(sectionSequence, 10),
    })
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.msg || "Section moved successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getSections(sectionId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.msg || "Something went wrong please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  return (
    <Dialog
      open={openMoveSectionDialog}
      onClose={() => setOpenMoveSectionDialog(false)}
    >
      <DialogTitle className={classes.textAlignCenter}>
        Move section
      </DialogTitle>
      <DialogContent>
        <div>Move section to</div>
        <div>
          <TextField
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
            }}
            fullWidth
            size="small"
            variant="outlined"
            select
            value={sectionSequence}
            onChange={(e) => onChangeHandler(e)}
            style={{ border: "1px solid #939393", borderRadius: "4px" }}
          >
            {getMenuItems()}
          </TextField>
        </div>
        <div className="mt-5 mb-3">
          <Button
            className={classes.cancelMoveSectionBtn}
            disabled={loading}
            onClick={() => {
              setOpenMoveSectionDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={saveHandler}
            className={`${classes.saveInstructionBtn}`}
          >
            Proceed
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MoveSectionDialog;
