import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@mui/icons-material/ChevronLeft";
import NextIcon from "@mui/icons-material/ChevronRight";
import { useStyles } from "../style";
import { Box, Button } from "@material-ui/core";
import AdminQuestions from "./AdminQuestions";

const AdminSubQuestionsWrapper = ({
  questionsArr,
  setQuestionsArr,
  sectionId,
  parentQuestionId,
  getSectionWiseData,
  currentAdminSubQuestionNo,
  setCurrentAdminSubQuestionNo,
}) => {
  const classes = useStyles();

  const handlePrevious = () => {
    setCurrentAdminSubQuestionNo(currentAdminSubQuestionNo - 1);
  };

  const handleNext = () => {
    setCurrentAdminSubQuestionNo(currentAdminSubQuestionNo + 1);
  };

  const addQuestion = () => {
    const newArr = JSON.parse(JSON.stringify(questionsArr));
    newArr.push(null);
    setQuestionsArr(newArr);
    setCurrentAdminSubQuestionNo(newArr.length - 1);
  };

  return (
    <div style={{ height: "90%" }}>
      <AdminQuestions
        questionData={
          questionsArr && questionsArr?.length
            ? questionsArr[currentAdminSubQuestionNo]
            : null
        }
        sectionId={sectionId}
        parentQuestionId={parentQuestionId}
        getSectionWiseData={getSectionWiseData}
        currentAdminSubQuestionNo={currentAdminSubQuestionNo}
        setCurrentAdminSubQuestionNo={setCurrentAdminSubQuestionNo}
        totalNoOfSubQns={questionsArr?.length || 0}
      />
      <div className={classes.questionSetPaginationContainer}>
        <Box className={classes.questionCountContainer}>
          <Box
            className={`${classes.qNoBox} ${classes.flex_row} ${classes.alignItemsCenter} ${classes.width100}`}
            style={{
              paddingTop:
                currentAdminSubQuestionNo > 0 ||
                currentAdminSubQuestionNo < questionsArr?.length - 1
                  ? "0"
                  : "5px",
              paddingBottom:
                currentAdminSubQuestionNo > 0 ||
                currentAdminSubQuestionNo < questionsArr?.length - 1
                  ? "0"
                  : "5px",
              paddingLeft: currentAdminSubQuestionNo > 0 ? "0" : "5px",
              paddingRight:
                currentAdminSubQuestionNo < questionsArr?.length - 1
                  ? "0"
                  : "5px",
            }}
          >
            {currentAdminSubQuestionNo > 0 && (
              <Box>
                <IconButton
                  onClick={handlePrevious}
                  style={{
                    color: "#2F4F4F",
                    padding: "8px",
                    marginRight: "5px",
                  }}
                  className={`${classes.flex_row} ${classes.alignItemsCenter}`}
                >
                  <BackIcon style={{ fontSize: "14px" }} />
                </IconButton>
              </Box>
            )}
            <Box
              className={` ${classes.flex_row} ${classes.alignItemsCenter} ${classes.questionCountText}`}
            >
              {`Question ${
                questionsArr?.length > 0 ? currentAdminSubQuestionNo + 1 : 0
              } of ${questionsArr?.length || 0}`}
            </Box>
            {currentAdminSubQuestionNo < questionsArr?.length - 1 && (
              <Box>
                <IconButton
                  onClick={() => handleNext("pagination")}
                  style={{
                    color: "#2F4F4F",
                    padding: "8px",
                    marginLeft: "5px",
                  }}
                  className={`${classes.flex_row} ${classes.alignItemsCenter}`}
                >
                  <NextIcon style={{ fontSize: "14px" }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.addQuestionContainer}>
          <Button
            className={`${classes.addOptionBtn} ${classes.font500} ${classes.font14}`}
            onClick={addQuestion}
          >
            <strong>Add Question +</strong>
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default AdminSubQuestionsWrapper;
