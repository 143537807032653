import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  newUpdateContainer: {
    width: "348px",
    height: "248px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "248px",
    },
  },
  darkModeIcon: {
    height: "64px",
    width: "64px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "21.78px",
    color: "#2F4F4F",
    marginTop: "24px",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "19.36px",
    color: "#569090",
    marginTop: "12px",
  },
  tryNow: {
    padding: "8px 32px",
    background: "#E98451",
    color: "#ffffff",
    borderRadius: "4px",
    marginTop: "24px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "16px",
  },
}));

const NewUpdate = ({ open, handleClose }) => {
  const classes = useStyles();
  const ASSETS_URL = `${process.env.REACT_APP_SANDBOX_ASSETS_URL}`;

  return (
    <>
      <Dialog maxWidth={"sm"} open={open} onClose={handleClose}>
        <div className={classes.newUpdateContainer}>
          <img
            src={`${ASSETS_URL}dark_mode.png`}
            alt="dark_mode"
            className={classes.darkModeIcon}
          />
          <div className={classes.title}>Exciting Update</div>
          <div className={classes.subTitle}>Dark Mode is Here!</div>
          <div className={classes.actionButton}>
            <Button
              type="submit"
              className={classes.tryNow}
              onClick={handleClose}
            >
              Try Now
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default NewUpdate;
