import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

Sentry.init({
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
  ],
  dsn: "https://8cba7814fb4146fba6e84192c51f4ced@o877499.ingest.sentry.io/6175607",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: "production",
  enabled: process.env.NODE_ENV !== "development",
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
