import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.background.background2,
    border: theme.palette.border2,
    boxShadow: "0px 0px 2px rgba(0, 83, 244, 0.16)",
    borderRadius: "12px",
    width: "280px",
    [theme.breakpoints.down("sm")]: {
      width: "104px",
    },
  },
  cnt: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "38px",
    color: theme.palette.exerciseCount,
    marginTop: "5px",
  },
  text: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.palette.heading,
    marginTop: "5px",
    marginBottom: "0px",
    textTransform: "capitalize",
  },
}));
function TrackerHeaderData({ icon, count, text }) {
  const classes = useStyles();
  return (
    <Grid item className={classes.container}>
      <div>
        <img src={icon} />
      </div>
      <div className={classes.cnt}>{count}</div>
      <div className={classes.text}>{text}</div>
    </Grid>
  );
}

export default TrackerHeaderData;
