import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exercises: [],
  searchExercise: "",
  searchConcepts: "",
  concepts: [],
  filteredConcepts: [],
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateSearchExercise: (state, actions) => {
      state.searchExercise = actions.payload;
    },

    updateSearchConcepts: (state, actions) => {
      state.searchConcepts = actions.payload;
    },

    updateConcepts: (state, actions) => {
      state.concepts = actions.payload;
    },

    updateFilteredConcepts: (state, actions) => {
      state.filteredConcepts = actions.payload;
    },
  },
});

export default coursesSlice.reducer;
export const {
  updateSearchExercise,
  updateSearchConcepts,
  updateConcepts,
  updateFilteredConcepts,
  updateCheckedState,
} = coursesSlice.actions;
