import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100vw',
    height: '100vh',
    background: theme.palette.background.background0,
    position: 'fixed',
  },
  loaderItem: {
    width: '20px',
    height: '20px',
    background: '#8385aa',
    opacity: 0.5,
    borderRadius: '50%',
    margin: '3px',
    animation: '$loader 0.6s infinite alternate',
  },
  loaderItem2: {
    animationDelay: '0.2s',
  },
  loaderItem3: {
    animationDelay: '0.4s',
  },
  '@keyframes loader': {
    to: {
      opacity: 0.1,
      transform: 'translate(0, -20px)',
      background: 'pink',
    },
  },
}));

const Loader = () => {
  const classes = useStyles()
  return (
    // <div className="loading">
    //   <div></div>
    //   <div></div>
    // </div>
    <div className={`${classes.loader}`}>
      <div className={classes.loaderItem}></div>
      <div className={`${classes.loaderItem} ${classes.loaderItem2}`}></div>
      <div className={`${classes.loaderItem} ${classes.loaderItem3}`}></div>
    </div>
  );
};

export default Loader;
