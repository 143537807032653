import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";

import "./styles.scss";

var Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "17px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
];

var Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "roboto",
  "calibri",
  "nunito",
  "georgia",
  "lucida-console",
  "times-new-roman",
];

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register(Size, true);
Quill.register(Font, true);
window.Quill = Quill;

const modules = {
  toolbar: {
    container: [
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image"],
      [{ symbol: ["Σ", "α", "β", "π", "Ω", "Θ", "π"] }],
      ["clean"],
    ],
    handlers: {
      symbol: function (value) {
        if (value) {
          const cursorPosition = this.quill.getSelection().index;
          this.quill.insertText(cursorPosition, value);
          this.quill.setSelection(cursorPosition + value.length);
        }
      },
    },
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
  imageDrop: true,
};

const TextEditor = ({ handleChange, answers, questionType, questionId }) => {
  const onChange = (content) => {
    handleChange(content, questionType, questionId);
  };

  useEffect(() => {
    const symbolPickerItems = Array.prototype.slice.call(
      document.querySelectorAll(".ql-symbol .ql-picker-item")
    );
    symbolPickerItems.forEach(
      (item) => (item.textContent = item.dataset.value)
    );
    if (document.querySelector(".ql-symbol .ql-picker-label")) {
      document.querySelector(".ql-symbol .ql-picker-label").innerHTML =
        "Ω" + document.querySelector(".ql-symbol .ql-picker-label").innerHTML;
      document.querySelector(".ql-symbol .ql-picker-label").style =
        "width: 45px";
    }
  }, []);

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      placeholder="Enter your answer here..."
      onChange={onChange}
      value={answers}
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    />
  );
};
export default TextEditor;
